import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { LabelField, LabelTextarea } from "../../components/fields";
import moment from "moment";
import { CardLayout } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import * as API from "../../api/bookings";
import { useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Text } from "../../components/elements";
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker';

const BookingView = () => {
  const id = useParams().id;
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingC, setLoadingC] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [rescheduling, setRescheduling] = useState(false);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [thisBoatSlots, setThisBoatSlots] = useState([]);
  const [boatBookings, setBoatBookings] = useState(null);

  function formatDate(dateString) {
    const [year, month, day] = dateString.split('-');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  const groupUnavailableDates = data?.booking?.boat?.groupSlots;

  const unavailableSlots = data?.booking?.boat?.slots?.map(obj => ({
    date: formatDate(obj.date),
    time: obj.time
  }));


  const bookingData = data?.booking;

  const navigate = useNavigate();

  const fetchbooking = async (id) => {
    try {
      const { data } = await API.getBookingById(id);
      if (data.status) {
        setData(data?.data);
      }
    } catch (error) {
      if (error.response.data.message)
        toast.error(error.response.data.message)
      else
        toast.error('failed to load booking data')
      console.log(error.message);
    }
  }

  const handleToogleCancel = async (id) => {
    const confirm = window.confirm('Want to cancel this booking?')
    if (!confirm) {
      return;
    }
    setLoadingC(true);
    try {
      const { data } = await API.toggleBookingCancel(id);
      if (data.status) {
        toast.warn("Booking cancelled");
        setLoadingC(false);
        navigate("/bookings");
      }
    } catch (error) {
      setLoading(false);
      if (error.response.data.message)
        toast.error(error.response.data.message)
      else
        toast.error('Cancellation failed')
      console.log(error.message);
    }
  };

  const findAvailableBoatSlots = async () => {
    try {
      const availableSlots = await API.boatSlots(data?.booking?.boat?._id, data?.booking?.trip?.slots)
      const newSlots = availableSlots?.data?.availableSlots
      for (const availableSlot of newSlots) {
        const matchingUnAvailableSlot = unavailableSlots?.find(
          (unAvailableSlot) => unAvailableSlot.date === availableSlot.date
        );

        if (matchingUnAvailableSlot) {
          availableSlot.time = availableSlot.time.filter(
            (time) => !matchingUnAvailableSlot.time.includes(time)
          );
        }
      }
      setThisBoatSlots(newSlots)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    let tempDate = [];
    if (thisBoatSlots?.length > 0) {
      const slotsWithTime = thisBoatSlots.filter((slot) => slot?.time?.length > 0);
      slotsWithTime?.map((slot) => {
        let date = slot?.date;
        tempDate = [...tempDate, new Date(date)];
        return true;
      });
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 1);
      let available = tempDate.filter((date) => !(date <= currentDate));
      setAvailableDates(available);
    }
  }, [thisBoatSlots])


  const convertDateToString = (date) => {
    const mydate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return mydate;
  }

  function sortTimeSlots(a, b) {
    const timeA = new Date("1970-01-01 " + a);
    const timeB = new Date("1970-01-01 " + b);
    return timeA - timeB;
  }

  const handleTimeSlotChange = (e) => {
    setSelectedTime(e.target.value);
  };


  useEffect(() => {
    if (selectedDate) {
      const date = new Date(selectedDate);

      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const formattedDate = `${year}-${month}-${day}`;

      setSelectedSlot({ date: formattedDate, time: selectedTime })

      let tempTimes = [];
      thisBoatSlots?.forEach((slot) => {
        let date = new Date(slot?.date);
        if (convertDateToString(date) === convertDateToString(selectedDate)) {
          tempTimes = [...slot['time']]
          setAvailableTimes(tempTimes.sort(sortTimeSlots));
        }
      })
    } else {
      setAvailableTimes([]);
      setSelectedTime(null)
    }
    if (selectedDate === null) {
      setSelectedSlot(null)
    }
    if (selectedDate !== null && data?.booking?.trip?.type === 'Group') {
      const availability = data?.booking?.boat?.availability;
      const selectedDay = new Date(selectedDate).getDay();
      setSelectedTime(availability.find((avail) => avail.value === selectedDay)?.slot)
    }
  }, [selectedDate, thisBoatSlots, selectedTime])
  const findAvailableSeats = async () => {
    try {
      const availableSeats = await API.checkAvailableSeats({ boat: data?.booking?.boat?._id });
      if (availableSeats?.data.status) {
        setBoatBookings(availableSeats?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const rescheduleBooking = async () => {
    const response = await API.rescheduleBooking({ id: data.booking._id, selectedSlot: selectedSlot })
    if (response.data.status) {
      setRescheduling(false);
      setSelectedSlot(null);
      setSelectedDate(null)
      fetchbooking(id);
      toast.success('Booking Rescheduled')
    }
  }


  useEffect(() => {
    if (bookingData?.trip?.type === 'Individual')
      findAvailableBoatSlots();
    if (bookingData?.trip?.type === 'Group') {
      setSelectedDate(null);
      findAvailableSeats();
    }
  }, [data])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (id)
      fetchbooking(id);
  }, [id]);

  const getDay = (date) => {
    return date.getDay();
  };


  const isWeekday = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const myFormat = `${year}-${month}-${day}`;
    const unavailableDates = groupUnavailableDates.map((d) => {
      const dd = new Date(d)
      const year = dd.getFullYear();
      const month = dd.getMonth() + 1;
      const day = dd.getDate();
      const myFormat1 = `${year}-${month}-${day}`;
      return myFormat1;
    })
    if ((Object.keys(boatBookings).includes(myFormat) && boatBookings?.[myFormat]?.availableSeats < data?.booking?.guests) || (unavailableDates.includes(myFormat))) {
      return;
    }
    date.setHours(6);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const closingTime = date.setHours(date.getHours() - 8);
    const currentDate = new Date();

    if (closingTime <= currentDate)
      return;
    const day1 = getDay(date);

    return data?.booking?.boat?.availability.some((slot) => {
      const slotValue = slot.value === 0 ? 6 : slot.value - 1
      return day1 === slotValue
    })
  };



  return (
    <div>
      <PageLayout>
        <div>
          <Row>
            <Breadcrumb title={"Booking Details"}></Breadcrumb>
          </Row>
        </div>
        <div className="mt-3 p-2">
          <div>
            <Row>
              <CardLayout>
                <Breadcrumb title={"User Details"}></Breadcrumb>
              </CardLayout>

              <Col xl={4}>
                <LabelField
                  type="text"
                  label="UserName"
                  name="uname"
                  //   onChange={handleChange}
                  value={data?.booking?.user?.userName}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>
              <Col xl={4}>
                <LabelField
                  type="text"
                  label="Email"
                  name="areTitle"
                  //   onChange={handleChange}
                  value={data?.booking?.user?.email}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>
              <Col xl={4}>
                <LabelField
                  type="text"
                  label="Contact"
                  name="phone"
                  //   onChange={handleChange}
                  value={data?.booking?.userEnteredNumber || data?.booking?.user?.phone}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>
              {data?.booking?.message && <Col xl={12}>
                <LabelTextarea
                  disabled
                  className={`bg-light rounded p-2 w-100 border`}
                  name="message"
                  label="Message"
                  value={data?.booking?.message}
                  rows={5}

                  fieldSize="w-100 h-text-md"
                />
              </Col>}
            </Row>
          </div>
          <div>
            <Row className="mt-2">
              <CardLayout>
                <Breadcrumb title={"Trip Details"}></Breadcrumb>
              </CardLayout>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="Trip"
                  name="trip"
                  //   onChange={handleChange}
                  value={data?.booking?.trip?.en?.title}
                  className={`${data?.booking?.trip === "null" ? "placeholder" : "N/A"
                    }`}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="Booked On"
                  name="date"
                  //   onChange={handleChange}
                  value={moment(data?.booking?.createdAt).format("MMM Do YYYY")}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="Selected Date"
                  name="trip"
                  //   onChange={handleChange}
                  value={moment(data?.booking?.selectedSlot?.date?.replaceAll('-', '/')).format("MMM Do YYYY")}
                  className={`${data?.trip === "null" ? "placeholder" : "N/A"}`}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>
              {data?.booking?.selectedSlot?.time && (
                <Col xl={6}>
                  <LabelField
                    type="text"
                    label="Selected Time"
                    name="trip"
                    //   onChange={handleChange}
                    value={data?.booking?.selectedSlot?.time}
                    className={`${data?.booking?.trip === "null" ? "placeholder" : "N/A"
                      }`}
                    fieldSize="w-100 h-md"
                    disabled
                  />
                </Col>
              )}
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="Trip Type"
                  name="date"
                  //   onChange={handleChange}
                  value={data?.booking?.trip?.type}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>


              <Col xl={6}>
                <LabelField
                  type="text"
                  label="Number Of Persons"
                  name="date"
                  //   onChange={handleChange}
                  value={data?.booking?.guests}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>


              <Col xl={6}>
                <LabelField
                  type="text"
                  label="Boat"
                  name="trip"
                  //   onChange={handleChange}
                  value={data?.booking?.boat?.en?.title}
                  className={`${data?.trip === "null" ? "placeholder" : "N/A"}`}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="captain"
                  name="captain"
                  //   onChange={handleChange}
                  value={data?.booking?.boat?.captainName}
                  className={`${data?.trip === "null" ? "placeholder" : "N/A"}`}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>

              <CardLayout>
                <Breadcrumb title={"Addon Details"}></Breadcrumb>
              </CardLayout>
              <div className="w-75  m-auto mt-3">
                <table className="table m-2">
                  <thead>
                    <tr className="">
                      <th>
                        <Text>Addon</Text>
                      </th>
                      <th>
                        <Text>Quantity</Text>
                      </th>
                      <th>
                        <Text>Price/Unit</Text>
                      </th>
                      <th>
                        <Text>Total Price</Text>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.booking?.addonsQuantity?.length > 0 &&
                      data?.booking?.addonsQuantity.map((e) => (
                        <tr>
                          <td>
                            <Text>{e?.addon?.en?.title}</Text>
                          </td>
                          <td>
                            <Text>{e?.addonCount}</Text>
                          </td>
                          <td>
                            <Text>AED {e?.addon?.price}</Text>
                          </td>
                          <td>
                            <Text>AED {e?.addon?.price * e?.addonCount}</Text>
                          </td>
                        </tr>
                      ))}
                    <tr>

                      <td>
                        <Text>Total Addon Price</Text>
                      </td>
                      <td></td>
                      <td></td>
                      <td className="m-auto"><Text>{data?.totalAddonPrice > 0 ? `AED ${data?.totalAddonPrice}` : 'N/A'}</Text></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mt-4 mb-4">
                <CardLayout>
                  <Breadcrumb title="Payment Information">

                  </Breadcrumb>
                </CardLayout>
                <div className="w-75 m-auto mt-3">
                  <table className="table table-borderless m-2">
                    <tbody>

                      <tr className="mt-2">
                        <th>Trip Price</th>
                        <td>AED {data?.booking?.tripPrice}</td>
                      </tr>
                      <tr className="">
                        <th>Total Addon Price</th>
                        <td>
                          {
                            data?.totalAddonPrice > 0
                              ?
                              `AED ${data?.totalAddonPrice}`
                              :
                              'N/A'
                          }
                        </td>
                      </tr>
                      <tr className="mt-2">
                        <th>Discount Code</th>
                        {
                          data?.booking?.discountAmount && data?.booking?.discountAmount > 0
                            ?
                            <td>{data?.booking?.discountCode} <span className="text-sm text-[#e00b2b]">(-{(data?.booking?.discountAmount)})</span></td>
                            :
                            <td>N/A</td>

                        }
                      </tr>
                      <tr>
                        <th>Amount Paid</th>
                        <td>
                          {
                            data?.booking?.payment?.status === 'success'
                              ?
                              `AED ${data?.booking?.payment?.amount}`
                              :
                              <p className="uppercase text-[#e00b2b] font-semibold">N/A</p>
                          }
                        </td>
                      </tr>
                      {
                        data?.booking?.payment?.transactionNo && (data?.booking?.payment?.transactionNo !== (null || undefined || ''))
                        &&
                        <>
                          <tr>
                            <th>Transaction ID</th>
                            <td>
                              {data?.booking?.payment?.transactionNo}
                            </td>
                          </tr>
                          {
                            data?.booking?.payment?.paymentDate
                            &&
                            <tr>
                              <th>Transaction time</th>
                              <td>
                                {moment(data?.booking?.payment?.paymentDate).format('llll')}
                              </td>
                            </tr>
                          }
                        </>
                      }
                      {
                        data?.booking?.payment?.status
                        &&
                        <tr>
                          <th>Transaction Status</th>
                          <td className={`font-bold ${data?.booking?.payment?.status === 'success' ? 'text-[#187d44]' : data?.booking?.payment?.status === 'pending' ? 'text-[#a808c3]' : 'text-[#e00b2b]'}`}>
                            {(data?.booking?.payment?.status).toUpperCase()}
                          </td>
                        </tr>
                      }
                      {
                        data?.booking?.payment?.errorMessage && data?.booking?.payment?.status !== 'success'
                        &&
                        <>
                          <tr>
                            <th>Reason</th>
                            <td>
                              {data?.booking?.payment?.errorMessage}
                            </td>
                          </tr>
                        </>
                      }
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                <h5 className={`text-center text-primary`}>
                  Confirmation Status :
                  <span
                    className={`${data?.booking?.status === "Confirmed"
                      ? "text-success"
                      : data?.booking?.status === "Cancelled"
                        ? "text-danger"
                        : "text-[#a808c3]"
                      }`}
                  >
                    &nbsp;{data?.booking?.status}
                  </span>
                </h5>
              </div>
              {
                data?.booking?.status === 'Confirmed'
                &&
                (
                  rescheduling
                    ?
                    <h2 className="text-center"><span className="text-red-400 cursor-pointer" onClick={() => { setRescheduling(false); setSelectedDate(null) }}>Cancel </span>Reschedule?</h2>
                    :
                    <h2 className="text-center">Want to <span className="text-red-400 cursor-pointer" onClick={() => setRescheduling(true)}>Reschedule?</span></h2>
                )
              }
              {
                !!rescheduling
                &&
                (
                  data?.booking?.trip?.type === 'Individual'
                    ?
                    <div className="[&_react-datepicker__input-container]:w-max flex gap-10">
                      <div>
                        <h3 className={`font1 text-lg md:text-2xl my-4`}>Select a new date</h3>
                        <DatePicker
                          highlightDates={availableDates}
                          selected={selectedDate}
                          dateFormat="dd/MM/yyyy"
                          includeDates={availableDates}
                          required
                          form="personalDetails"
                          onChange={setSelectedDate}
                          placeholderText="&#128197;"
                          minDate={new Date()}
                          isClearable
                          customInput={!!selectedDate
                            ?
                            <input type='text' style={{ border: `${selectedDate !== null && '1px solid gray'}` }} className={`rounded-lg shadow-[0_0_40px_-7px_rgba(53,151,166,.6)] placeholder:text-right cursor-pointer caret-transparent m-auto py-2 px-1`} />
                            :
                            <div className='h-20 cursor-pointer'>
                              <svg width="" height="" className='h-full' viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M445.741 30.1172H400.565V75.2937C400.565 84.329 393.035 90.3525 385.506 90.3525C377.976 90.3525 370.447 84.329 370.447 75.2937V30.1172H129.506V75.2937C129.506 84.329 121.976 90.3525 114.447 90.3525C106.918 90.3525 99.3882 84.329 99.3882 75.2937V30.1172H54.2118C31.6235 30.1172 15.0588 49.6937 15.0588 75.2937V129.505H496.941V75.2937C496.941 49.6937 469.835 30.1172 445.741 30.1172ZM15.0588 161.129V436.705C15.0588 463.811 31.6235 481.882 55.7177 481.882H447.247C471.341 481.882 498.447 462.305 498.447 436.705V161.129H15.0588ZM149.082 414.117H112.941C106.918 414.117 100.894 409.6 100.894 402.07V364.423C100.894 358.4 105.412 352.376 112.941 352.376H150.588C156.612 352.376 162.635 356.894 162.635 364.423V402.07C161.129 409.6 156.612 414.117 149.082 414.117ZM149.082 278.588H112.941C106.918 278.588 100.894 274.07 100.894 266.541V228.894C100.894 222.87 105.412 216.847 112.941 216.847H150.588C156.612 216.847 162.635 221.364 162.635 228.894V266.541C161.129 274.07 156.612 278.588 149.082 278.588ZM269.553 414.117H231.906C225.882 414.117 219.859 409.6 219.859 402.07V364.423C219.859 358.4 224.376 352.376 231.906 352.376H269.553C275.576 352.376 281.6 356.894 281.6 364.423V402.07C281.6 409.6 277.082 414.117 269.553 414.117ZM269.553 278.588H231.906C225.882 278.588 219.859 274.07 219.859 266.541V228.894C219.859 222.87 224.376 216.847 231.906 216.847H269.553C275.576 216.847 281.6 221.364 281.6 228.894V266.541C281.6 274.07 277.082 278.588 269.553 278.588ZM390.024 414.117H352.376C346.353 414.117 340.329 409.6 340.329 402.07V364.423C340.329 358.4 344.847 352.376 352.376 352.376H390.024C396.047 352.376 402.071 356.894 402.071 364.423V402.07C402.071 409.6 397.553 414.117 390.024 414.117ZM390.024 278.588H352.376C346.353 278.588 340.329 274.07 340.329 266.541V228.894C340.329 222.87 344.847 216.847 352.376 216.847H390.024C396.047 216.847 402.071 221.364 402.071 228.894V266.541C402.071 274.07 397.553 278.588 390.024 278.588Z" fill="#15658D" />
                              </svg>
                            </div>
                          }
                        />
                      </div>
                      {
                        selectedSlot && selectedDate &&
                        <div className="flex-grow">
                          <h3 className={`font1 text-lg md:text-2xl my-4`}>Choose a new time</h3>
                          <ul className="grid grid-cols-3 sm:grid-cols-4 max-w-full gap-6 items-center">
                            {availableTimes?.map((time) => (
                              <li key={time} className='w-max'>
                                <input type="radio" id={time} name='time' key={time} value={time} className="hidden peer" onChange={handleTimeSlotChange} disabled={!!selectedDate ? false : true} required />
                                <label htmlFor={time} className={`inline-flex items-center justify-between w-full p-2 color-secondary bg-white rounded-lg cursor-pointer shadow-md ${selectedSlot?.time === time && '!bg-green-500'} peer-checked:text-white hover:text-gray-600 hover:bg-gray-100`}>
                                  <div className="block">
                                    <div className="w-full font-semibold">{time}</div>
                                  </div>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      }
                    </div>
                    :
                    <div>
                      <h3 className={`font1 text-lg md:text-2xl mb-8`}>Select a new Date</h3>

                      <DatePicker
                        // highlightDates={isWeekday}
                        selected={selectedDate}
                        // includeDates={availableDates}
                        onChange={setSelectedDate}
                        dateFormat="dd/MM/yyyy"
                        required
                        form="personalDetails"
                        filterDate={isWeekday}
                        placeholderText="&#128197;"
                        minDate={new Date()}
                        isClearable
                        customInput={!!selectedDate
                          ?
                          <input type='text' className='rounded-lg shadow-[0_0_27px_-7px_rgba(53,151,166,.4)] placeholder:text-right cursor-pointer caret-transparent arabic-format1 py-2 px-1'></input>
                          :
                          <div className='h-20 cursor-pointer'>
                            <svg width="" height="" className='h-full' viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M445.741 30.1172H400.565V75.2937C400.565 84.329 393.035 90.3525 385.506 90.3525C377.976 90.3525 370.447 84.329 370.447 75.2937V30.1172H129.506V75.2937C129.506 84.329 121.976 90.3525 114.447 90.3525C106.918 90.3525 99.3882 84.329 99.3882 75.2937V30.1172H54.2118C31.6235 30.1172 15.0588 49.6937 15.0588 75.2937V129.505H496.941V75.2937C496.941 49.6937 469.835 30.1172 445.741 30.1172ZM15.0588 161.129V436.705C15.0588 463.811 31.6235 481.882 55.7177 481.882H447.247C471.341 481.882 498.447 462.305 498.447 436.705V161.129H15.0588ZM149.082 414.117H112.941C106.918 414.117 100.894 409.6 100.894 402.07V364.423C100.894 358.4 105.412 352.376 112.941 352.376H150.588C156.612 352.376 162.635 356.894 162.635 364.423V402.07C161.129 409.6 156.612 414.117 149.082 414.117ZM149.082 278.588H112.941C106.918 278.588 100.894 274.07 100.894 266.541V228.894C100.894 222.87 105.412 216.847 112.941 216.847H150.588C156.612 216.847 162.635 221.364 162.635 228.894V266.541C161.129 274.07 156.612 278.588 149.082 278.588ZM269.553 414.117H231.906C225.882 414.117 219.859 409.6 219.859 402.07V364.423C219.859 358.4 224.376 352.376 231.906 352.376H269.553C275.576 352.376 281.6 356.894 281.6 364.423V402.07C281.6 409.6 277.082 414.117 269.553 414.117ZM269.553 278.588H231.906C225.882 278.588 219.859 274.07 219.859 266.541V228.894C219.859 222.87 224.376 216.847 231.906 216.847H269.553C275.576 216.847 281.6 221.364 281.6 228.894V266.541C281.6 274.07 277.082 278.588 269.553 278.588ZM390.024 414.117H352.376C346.353 414.117 340.329 409.6 340.329 402.07V364.423C340.329 358.4 344.847 352.376 352.376 352.376H390.024C396.047 352.376 402.071 356.894 402.071 364.423V402.07C402.071 409.6 397.553 414.117 390.024 414.117ZM390.024 278.588H352.376C346.353 278.588 340.329 274.07 340.329 266.541V228.894C340.329 222.87 344.847 216.847 352.376 216.847H390.024C396.047 216.847 402.071 221.364 402.071 228.894V266.541C402.071 274.07 397.553 278.588 390.024 278.588Z" fill="#15658D" />
                            </svg>
                          </div>
                        }
                      />
                      {
                        selectedSlot && selectedDate &&
                        <div className="flex-grow">
                          <h3 className={`font1 text-lg md:text-2xl my-4`}>Choose a new time</h3>
                          <ul className="grid grid-cols-3 sm:grid-cols-4 max-w-full gap-6 items-center">
                              <li className='w-max'>
                                <input type="radio" id={'time'} name='time' value={selectedTime} className="hidden peer" disabled={true} required />
                                <label htmlFor={'time'} className={`inline-flex items-center justify-between w-full p-2 color-secondary text-white bg-white rounded-lg cursor-pointer shadow-md ${selectedSlot?.time === selectedTime && '!bg-green-500'} peer-checked:text-white hover:text-gray-600 hover:bg-gray-100`}>
                                  <div className="block">
                                    <div className="w-full font-semibold">{selectedTime}</div>
                                  </div>
                                </label>
                              </li>
                          </ul>
                        </div>
                      }
                    </div>
                )}
              <div className="text-center mt-4">
                <Box className={"d-flex justify-content-center"}>
                  {data?.booking?.status === "Confirmed" && !rescheduling && (
                    <button
                      className="btn btn-danger m-2"
                      onClick={() => {
                        !loadingC && handleToogleCancel(data?.booking?._id);
                      }}
                    >
                      {loadingC ? "loading..." : `Cancel Booking`}
                    </button>
                  )}
                  {
                    selectedSlot !== null && selectedSlot?.date !== null && selectedSlot?.time !== null
                    &&
                    <button
                      className="btn btn-success m-2"
                      onClick={rescheduleBooking}
                    >
                      Reschedule
                    </button>
                  }
                </Box>
              </div>
            </Row>
          </div>
        </div>
      </PageLayout>
    </div>
  );
};

export default BookingView;

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Anchor, Icon, Item } from "../../components/elements";
import { CardLayout, CardHeader, FloatCard } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import UsersTable from "../../components/tables/UsersTable";
import PageLayout from "../../layouts/PageLayout";
import { toast } from "react-toastify";
import data from "../../data/master/userList.json";
import * as userAPI from '../../api/users'
import ReactPaginate from "react-paginate";

export default function UserList({ userData, fetchUserData }) {
    const [users, setUsers] = useState([]);
    const [pendingUsers, setPendingUsers] = useState(0)
    const [verifiedUsers, setVerifiedUsers] = useState(0)
    const [blockedUsers, setBlockedUsers] = useState(0)

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const [dataCount, setDataCount] = useState(0);
    const indexOfLastItem = (currentPage + 1) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = users.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(dataCount / itemsPerPage);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage.selected);
    };



    useEffect(() => {
        const users = userData.filter((user) => user.role !== 'admin');
        setDataCount(users.length);
        setUsers(userData)
        const countUsers = (data) => {
            setVerifiedUsers((data.filter((user) => user.isEmailVerified === true && user.role !== 'admin')))
            setPendingUsers((data.filter((user) => user.isEmailVerified === false && user.role !== 'admin')))
            setBlockedUsers((data.filter((user) => user.isBlocked === true && user.role !== 'admin')))
        }
        if (userData)
            countUsers(userData);
    }, [userData]);

    useEffect(() => {
        fetchUserData();
    }, []);

    useEffect(() => {
        setDataCount(users.length);
    }, [users])

    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title={data?.pageTitle} loadingIcon={true} fetchData={fetchUserData}>
                            {data?.breadcrumb.map((item, index) => (
                                <Item key={index} className="mc-breadcrumb-item">
                                    {item.path ? <Anchor className="mc-breadcrumb-link" href={item.path}>{item.text}</Anchor> : item.text}
                                </Item>
                            ))}
                        </Breadcrumb>
                    </CardLayout>
                </Col>
                <Col xl={3}>
                    <div className="cursor-pointer" onClick={() => setUsers(userData)}>
                        <FloatCard
                            variant={'lg blue'}
                            digit={userData.length}
                            title={'users'}
                            icon={'account_circle'}
                        />
                    </div>
                </Col>
                <Col xl={3}>
                    <div className="cursor-pointer" onClick={() => setUsers(pendingUsers)}>
                        <FloatCard
                            variant={'lg purple'}
                            digit={pendingUsers.length}
                            title={'pending verifications'}
                            icon={'pending'}
                        />
                    </div>
                </Col>
                <Col xl={3}>
                    <div className="cursor-pointer" onClick={() => setUsers(verifiedUsers)}>
                        <FloatCard
                            variant={'lg green'}
                            title={'verified users'}
                            icon={'check_circle'}
                            digit={verifiedUsers.length}
                        />
                    </div>
                </Col>
                <Col xl={3}>
                    <div className="cursor-pointer" onClick={() => setUsers(blockedUsers)}>
                        <FloatCard
                            variant={'lg red'}
                            title={'blocked users'}
                            icon={'remove_circle'}
                            digit={blockedUsers.length}
                        />
                    </div>
                </Col>
                <Col xl={12}>
                    <CardLayout>
                        <CardHeader title={data?.cardTitle} />
                        <Row xs={1} sm={4} className="mb-4">
                        </Row>
                        <UsersTable
                            itemsPerPage={itemsPerPage}
                            currentPage={currentPage}
                            thead={data?.table.thead}
                            tbody={currentItems}
                            fetchUserData={fetchUserData}
                        />
                        {/* <Pagination /> */}
                    </CardLayout>
                </Col>
            </Row>
            {/* <Pagination totalItems={dataCount} itemsPerPage={itemsPerPage} currentPage={currentPage} onPageChange={(page) => handlePageChange(page)} totalNoPages={totalPages} /> */}
            <div>
                <ReactPaginate
                    pageCount={totalPages}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    previousLabel={
                        <Item className="mc-paginate-item" disabled={currentPage === 1}>
                            <Icon type="chevron_left" />
                        </Item>
                    }
                    nextLabel={
                        <Item className="mc-paginate-item" disabled={currentPage === totalPages}>
                            <Icon type="chevron_right" />
                        </Item>
                    }
                    breakLabel={'...'}
                    onPageChange={(page) => handlePageChange(page)}
                    containerClassName={'flex justify-end mt-4 items-center'}
                    pageClassName={'page-class'}
                    activeClassName={'active-page'}
                    previousClassName={'select-none'}
                    nextClassName={'select-none'}
                    breakClassName={'break'}
                />
            </div>
        </PageLayout>
    );
}
import React from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/ecommerce.json";
import { Link, useNavigate } from "react-router-dom";
import { Box, Item, Anchor } from "../../components/elements";
import { EcommerceCard } from "../../components/cards";
import { useEffect } from "react";

export default function Dashboard({ userData, messageData, bookings, fetchData }) {

    const currentDate = new Date();
    const currentMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    const totalUsersThisMonth = (userData.filter((user) => new Date(user.createdAt) >= currentMonthStartDate && user.role !== 'admin')).length;
    const totalMessagesThisMonth = (messageData.filter((review) => new Date(review.createdAt) >= currentMonthStartDate)).length;
    const totalBookingsThisMonth = (bookings.filter((booking) => new Date(booking.createdAt) >= currentMonthStartDate)).length;
    const thisMonthConfirmedBookings = bookings.filter((booking) => new Date(booking.createdAt) >= currentMonthStartDate && booking.status === 'Confirmed')


    const totalUsers = (userData.filter((user) => user.role !== 'admin')).length;
    const totalMessages = messageData?.length;
    const totalBookings = bookings?.length;
    const confirmedBookings = bookings.filter((booking) => booking.status === "Confirmed");

    let revenueThisMonth = 0;
    let revenue = 0;

    thisMonthConfirmedBookings.forEach((book) => {
        revenueThisMonth += book?.payment?.amount;
    })
    confirmedBookings.forEach((book) => {
        revenue += book?.payment?.amount;
    })

    const userPercentageThisMonth = ((totalUsersThisMonth / totalUsers) * 100).toFixed(2);
    const reviewPercentageThisMonth = ((totalMessagesThisMonth / totalMessages) * 100).toFixed(2);
    const revenuePercentageThisMonth = ((revenueThisMonth / revenue) * 100).toFixed(2);
    const bookingPercentageThisMonth = ((totalBookingsThisMonth / totalBookings) * 100).toFixed(2);

    let user = localStorage.getItem('admin');
    useEffect(() => {
        let myInterval;
        if (user) {
            fetchData();
        }
        // myInterval = setInterval(() => {
        //     if (window.location.pathname !== '/') {
        //         clearInterval(myInterval)
        //     }
        //     if (user) {
        //         try {
        //             fetchData();
        //         } catch (error) {
        //             console.log(error.message);
        //             clearInterval(myInterval);
        //         }
        //     }
        // }, 5000);
    }, [user]);


    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <Box className="mc-card">
                        <Breadcrumb title={data?.pageTitle} loadingIcon={true} fetchData={fetchData}>
                            {data?.breadcrumb?.map((item, index) => (
                                <Item key={index} className="mc-breadcrumb-item">
                                    {item.path ? <Anchor className="mc-breadcrumb-link" href={item.path}>{item.text}</Anchor> : item.text}
                                </Item>
                            ))}
                        </Breadcrumb>
                    </Box>
                </Col>
                <Col xs={12} xl={12}>
                    <Row xs={1} sm={2}>
                        <Col xl={6}>
                            <Link style={{ width: '100%' }} to={'/user-list'}>
                                <EcommerceCard
                                    variant="green"
                                    title="total users"
                                    number={totalUsers}
                                    icon="account_circle"
                                    trend={`${userPercentageThisMonth >= 0 ? 'trending_up' : 'trending_down'}`}
                                    percent={`+${totalUsersThisMonth} (${userPercentageThisMonth}%)`}
                                    compare="this month"
                                />
                            </Link>
                        </Col>
                        <Col xl={6}>
                            <Link style={{ width: '100%' }} to={'/bookings'}>
                                <EcommerceCard
                                    variant="purple"
                                    title="total bookings"
                                    number={totalBookings}
                                    icon="shopping_cart"
                                    trend={`${bookingPercentageThisMonth >= 0 ? 'trending_up' : 'trending_down'}`}
                                    percent={`+${totalBookingsThisMonth} (${bookingPercentageThisMonth}%)`}
                                    compare="this month"
                                />
                            </Link>
                        </Col>
                        <Col xl={6}>
                            <Link style={{ width: '100%' }} to={'/'}>
                                <EcommerceCard
                                    variant="yellow"
                                    title="total revenue"
                                    number={`AED ${revenue.toFixed(2)}`}
                                    icon="account_balance_wallet"
                                    trend={`${revenuePercentageThisMonth >= 0 ? 'trending_up' : 'trending_down'}`}
                                    percent={`+${revenueThisMonth.toFixed(2)} (${revenuePercentageThisMonth}%)`}
                                    compare="this month"
                                />
                            </Link>
                        </Col>
                        <Col xl={6}>
                            <Link style={{ width: '100%' }} to={'/customer-questions'}>
                                <EcommerceCard
                                    variant="blue"
                                    title="total messages"
                                    number={totalMessages}
                                    icon="chat"
                                    trend={`${reviewPercentageThisMonth >= 0 ? 'trending_up' : 'trending_down'}`}
                                    percent={`+${totalMessagesThisMonth} (${reviewPercentageThisMonth}%)`}
                                    compare="this month"
                                />
                            </Link>
                        </Col>

                        {/* <Col xl={6}>
                            <Link style={{ width: '100%' }} to={'/trip-list'}>
                                <EcommerceCard
                                    variant="blue"
                                    title="total trips"
                                    number={totalTrips}
                                    icon="sailing"
                                    trend={`${tripsPercentageThisMonth >= 0 ? 'trending_up' : 'trending_down'}`}
                                    percent={`+ ${tripsPercentageThisMonth}%`}
                                    compare="this month"
                                />
                            </Link>
                        </Col> */}
                        {/* ))} */}
                    </Row>
                </Col>
            </Row>
        </PageLayout>
    );
}

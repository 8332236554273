import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "../../components/cards";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import * as API from "../../api/messages";
import data from "../../data/master/customerQuestionsList.json";
import { toast } from "react-toastify";
import CustomerQuestionsTable from "../../components/tables/CustomerQuestionsTable";


export default function CustomerQuestionsList({ messages }) {
  const [messageData, setMessageData] = useState(messages);

  async function fetchData() {
    try {
      const res = await API.getAll();
      let data = res.data.data;
      data.reverse();
      setMessageData(data);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error.response.data.message);
      else toast.error("Failed to fetch messages");
      console.log(error.message);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"All messages"} loadingIcon={true} fetchData={fetchData}>
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>

              <Col xl={12}>
                <CustomerQuestionsTable thead={data?.trip.thead} tbody={messageData} />
                {/* <Pagination /> */}
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}

import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "../../components/cards";
import TripsTable from "../../components/tables/TripsTable";
import { Breadcrumb } from "../../components";
import Anchor from "../../components/elements/Anchor";
import PageLayout from "../../layouts/PageLayout";
import * as tripAPI from '../../api/trips'
import data from "../../data/master/tripList.json";
import { toast } from "react-toastify";
import { useEffect } from "react";

export default function TripList() {

    const [tripData, setTripData] = useState([]);

    const fetchTripData = async () => {
        try {
            const { data } = await tripAPI.getTrip();
            if (data.status) {
                setTripData(data.data)
            }
        } catch (error) {
            if (error?.response?.data?.message) toast.error(error.response.data.message)
            else toast.error('Failed to fetch Trips')
            console.log(error.message);
        }
    }

    useEffect(() => {
        fetchTripData();
    }, [])

    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title={data?.pageTitle}>
                            {data?.breadcrumb.map((item, index) => (
                                <li key={index} className="mc-breadcrumb-item">
                                    {item.path ? <Anchor className="mc-breadcrumb-link" href={item.path}>{item.text}</Anchor> : item.text}
                                </li>
                            ))}
                        </Breadcrumb>
                    </CardLayout>
                </Col>

                <Col xl={12}>
                    <CardLayout>
                        <Row>

                            <Col xl={12}>
                                <TripsTable
                                    thead={data?.trip.thead}
                                    tbody={tripData}

                                />

                            </Col>
                        </Row>
                    </CardLayout>
                </Col>
            </Row>
        </PageLayout>
    );
}

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Button, Image, Input, Label, Icon, Text } from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import * as API from "../../api/boats";
import ChipInput from 'material-ui-chip-input'
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function EditBoat() {

    const id = useParams().id;
    const navigate = useNavigate();

    const [image, setImage] = useState([])
    const [enSpecs, setEnSpecs] = useState([])
    const [areSpecs, setAreSpecs] = useState([])
    const [form, setForm] = useState({
        enTitle: '',
        enDesc: '',
        areTitle: '',
        areDesc: '',
        type: 'Boat',
        totalCapacity: '',
        size: '',
        status: 'Active',
        boatNo:''
    })
    const fetchData = async () => {
        try {
            const res = await API.getBoatDetails(id);
            const data = res.data.data
            setForm({
                enTitle: data.en.title,
                enDesc: data.en.description,
                areTitle: data.are.title,
                areDesc: data.are.description,
                type: data.type,
                totalCapacity: data.totalCapacity,
                size: data.size,
                status: data.status,
                boatNo: data?.boatNumber,
            })
            setEnSpecs(data.en.specification)
            setAreSpecs(data.are.specification)
            setImage(data.image)
        } catch (error) {
            if (error.response.data.message) toast.error(error.response.data.message)
            else toast.error('Failed to fetch Boat details')
            console.log(error.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])


    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title={form.enTitle}>
                            <Button
                                className={`material-icons icons p-2`}
                                style={{
                                    marginLeft: "15px",
                                    borderRadius: "100%",
                                    color: "white",
                                    backgroundColor: "#5e5d72",
                                }}
                                onClick={() => navigate(`/edit-boat/${id}`)}
                            >
                                edit
                            </Button>
                        </Breadcrumb>
                    </CardLayout>
                </Col>
                <Col xl={6}>
                    <CardLayout>
                        <Row>
                            <Col xl={6}><LabelField type="text" disabled label="english title" name='enTitle' value={form.enTitle} fieldSize="w-100 h-md" /></Col>
                            <Col xl={6}><LabelField type="text" disabled label="arabic title" name='areTitle' value={form.areTitle} fieldSize="w-100 h-md" /></Col>
                            {/* <Col xl={6}><LabelTextarea disabled label="english description" name='enDesc' value={form.enDesc} fieldSize="w-100 h-text-md" /></Col>
                            <Col xl={6}><LabelTextarea disabled label="arabic description" name='areDesc' value={form.areDesc} fieldSize="w-100 h-text-md" /></Col> */}
                            <Col xl={6}><LabelField disabled type="text" label="Boat Number" name="boatNo" value={form.boatNo} fieldSize="w-100 h-md" /></Col>
                            <Col xl={6}><LabelField disabled label="boat type" name='type' value={form.type} option={['Boat', 'Yacht']} fieldSize="w-100 h-md" /></Col>
                            <Col xl={6}><LabelField disabled type="text" label="boat size" name='size' value={form.size} fieldSize="w-100 h-md" /></Col>
                            <Col xl={6}><LabelField disabled label="status" name='status' value={form.status} option={['Active', 'Inactive']} fieldSize="w-100 h-md" /></Col>
                            <Col xl={12}><LabelField disabled type="number" label="total capacity" name='totalCapacity' value={form.totalCapacity} fieldSize="w-100 h-md" /></Col>
                            <Col xl={6}>
                                <Label className="mc-label-field-title">english specifications</Label>
                                <ChipInput
                                    disabled
                                    style={{ margin: '10px 0' }}
                                    value={enSpecs}

                                    variant="outlined"

                                />
                            </Col>
                            <Col xl={6}>
                                <Label className="mc-label-field-title">arabic specifications</Label>
                                <ChipInput
                                    disabled
                                    style={{ margin: '10px 0' }}
                                    value={areSpecs}
                                    variant="outlined"

                                />
                            </Col>
                        </Row>
                    </CardLayout>
                </Col>

                <Col xl={6}>
                    <CardLayout>
                        <Box className="mc-trip-upload-media">
                            {
                                image.map((img, index) => (
                                    <Box key={img.name} className="mc-trip-upload-image">
                                        {typeof img === 'string' ? (
                                            <Image src={img} alt="boat image" style={{ maxHeight: '250px' }} />
                                        ) : (
                                            <Image src={URL?.createObjectURL?.(img)} alt="boat image" style={{ maxHeight: '250px' }} />
                                        )}
                                    </Box>
                                ))
                            }
                        </Box>
                    </CardLayout>
                </Col>
            </Row>
        </PageLayout>
    )
}

import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Heading, Box, Text, Icon, Button, Label } from "../elements";
import * as API from '../../api/messages'
import { Col, Row } from "react-bootstrap";
import { LabelTextarea } from "../fields";
import { toast } from "react-toastify";
import EmptyList from "../EmptyList";

export default function CustomerQuestionsTable({ thead, tbody }) {
    const [alertModal, setAlertModal] = useState({
        active: false,
        _id: '',
        index: ''
    });
    const [sending, setSending] = useState('Send Mail')
    const [replyModal, setReplyModal] = useState({
        active: false,
        _id: '',
        question: '',
        reply: '',
        email: '',
        phone: "",
        index: ''
    });

    const replyToUser = async (id, reply, index) => {
        try {
            setSending('Sending mail...')
            const res = await API.replyToUser(replyModal._id, reply)
            let tempData = data
            tempData[index].isReplied = true
            tempData[index].isSeen = true
            setData(tempData)
            setSending('Send Mail')
            setReplyModal({
                acitve: false,
                _id: '',
                question: '',
                reply: '',
                email: '',
                phone: "",
                index: ''
            })
        } catch (error) {
            console.log(error.message);
        }
    }

    const deleteMessage = async (id, index) => {
        try {
            const res = await API.deleteMessage(id);
            let tempData = data
            tempData.splice(index, 1)
            setData([...tempData])
            if (res && res.data.status) toast.warning('Message deleted')
        } catch (error) {
            if (error?.response?.data?.message) toast.error(error.response.data.message)
            else toast.error('Failed to delete message')
            console.log(error.message);
        }
    }

    const [data, setData] = useState([]);

    const getDate = (date) => {
        let newDate = date.split('T')[0]
        return newDate
    }
    useEffect(() => { setData(tbody) }, [tbody]);


    return (
        <Box className="mc-table-responsive">
            {data.length === 0 && <EmptyList title={'Messages'} />}
            {data.length !== 0 &&
                <Table className="mc-table trip">
                    <Thead className="mc-table-head primary">
                        <Tr>
                            <Th>
                                <Box className="mc-table-check">
                                    <Text>uid</Text>
                                </Box>
                            </Th>
                            {thead.map((item, index) => (
                                <Th key={index}>{item}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody className="mc-table-body even">
                        {data?.map((item, index) => (
                            <Tr key={index}>
                                <Td title={index + 1}>
                                    <Box className="mc-table-check">
                                        <Text>#{index + 1}</Text>
                                    </Box>
                                </Td>
                                <Td className={'!max-w-max'}>
                                    <Box className="mc-table-trip md">
                                        <Box className="mc-table-group">
                                            <Heading as="h6">{item?.fullName}</Heading>
                                        </Box>
                                    </Box>
                                </Td>
                                <Td className={'!max-w-max'}>
                                    <Box className="mc-table-trip md">
                                        <Box className="mc-table-group">
                                            <Text>{item?.email}</Text>
                                        </Box>
                                    </Box>
                                </Td>
                                <Td className={'!max-w-max'}>
                                    <Box className="mc-table-trip md">
                                        <Box className="mc-table-group">
                                            <Text className={`${item?.phone === null ? 'Placeholder' : 'N/A'}`}>
                                                {item?.phone !== null ? item?.phone : 'N/A'}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Box className="mc-table-trip md">
                                        <Box className="mc-table-group">
                                            <Text>{item?.message}</Text>
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    {item?.isReplied === true && <Text className="mc-table-badge green">True</Text>}
                                    {item?.isReplied === false && <Text className="mc-table-badge purple">False</Text>}
                                </Td>
                                <Td className={'!max-w-max'}>
                                    <Box className="mc-table-trip md">
                                        <Box className="mc-table-group">
                                            <Text>{item?.createdAt ? getDate(item.createdAt) : ''}</Text>
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Box className="mc-table-action">
                                        <Button title="Reply" className="material-icons edit" onClick={() => {
                                            setReplyModal({ active: true, _id: item?._id, index: index, email: item?.email, question: item?.message, reply: '' });
                                        }}>send</Button>
                                        <Button title="Delete" className="material-icons delete" onClick={() => { setAlertModal({ active: true, id: item?._id, index: index, email: item?.email, question: item?.message, reply: '' }) }}>delete</Button>
                                    </Box>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            }

            <Modal show={replyModal.active} onHide={() => setReplyModal({ active: false, _id: '', index: '', email: '', phone: "", question: '', reply: '' })}>
                <Box className="mc-alert-modal">
                    <Icon type="new_releases" />
                    <Text as="p">User's message</Text>
                    <Row>
                        <Label text={replyModal.question} />
                        <Col xl={12}>
                            <LabelTextarea label="Your reply" onChange={(e) => setReplyModal({ ...replyModal, reply: e.target.value })} name='totalCapacity' value={replyModal.reply} fieldSize="w-100 h-md" />
                        </Col>
                    </Row>
                    <Modal.Footer>
                        <Button type="btn" className="btn btn-secondary" onClick={() => setReplyModal({ active: false, _id: '', index: '', email: '', phone: "", question: '', reply: '', })}>Cancel</Button>
                        <Button type="btn" className="btn btn-danger" onClick={() => { replyToUser(replyModal._id, replyModal.reply, replyModal.index); setAlertModal({ active: false, _id: '', index: '', email: '', question: '', reply: '', }) }}>{sending}</Button>
                    </Modal.Footer>
                </Box>
            </Modal>

            <Modal show={alertModal.active} onHide={() => setAlertModal({ active: false, id: '', index: '' })}>
                <Box className="mc-alert-modal">
                    <Icon type="new_releases" />
                    <Heading as="h3">are your sure!</Heading>
                    <Text as="p">Want to delete this message?</Text>
                    <Modal.Footer>
                        <Button type="btn" className="btn btn-secondary" onClick={() => setAlertModal({ active: false, id: '', index: '' })}>nop, close</Button>
                        <Button type="btn" className="btn btn-danger" onClick={() => { deleteMessage(alertModal.id, alertModal.index); setAlertModal({ active: false, id: '', index: '' }) }}>yes, delete</Button>
                    </Modal.Footer>
                </Box>
            </Modal>

        </Box>
    );
}
import React, { useState } from "react";
import { Box, List, Heading } from "./elements";
import { LuRefreshCw } from "react-icons/lu";

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    refresh: {
        margin: "auto",
    },
    spin: {
        margin: "auto",
        animation: "$spin 1s 1",
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)",
        },
    }
}))

export default function Breadcrumb({ title, children, className, loadingIcon, fetchData }) {

    const [spin, setSpin] = React.useState(0);

    const classes = useStyles();

    const refreshCanvas = () => {
        setSpin(true);
        fetchData()
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };

    return (
        <Box className={`mc-breadcrumb ${className ? className : ""}`}>
            <div className="flex items-center gap-3">
                <Heading className="mc-breadcrumb-title">{title}</Heading>
                {
                    loadingIcon &&
                    <div title="Refresh Data">
                        <LuRefreshCw spin={spin} onClick={refreshCanvas} className={`${spin ? classes.spin : classes.refresh} text-2xl cursor-pointer`} />
                    </div>
                }
            </div>
            <List className="mc-breadcrumb-list">{children}</List>
        </Box>
    )
}
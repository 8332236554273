import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import {Button} from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout, CardHeader } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import * as API from '../../api/faqs'
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function EditFaq() {
  const navigate = useNavigate();
  const id = useParams().id
  const [form, setForm] = useState({
    enQuestion: '',
    enAnswer: '',
    areQuestion: '',
    areAnswer: ''
  })

  const resetForm = () => {
    setForm({
      enQuestion: '',
      enAnswer: '',
      areQuestion: '',
      areAnswer: ''
    })
  }
  const handleSubmit = async () => {
    try {
      const res = await API.updateFaq(id, {
        en: {
          question: form.enQuestion,
          answer: form.enAnswer
        },
        are: {
          question: form.areQuestion,
          answer: form.areAnswer
        }
      })
      if (res && res.data.status) toast.success('FAQ updated successfully')
      resetForm();
      toast.success('FAQ updated')
      navigate('/faqs-list')

    } catch (error) {
      if (error?.response?.data?.message) toast.error(error.response.data.message)
      else toast.error('Failed to update FAQ')
      console.log(error.message);
    }
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const res = await API.getFaqById(id);
        const data = res.data;
        setForm({
          enQuestion: data.en.question,
          enAnswer: data.en.answer,
          areQuestion: data.are.question,
          areAnswer: data.are.answer
        })
      } catch (error) {
        if (error.response.data.message)
          toast(error.response.data.message)
        else
          toast.error('Failed to fetch FAQ details')
      }
    }
    if (id)
      fetchData(id);
  }, [id])
  const [data, setData] = useState([]);
  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title='edit faq'>
              <Button
                className={`material-icons icons p-2`}
                style={{
                  marginLeft: "15px",
                  borderRadius: "100%",
                  color: "white",
                  backgroundColor: "#5e5d72",
                }}
                onClick={() => navigate(`/faqs-list`)}
              >
                cancel
              </Button>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={6}>
          <CardLayout>
            <CardHeader title="english details" dotsMenu={data?.dotsMenu} />
            <Row>
              <Col xl={12}>
                <LabelField
                  type="text"
                  label="question"
                  fieldSize="w-100 h-md"
                  value={form.enQuestion}
                  onChange={handleChange}
                  name='enQuestion'
                />
              </Col>
              <Col xl={12}><LabelTextarea label="answer" name='enAnswer' value={form.enAnswer} onChange={handleChange} fieldSize="w-100 h-text-md" /></Col>
            </Row>
          </CardLayout>
        </Col>

        <Col xl={6}>
          <CardLayout>
            <CardHeader title="Arabic details" dotsMenu={data?.dotsMenu} />
            <Row>
              <Col xl={12}>
                <LabelField
                  type="text"
                  label="question"
                  fieldSize="w-100 h-md"
                  name='areQuestion'
                  value={form.areQuestion}
                  onChange={handleChange}
                />
              </Col>
              <Col xl={12}><LabelTextarea label="answer" name='areAnswer' value={form.areAnswer} onChange={handleChange} fieldSize="w-100 h-text-md" /></Col>
            </Row>
          </CardLayout>
        </Col>
        <Col xl={12}>
          <CardLayout className={'d-flex justify-content-center'}>
            <Button type="btn" className={'btn btn-primary text-xl '} onClick={handleSubmit}>Update FAQ</Button>
          </CardLayout>
        </Col>
      </Row>

    </PageLayout>
  );
}

import React, { useState,useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "../../components/cards";
import AddonTable from "../../components/tables/AddonTable";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import * as API from '../../api/addons'
import data from "../../data/master/addonList.json";
import { toast } from "react-toastify";

    export default function AddOnList() {
        const [addonData,setAddonData] = useState([])
        useEffect(()=>{
            async function fetchData(){
                try {
                    const res = await API.getAddons();
                    const data = res.data.data;
                    setAddonData(data);
                } catch (error) {
                    if(error.response.data.message) toast.error(error.response.data.message)
                    else    toast.error('Failed to fetch Addons')
                    console.log(error.message);
                }
            }
            fetchData();
        },
        []
    )
    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title={ data?.pageTitle }>
                        </Breadcrumb>
                    </CardLayout>
                </Col>
                <Col xl={12}>
                    <CardLayout>
                        <Row>
                            <Col xl={12}>
                                <AddonTable 
                                    thead = { data?.trip.thead } 
                                    tbody = { addonData } 
                                />
                            </Col>
                        </Row>
                    </CardLayout>
                </Col>
            </Row>
        </PageLayout>
    );
}

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "../../components/cards";
import { Breadcrumb } from "../../components";
import Loader from "../../components/Loader";
import Anchor from "../../components/elements/Anchor";
import PageLayout from "../../layouts/PageLayout";
import * as API from '../../api/blogs'
import data from "../../data/master/blogList.json";
import BlogsTable from "../../components/tables/BlogsTable";
import { toast } from "react-toastify";

export default function BlogsList() {
    const [blogData, setBlogData] = useState([])
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            try {
                const res = await API.getAllBlogs();
                if (res?.data?.status) {
                    const data = res?.data?.data;
                    setBlogData(data);
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)
                if (error.response.data.message) toast.error(error?.response?.data?.message)
                else toast.error('Failed to fetch Blogs')
                console.log(error.message);
            }
        }
        fetchData();
    },
        []
    )
    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title={data?.pageTitle}>
                            {data?.breadcrumb.map((item, index) => (
                                <li key={index} className="mc-breadcrumb-item">
                                    {item.path ? <Anchor className="mc-breadcrumb-link" href={item.path}>{item.text}</Anchor> : item.text}
                                </li>
                            ))}
                        </Breadcrumb>
                    </CardLayout>
                </Col>
                <Col xl={12}>
                    <CardLayout>
                        <Row>
                            <Col xl={12}>
                                {
                                    loading
                                        ?
                                        <Loader />
                                        :
                                        <BlogsTable
                                            thead={data?.trip.thead}
                                            tbody={blogData}
                                        />
                                }
                            </Col>
                        </Row>
                    </CardLayout>
                </Col>
            </Row>
        </PageLayout>
    );
}

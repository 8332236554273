import React, { useContext, useEffect, useState } from "react";
import { MultipleMenu, Logout, MenuItem } from "../components/sidebar";
import { DrawerContext } from "../context/Drawer";
import Section from "../components/elements/Section";
import data from "../data/master/sidebar.json";
import { Heading, List, Menu } from "../components/elements";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Sidebar({user, setUser}) {

  const { drawer } = useContext(DrawerContext);

  return (
    <>
      <Section
        as="aside"
        className={`mc-sidebar thin-scrolling ${drawer ? "active" : ""}`}
      >
        <MultipleMenu data={user?.role === 'admin' ? data?.navs: data?.partnerNavs} />
        {/* <Menu className="mc-sidebar-menu">
          <List className="mc-sidebar-menu-list">
            <MenuItem onClick={handleLogout} item={item} />
          </List>
        </Menu> */}
      </Section>
    </>
  );
}

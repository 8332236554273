import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import {  Box,  Image,  Button } from "../../components/elements";
import { Breadcrumb, DivideTitle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import CardLayout from "../../components/cards/CardLayout";
import * as API from '../../api/gallery'
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export default function Gallery() {
    const navigate = useNavigate();
    const [images, setImages] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await API.getAllImages();
                setImages(res.data.data.gallery.image)
            } catch (error) {
                if (error.response.data.message) toast.error(error.response.data.message)
                else toast.error('Failed to fetch images')
            }
        }
        fetchData();
    }, [])



    return (
        <PageLayout>
            <CardLayout className="mb-4">
                <Breadcrumb title='Gallery'>
                    <Button
                        className={`material-icons icons p-2`}
                        style={{
                            marginLeft: "15px",
                            borderRadius: "100%",
                            color: "white",
                            backgroundColor: "#5e5d72",
                        }}
                        onClick={() => navigate(`/edit-gallery`)}
                    >
                        edit
                    </Button>
                </Breadcrumb>
            </CardLayout>
            <CardLayout className="p-lg-5">
                <Row>
                    <Col xl={12}>
                        <DivideTitle title="gallery" className="mb-4" />
                        {
                            images.length === 0 &&
                            <Box className='mc-trip-upload-media' >
                                <p className="m-auto ">No images to show</p>
                            </Box>
                        }
                        <Box className='mc-trip-upload-media' >
                            {
                                images.map((img, index) => (
                                    <Box key={img.name} className="mc-trip-upload-image">
                                        {typeof img === 'string' ? (
                                            <Image key={index} src={img} alt="boat image" style={{ maxHeight: '250px' }} />
                                        ) : (
                                            <Image key={index} src={URL?.createObjectURL?.(img)} alt="boat image" style={{ maxHeight: '250px' }} />
                                        )}
                                    </Box>
                                ))
                            }
                        </Box>
                        {/* <Button type = "btn" className="btn btn-primary" onClick={goToEdit}>Edit Gallery</button> */}
                    </Col>

                </Row>
            </CardLayout>
        </PageLayout>
    )
}
import React, { useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Box, Text, Item, Anchor, Button } from "../../components/elements";
import { Breadcrumb, DivideTitle } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/terms.json";
import * as API from "../../api/terms";
import { useState } from "react";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";

const Terms = () => {
  const [editHome, setEditHome] = useState(false);
  const [editPages, setEditPages] = useState(false);
  const [areContent, setAreContent] = useState('');
  const [enContent, setEnContent] = useState('');

  const handleSubmit = async () => {
    try {
      const { data } = await API.postTerms({
        en: {
          content: enContent,
        },
        are: {
          content: areContent,
        },
      });
      
      toast.success("Data updated succesfully");
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error.response.data.message);
      else toast.error("Failed to update data");
    }
  };
  const handleHomeEdit = () => {
    setEditHome(!editHome);

    setEditPages(false);
  };

  const handleSubmitHome = async () => {
    try {
      await handleSubmit();
      setEditHome(!editHome);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error.response.data?.message);
      else toast.error("Something went wrong");
      console.log(error);
    }
  };

  const setAllFields = (data) => {
    
      setEnContent(data?.en?.content);

      setAreContent(data?.are?.content)
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await API.getTerms();
        const data = res?.data?.data[0];
        setAllFields(data);
      } catch (error) {
        if (error?.response?.data?.message)
          toast.error(error.response.data?.message);
        else toast.error("Failed to fetch details");
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <PageLayout>
        <CardLayout className="mb-4">
          <Breadcrumb title={data?.pageTitle}>
            {data?.breadcrumb.map((item, index) => (
              <Item key={index} className="mc-breadcrumb-item">
                {item.path ? (
                  <Anchor className="mc-breadcrumb-link" href={item.path}>
                    {item.text}
                  </Anchor>
                ) : (
                  item.text
                )}
              </Item>
            ))}
          </Breadcrumb>
        </CardLayout>
        <CardLayout className="p-sm-5 mb-4">
          <Box className="mb-0">
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <DivideTitle
                title="Terms & Condition"
                className="mb-4"
                style={{ marginBottom: "0 !important" }}
              />
              
              <Button
                className={`material-icons icons mb-4 p-2 ${
                  !editHome ? "d-none" : ""
                }`}
                style={{
                  marginLeft: "15px",
                  borderRadius: "100%",
                  color: "white",
                  backgroundColor: "#5e5d72",
                }}
                onClick={handleHomeEdit}
              >
                cancel
              </Button>
            </div> */}
            <Row>
              <Col xl={12} className="">
                <Row>
                  <Col xl={6}>
                   
                    <h2 className="text-2xl font-bold mb-4">T&C(English)</h2>
                  <JoditEditor value={enContent} onBlur={setEnContent} />
                  </Col>
                  <Col xl={6}>
                  <h2 className="text-2xl font-bold mb-4">T&C(Arabic)</h2>
                  <JoditEditor value={areContent} onBlur={setAreContent}/>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Button
              className={`mt-5 mb-0 btn btn-primary flex justify-center items-center gap-1`}
              icon="verified"
              text="save all changes"
              onClick={handleSubmitHome}
            />
          </Box>
        </CardLayout>
        {/* <Button type = "btn" className={`mc-btn primary ${editPages?'':'d-none'}`} icon="verified" text="save all changes" onClick={handlePageDetailsSubmit} /> */}
      </PageLayout>
    </div>
  );
};

export default Terms;

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./css/bootstrap.min.css"
import "./css/icons.css"
import "./css/bullets.css"
import "./css/fonts/text.css"
import "./css/fonts/icofont/icofont.css"
import "./css/styles.min.css"
import './css/styles.css'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Item, Anchor, Button, Input, Label, Icon, Text, Image } from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { Breadcrumb, DivideTitle } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/settings.json";
import * as API from '../../api/cms'
import { useState } from "react";
import { toast } from "react-toastify";

export default function Settings() {

    const [editHome, setEditHome] = useState(false)
    const [uploadingHomeData, setUploadingHomeData] = useState(false);
    const [editFooter, setEditFooter] = useState(false)
    const [editPages, setEditPages] = useState(false)
    const [coverImage, setCoverImage] = useState([]);
    const [bannerImage, setBannerImage] = useState([]);
    const [bannerVideo, setBannerVideo] = useState([]);

    const handleSubmit = async () => {
        try {

            const data = new FormData();

            data.append('en.hero.title1', form?.enTitle1);
            data.append('en.hero.title3', form?.enTitle3);
            data.append('en.hero.title4', form?.enTitle4);
            data.append('en.copyRight', footerForm?.enCopyRight);
            data.append('en.ourServices.title', pageDetails?.enOurServicesTitle);
            data.append('en.ourServices.description', pageDetails?.enOurServicesDescription);
            data.append('en.ourServicesHome.description', pageDetails?.enHomePageOurServicesDescription);
            data.append('en.homeAbout.title', form?.enAboutTitle);
            data.append('en.homeAbout.description', form?.enAboutDescription);
            data.append('en.homeAbout.status', form?.status);
            data.append('image', coverImage[0]);
            data.append('bannerImage', bannerImage[0]);
            data.append('bannerVideo', bannerVideo[0]);
            data.append('en.gallery.title', pageDetails?.enGalleryTitle);
            data.append('en.gallery.description', pageDetails?.enGalleryDescription);
            data.append('en.blog.title', pageDetails?.enBlogTitle);
            data.append('en.blog.description', pageDetails?.enBlogDescription);
            data.append('en.contactUs.title', pageDetails?.enContactUsTitle);
            data.append('en.contactUs.description', pageDetails?.enContactUsDescription);

            data.append('are.hero.title1', form?.areTitle1);
            data.append('are.hero.title3', form?.areTitle3);
            data.append('are.hero.title4', form?.areTitle4);
            data.append('are.copyRight', footerForm?.areCopyRight);
            data.append('are.ourServices.title', pageDetails?.areOurServicesTitle);
            data.append('are.ourServices.description', pageDetails?.areOurServicesDescription);
            data.append('are.ourServicesHome.description', pageDetails?.areHomePageOurServicesDescription);
            data.append('are.homeAbout.title', form?.areAboutTitle);
            data.append('are.homeAbout.description', form?.areAboutDescription);
            data.append('are.gallery.title', pageDetails?.areGalleryTitle);
            data.append('are.gallery.description', pageDetails?.areGalleryDescription);
            data.append('are.blog.title', pageDetails?.areBlogTitle);
            data.append('are.blog.description', pageDetails?.areBlogDescription);
            data.append('are.contactUs.title', pageDetails?.areContactUsTitle);
            data.append('are.contactUs.description', pageDetails?.areContactUsDescription);

            data.append('contactInfo.email', footerForm?.email);
            data.append('contactInfo.phone', footerForm?.phone);


            data.append('socialLinks.fb', footerForm?.facebook);
            data.append('socialLinks.insta', footerForm?.instagram);
            data.append('socialLinks.linkedin', footerForm?.linkedin);
            data.append('socialLinks.pinterest', footerForm?.pinterest);
            data.append('socialLinks.tiktok', footerForm?.tiktok);
            data.append('socialLinks.twitter', footerForm?.twitter);
            data.append('socialLinks.youtube', footerForm?.youtube);
            data.append('socialLinks.thread', footerForm?.thread);


            const res = await API.createStaticData(data);
            if (res.data.status) {
                toast.success('Data updated succesfully')
                fetchData();
            }
        } catch (error) {
            if (error?.response?.data?.message) toast.error(error.response.data.message)
            else toast.error('Failed to update data')
        }
    }

    const handleSubmitHome = async () => {
        try {
            setUploadingHomeData(true)
            await handleSubmit();
            setUploadingHomeData(false)
            setEditHome(!editHome)
        } catch (error) {
            if (error?.response?.data?.message) toast.error(error.response.data?.message)
            else toast.error('Something went wrong')
            console.log(error.message);
        }
    }

    const handleFooterSubmit = async () => {
        try {
            handleSubmit();
            setEditFooter(!editFooter)
        } catch (error) {
            if (error?.response?.data?.message) toast.error(error.response.data?.message)
            else toast.error('Failed to update Footer Details')
        }
    }

    const handlePageDetailsSubmit = async () => {
        try {
            handleSubmit();
            setEditPages(!editPages)
        } catch (error) {
            if (error?.response?.data?.message) toast.error(error.response.data?.message)
            else toast.error('Failed to update information')
        }
    }

    const [footerForm, setFooterForm] = useState({
        email: '',
        phone: '',
        facebook: '',
        instagram: '',
        linkedin: '',
        pinterest: '',
        tiktok: '',
        twitter: '',
        youtube: '',
        thread: '',
        enCopyRight: '',
        areCopyRight: ''
    })

    const [form, setForm] = useState({
        enTitle1: '',
        enTitle3: '',
        enTitle4: '',
        areTitle1: '',
        areTitle3: '',
        areTitle4: '',
        enAboutTitle: '',
        areAboutTitle: '',
        enAboutDescription: '',
        areAboutDescription: '',
        status: false
    })

    const [pageDetails, setPageDetails] = useState({
        enOurServicesTitle: '',
        enOurServicesDescription: '',
        enHomePageOurServicesDescription: '',
        enGalleryTitle: '',
        enGalleryDescription: '',
        enBlogTitle: '',
        enBlogDescription: '',
        enContactUsTitle: '',
        enContactUsDescription: '',
        areOurServicesTitle: '',
        areOurServicesDescription: '',
        areHomePageOurServicesDescription: '',
        areGalleryTitle: '',
        areGalleryDescription: '',
        areBlogTitle: '',
        areBlogDescription: '',
        areContactUsTitle: '',
        areContactUsDescription: '',
    })

    const setAllFields = (data) => {
        const image = data?.image;
        if (image) {
            setCoverImage([image])
        } else {
            setCoverImage([])
        }
        const bannerImage = data?.bannerImage;
        if (bannerImage) {
            setBannerImage([bannerImage])
        } else {
            setBannerImage([])
        }
        const bannerVideo = data?.bannerVideo;
        if (bannerVideo) {
            setBannerVideo([bannerVideo])
        } else {
            setBannerVideo([])
        }

        setForm({
            enTitle1: data?.en?.hero?.title1,
            enTitle3: data?.en?.hero?.title3,
            enTitle4: data?.en?.hero?.title4,
            status: data?.en?.homeAbout?.status,
            areTitle1: data?.are?.hero?.title1,
            areTitle3: data?.are?.hero?.title3,
            areTitle4: data?.are?.hero?.title4,
            enAboutTitle: data?.en?.homeAbout?.title,
            areAboutTitle: data?.are?.homeAbout?.title,
            enAboutDescription: data?.en?.homeAbout?.description,
            areAboutDescription: data?.are?.homeAbout?.description,
        })

        setFooterForm({
            email: data?.contactInfo?.email,
            phone: data?.contactInfo?.phone,
            facebook: data?.socialLinks?.fb,
            instagram: data?.socialLinks?.insta,
            linkedin: data?.socialLinks?.linkedin,
            pinterest: data?.socialLinks?.pinterest,
            tiktok: data?.socialLinks?.tiktok,
            twitter: data?.socialLinks?.twitter,
            youtube: data?.socialLinks?.youtube,
            thread: data?.socialLinks?.thread,
            enCopyRight: data?.en?.copyRight,
            areCopyRight: data?.are?.copyRight
        })

        setPageDetails({
            enOurServicesTitle: data?.en?.ourServices?.title,
            enOurServicesDescription: data?.en?.ourServices?.description,
            enHomePageOurServicesDescription: data?.en?.ourServicesHome?.description,
            areHomePageOurServicesDescription: data?.are?.ourServicesHome?.description,
            enGalleryTitle: data?.en?.gallery?.title,
            enGalleryDescription: data?.en?.gallery?.description,
            enBlogTitle: data?.en?.blog?.title,
            enBlogDescription: data?.en?.blog?.description,
            enContactUsTitle: data?.en?.contactUs?.title,
            enContactUsDescription: data?.en?.contactUs?.description,
            areOurServicesTitle: data?.are?.ourServices?.title,
            areOurServicesDescription: data?.are?.ourServices?.description,
            areGalleryTitle: data?.are?.gallery?.title,
            areGalleryDescription: data?.are?.gallery?.description,
            areBlogTitle: data?.are?.blog?.title,
            areBlogDescription: data?.are?.blog?.description,
            areContactUsTitle: data?.are?.contactUs?.title,
            areContactUsDescription: data?.are?.contactUs?.description,
        })
    }

    const handleHomeChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }
    const handleFooterChange = (e) => {
        setFooterForm({ ...footerForm, [e.target.name]: e.target.value })
    }
    const handlePageDetailsChange = (e) => {
        setPageDetails({ ...pageDetails, [e.target.name]: e.target.value })
    }

    const fetchData = async () => {
        try {
            const res = await API.getStaticData();
            const data = res?.data?.data
            setAllFields(data)
        } catch (error) {
            if (error?.response?.data?.message) toast.error(error.response.data?.message)
            else toast.error('Failed to fetch details')
        }
    }
    useEffect(() => {
        fetchData();
    }, [])

    const handleHomeEdit = () => {
        setEditHome(!editHome)
        setEditFooter(false)
        setEditPages(false)
    }

    const handleFooterEdit = () => {
        setEditFooter(!editFooter)
        setEditHome(false)
        setEditPages(false)
    }

    const handlePageEdit = () => {
        setEditPages(!editPages)
        setEditFooter(false)
        setEditHome(false)
    }

    const handleCoverImage = (event) => {
        const files = Array.from(event.target.files)
        setCoverImage(files)
    }
    const handleBannerImageChange = (event) => {
        const files = Array.from(event.target.files)
        setBannerImage(files)
    }
    const handleBannerVideoChange = (event) => {
        const files = Array.from(event.target.files)
        setBannerVideo(files)
    }

    const handleStatusChange = (e) => {
        if (e.target.value === "true") setForm({ ...form, [e.target.name]: true });
        else if (e.target.value === "false")
            setForm({ ...form, [e.target.name]: false });
        else setForm({ ...form, [e.target.name]: e.target.value });
    };
console.log(coverImage);
    return (
        <PageLayout>
            <CardLayout className="mb-4">
                <Breadcrumb title={data?.pageTitle}>
                    {data?.breadcrumb.map((item, index) => (
                        <Item key={index} className="mc-breadcrumb-item">
                            {item.path ? <Anchor className="mc-breadcrumb-link" href={item.path}>{item.text}</Anchor> : item.text}
                        </Item>
                    ))}
                </Breadcrumb>
            </CardLayout>
            <CardLayout className="p-sm-5 mb-4">
                <Box className="mb-5">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DivideTitle title="Home Page" className="mb-4" style={{ marginBottom: '0 !important' }} />
                        <Button type="btn" className={`material-icons icons mb-4 p-2 ${editHome ? 'd-none' : ''}`} style={{ marginLeft: '15px', borderRadius: '100%', color: 'white', backgroundColor: '#5e5d72' }} onClick={handleHomeEdit}>edit</Button>
                        <Button type="btn" className={`material-icons icons mb-4 p-2 ${!editHome ? 'd-none' : ''}`} style={{ marginLeft: '15px', borderRadius: '100%', color: 'white', backgroundColor: '#5e5d72' }} onClick={handleHomeEdit}>cancel</Button>
                    </div>
                    <Row>
                        <Col xl={12} className="ps-xl-5">
                            <Row>
                                <Col xl={6}>
                                    <Label className="mc-label-field-title">Banner Image</Label>
                                    {
                                        bannerImage.length === 0
                                            ?
                                            <Box className="mc-trip-upload-file mt-2">
                                                <Input type="file" accept="image/*" name='bannerImage' id='bannerImage' onChange={handleBannerImageChange} />
                                                <Label htmlFor="bannerImage"><Icon type="collections" /><Text className={'w-100'}>Upload Banner Image</Text></Label>
                                            </Box>
                                            :
                                            bannerImage.map((img, index) => (
                                                <Box key={img.name} className="mc-trip-upload-image d-flex mt-2">
                                                    {
                                                        typeof img === 'string'
                                                            ?
                                                            <Image src={img} alt="boat image" style={{ maxWidth: '300px' }} />
                                                            :
                                                            <Image src={URL?.createObjectURL?.(img)} alt="boat image" style={{ maxWidth: '300px' }} />
                                                    }
                                                    {
                                                        editHome &&
                                                        <Button onClick={() => { setBannerImage([]) }} className="btn btn-danger position-absolute top-0 end-0 m-2 " >Remove</Button>
                                                    }
                                                </Box>
                                            ))
                                    }
                                </Col>
                                <Col xl={6}>
                                    <Label className="mc-label-field-title">Banner Video</Label>
                                    {
                                        bannerVideo.length === 0
                                            ?
                                            <Box className="mc-trip-upload-file mt-2">
                                                <Input type="file" accept="video/*" name='coverImage' id='bannerVideo' onChange={handleBannerVideoChange} />
                                                <Label htmlFor="bannerVideo"><Icon type="collections" /><Text className={'w-100'}>Upload Banner Video</Text></Label>
                                            </Box>
                                            :
                                            bannerVideo.map((video, index) => (
                                                <Box key={index} className="mc-trip-upload-image d-flex mt-2">
                                                    {
                                                        typeof video === 'string'
                                                            ?
                                                            <video autoPlay={false} controls={false} alt="banner Video" style={{ maxWidth: '300px' }}>
                                                                <source src={bannerVideo} type="video/mp4" />
                                                            </video>
                                                            :
                                                            <video autoPlay={false} controls={false} src={URL?.createObjectURL?.(video)} alt="banner Video" style={{ maxWidth: '300px' }}>
                                                                <source src={bannerVideo} type="video/mp4" />
                                                            </video>
                                                    }
                                                    {
                                                        editHome &&
                                                        <Button onClick={() => { setBannerVideo([]) }} className="btn btn-danger position-absolute top-0 end-0 m-2 " >Remove</Button>
                                                    }
                                                </Box>
                                            ))
                                    }
                                </Col>
                                <Col xl={6}><LabelField disabled={!editHome} name="enTitle1" value={form.enTitle1} onChange={handleHomeChange} className={`${editHome && 'bg-white'}`} label="english title 1" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField disabled={!editHome} name="areTitle1" value={form.areTitle1} onChange={handleHomeChange} className={`${editHome && 'bg-white'}`} label="arabic title 1" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelTextarea disabled={!editHome} name="enTitle3" value={form.enTitle3} onChange={handleHomeChange} className={`${editHome ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} label="english title 2" type="text" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea disabled={!editHome} name="areTitle3" value={form.areTitle3} onChange={handleHomeChange} className={`${editHome ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} label="arabic title 2" type="text" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea disabled={!editHome} name="enTitle4" value={form.enTitle4} onChange={handleHomeChange} className={`${editHome ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} label="english title 3" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea disabled={!editHome} name="areTitle4" value={form.areTitle4} onChange={handleHomeChange} className={`${editHome ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} label="arabic title 3" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea disabled={!editHome} name="enAboutTitle" value={form.enAboutTitle} onChange={handleHomeChange} className={`${editHome ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} label="English About Section(3) Title" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea disabled={!editHome} name="areAboutTitle" value={form.areAboutTitle} onChange={handleHomeChange} className={`${editHome ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} label="Arabic About Section(3) Title" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea disabled={!editHome} name="enAboutDescription" value={form.enAboutDescription} onChange={handleHomeChange} className={`${editHome ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} label="English About Section(3) Description" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea disabled={!editHome} name="areAboutDescription" value={form.areAboutDescription} onChange={handleHomeChange} className={`${editHome ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} label="Arabic About Section(3) Description" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}>
                                    <Label className="mc-label-field-title">About Page Section(3) Image</Label>
                                    {
                                        coverImage.length === 0
                                            ?
                                            <Box className="mc-trip-upload-file mt-2">
                                                <Input type="file" id='coverimage' accept="image/*" name='coverimage' onChange={handleCoverImage} />
                                                <Label htmlFor="coverimage"><Icon type="collections" /><Text className={'w-100'}>Upload Image for About Us Section 3</Text></Label>
                                            </Box>
                                            :
                                            coverImage.map((img, index) => (
                                                <Box key={img.name} className="mc-trip-upload-image d-flex mt-2">
                                                    {
                                                        typeof img === 'string'
                                                            ?
                                                            <Image src={img} alt="boat image" style={{ maxWidth: '300px' }} />
                                                            :
                                                            <Image src={URL?.createObjectURL?.(img)} alt="boat image" style={{ maxWidth: '300px' }} />
                                                    }
                                                    {
                                                        editHome &&
                                                        <Button onClick={() => { setCoverImage([]) }} className="btn btn-danger position-absolute top-0 end-0 m-2 " >Remove</Button>
                                                    }
                                                </Box>
                                            ))
                                    }
                                </Col>
                                <Col xl={6}>
                                    <LabelField
                                        className={`${editHome ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`}
                                        disabled={!editHome}
                                        label="home about section visiblity"
                                        option={["true", "false"]}
                                        name="status"
                                        onChange={handleStatusChange}
                                        value={form.status}
                                        fieldSize="w-100 h-md"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Box>

                <Button type="btn" className={`flex justify-center items-center gap-1 btn btn-primary ${editHome ? '' : 'd-none'}`} icon="verified" text={`${!uploadingHomeData ? 'save all changes' : 'Updating data...'}`} onClick={handleSubmitHome} />
            </CardLayout>

            <CardLayout className="p-sm-5 mb-4">
                <Box className="mb-5">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DivideTitle title="Footer Details" className="mb-4" style={{ marginBottom: '0 !important' }} />
                        <Button type="btn" className={`material-icons icons mb-4 p-2 ${editFooter ? 'd-none' : ''}`} style={{ marginLeft: '15px', borderRadius: '100%', color: 'white', backgroundColor: '#5e5d72' }} onClick={handleFooterEdit}>edit</Button>
                        <Button type="btn" className={`material-icons icons mb-4 p-2 ${!editFooter ? 'd-none' : ''}`} style={{ marginLeft: '15px', borderRadius: '100%', color: 'white', backgroundColor: '#5e5d72' }} onClick={handleFooterEdit}>cancel</Button>
                    </div>
                    <Row>
                        <Col xl={12} className="ps-xl-5">
                            <Row>
                                <Col xl={6}><LabelField className={`${editFooter && 'bg-white'}`} disabled={!editFooter} name="email" value={footerForm.email} onChange={handleFooterChange} label="email" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField className={`${editFooter && 'bg-white'}`} disabled={!editFooter} name="phone" value={footerForm.phone} onChange={handleFooterChange} label="phone" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField className={`${editFooter && 'bg-white'}`} disabled={!editFooter} name="facebook" value={footerForm.facebook} onChange={handleFooterChange} label="facebook" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField className={`${editFooter && 'bg-white'}`} disabled={!editFooter} name="instagram" value={footerForm.instagram} onChange={handleFooterChange} label="instagram" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField className={`${editFooter && 'bg-white'}`} disabled={!editFooter} name="linkedin" value={footerForm.linkedin} onChange={handleFooterChange} label="linkedin" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField className={`${editFooter && 'bg-white'}`} disabled={!editFooter} name="pinterest" value={footerForm.pinterest} onChange={handleFooterChange} label="pinterest" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField className={`${editFooter && 'bg-white'}`} disabled={!editFooter} name="tiktok" value={footerForm.tiktok} onChange={handleFooterChange} label="tiktok" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField className={`${editFooter && 'bg-white'}`} disabled={!editFooter} name="twitter" value={footerForm.twitter} onChange={handleFooterChange} label="twitter" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField className={`${editFooter && 'bg-white'}`} disabled={!editFooter} name="youtube" value={footerForm.youtube} onChange={handleFooterChange} label="youtube" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField className={`${editFooter && 'bg-white'}`} disabled={!editFooter} name="thread" value={footerForm.thread} onChange={handleFooterChange} label="thread" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField className={`${editFooter && 'bg-white'}`} disabled={!editFooter} name="enCopyRight" value={footerForm.enCopyRight} onChange={handleFooterChange} label="english copyright" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField className={`${editFooter && 'bg-white'}`} disabled={!editFooter} name="areCopyRight" value={footerForm.areCopyRight} onChange={handleFooterChange} label="arabic copyright" type="text" fieldSize="w-100 h-md" /></Col>
                            </Row>
                        </Col>
                    </Row>
                </Box>

                <Button type="btn" className={`flex justify-center items-center gap-1 btn btn-primary ${editFooter ? '' : 'd-none'}`} icon="verified" text="save all changes" onClick={handleFooterSubmit} />
            </CardLayout>


            <CardLayout className="p-sm-5 mb-4">
                <Box className="mb-5">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DivideTitle title="Page Details" className="mb-4" style={{ marginBottom: '0 !important' }} />
                        <Button type="btn" className={`material-icons icons mb-4 p-2 ${editPages ? 'd-none' : ''}`} style={{ marginLeft: '15px', borderRadius: '100%', color: 'white', backgroundColor: '#5e5d72' }} onClick={handlePageEdit}>edit</Button>
                        <Button type="btn" className={`material-icons icons mb-4 p-2 ${!editPages ? 'd-none' : ''}`} style={{ marginLeft: '15px', borderRadius: '100%', color: 'white', backgroundColor: '#5e5d72' }} onClick={handlePageEdit}>cancel</Button>
                    </div>
                    <Row>
                        <Col xl={12} className="ps-xl-5">
                            <Row>
                                <Col xl={6}><LabelField className={`${editPages && 'bg-white'}`} disabled={!editPages} name="enOurServicesTitle" value={pageDetails.enOurServicesTitle} onChange={handlePageDetailsChange} label="english our services title " type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField className={`${editPages && 'bg-white'}`} disabled={!editPages} name="areOurServicesTitle" value={pageDetails.areOurServicesTitle} onChange={handlePageDetailsChange} label="arabic our services title " type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelTextarea className={`${editPages ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} disabled={!editPages} name="enHomePageOurServicesDescription" value={pageDetails.enHomePageOurServicesDescription} onChange={handlePageDetailsChange} label="english our services description (for home)" type="text" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea className={`${editPages ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} disabled={!editPages} name="areHomePageOurServicesDescription" value={pageDetails.areHomePageOurServicesDescription} onChange={handlePageDetailsChange} label="arabic our services description (for home)" type="text" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea className={`${editPages ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} disabled={!editPages} name="enOurServicesDescription" value={pageDetails.enOurServicesDescription} onChange={handlePageDetailsChange} label="english our services description" type="text" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea className={`${editPages ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} disabled={!editPages} name="areOurServicesDescription" value={pageDetails.areOurServicesDescription} onChange={handlePageDetailsChange} label="arabic our services description" type="text" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelField className={`${editPages && 'bg-white'}`} disabled={!editPages} name="enGalleryTitle" value={pageDetails.enGalleryTitle} onChange={handlePageDetailsChange} label="english gallery title" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField className={`${editPages && 'bg-white'}`} disabled={!editPages} name="areGalleryTitle" value={pageDetails.areGalleryTitle} onChange={handlePageDetailsChange} label="arabic gallery title" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelTextarea className={`${editPages ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} disabled={!editPages} name="enGalleryDescription" value={pageDetails.enGalleryDescription} onChange={handlePageDetailsChange} label="english gallery description" type="text" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea className={`${editPages ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} disabled={!editPages} name="areGalleryDescription" value={pageDetails.areGalleryDescription} onChange={handlePageDetailsChange} label="arabic gallery description" type="text" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelField className={`${editPages && 'bg-white'}`} disabled={!editPages} name="enBlogTitle" value={pageDetails.enBlogTitle} onChange={handlePageDetailsChange} label="english blog page title" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField className={`${editPages && 'bg-white'}`} disabled={!editPages} name="areBlogTitle" value={pageDetails.areBlogTitle} onChange={handlePageDetailsChange} label="arabic blog page title" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelTextarea className={`${editPages ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} disabled={!editPages} name="enBlogDescription" value={pageDetails.enBlogDescription} onChange={handlePageDetailsChange} label="english blog description" type="text" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea className={`${editPages ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} disabled={!editPages} name="areBlogDescription" value={pageDetails.areBlogDescription} onChange={handlePageDetailsChange} label="arabic blog description" type="text" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelField className={`${editPages && 'bg-white'}`} disabled={!editPages} name="enContactUsTitle" value={pageDetails.enContactUsTitle} onChange={handlePageDetailsChange} label="english contact us title" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelField className={`${editPages && 'bg-white'}`} disabled={!editPages} name="areContactUsTitle" value={pageDetails.areContactUsTitle} onChange={handlePageDetailsChange} label="arabic contact us title" type="text" fieldSize="w-100 h-md" /></Col>
                                <Col xl={6}><LabelTextarea className={`${editPages ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} disabled={!editPages} name="enContactUsDescription" value={pageDetails.enContactUsDescription} onChange={handlePageDetailsChange} label="english contact us description" type="text" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea className={`${editPages ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} disabled={!editPages} name="areContactUsDescription" value={pageDetails.areContactUsDescription} onChange={handlePageDetailsChange} label="arabic contact us description" type="text" fieldSize="w-100 h-text-md" /></Col>
                            </Row>
                        </Col>
                    </Row>
                </Box>

                <Button type="btn" className={`flex justify-center items-center gap-1 btn btn-primary ${editPages ? '' : 'd-none'}`} icon="verified" text="save all changes" onClick={handlePageDetailsSubmit} />
            </CardLayout>

        </PageLayout>
    )
}
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Anchor, Button, Image, Input, Label, Icon, Text } from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import CustomOption from "../../components/fields/CustomOption";
import Multiselect from "multiselect-react-dropdown";
import * as API from "../../api/boats";
import * as AddOn from "../../api/addons";
import * as Trip from "../../api/trips";
import * as Promo from "../../api/promos";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
const slots = new Map();

const timeSlots = [
  "05:00 AM",
  "05:30 AM",
  "06:00 AM",
  "06:30 AM",
  "07:00 AM",
  "07:30 AM",
  "08:00 AM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM"
];

const convertDateToString = (date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export default function EditTrip() {
  const id = useParams().id;
  const navigate = useNavigate();

  const [currentDate, setCurrentDate] = useState(null);

  const [images, setImages] = useState([]);
  const [boats, setBoats] = useState([]);
  const [selectedBoats, setSelectedBoats] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [selectedPromos, setSelectedPromos] = useState([]);
  const [addons, setAddOns] = useState([]);
  const [promos, setPromos] = useState([]);
  const [initialAddons, setInitialAddons] = useState([]);
  const [initialPromos, setInitialPromos] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [boatId, setBoatId] = useState('');
  const [price, setPrice] = useState('');

  const boatOptions = boats.filter(obj2 =>
    !selectedBoats.some(obj1 => obj1.boatId === obj2._id)
  );



  const handleAddBoat = () => {
    if (boatId && price) {
      const isBoatAdded = selectedBoats.some((item) => item.boatId === boatId && item.price === price);
      if (isBoatAdded) {
        alert("This boat is already added with a price.");
      } else {
        setSelectedBoats([...selectedBoats, { boatId, price }]);
        setBoatId('');
        setPrice('');
      }
    }
  };

  const handleRemoveBoat = (boatIdToRemove) => {
    const updatedBoatPrices = selectedBoats.filter((item) => item.boatId !== boatIdToRemove);
    setSelectedBoats(updatedBoatPrices);
  };

  const handleEditBoat = (id, price) => {
    const updatedBoatPrices = selectedBoats.filter((item) => item.boatId !== id);
    setSelectedBoats(updatedBoatPrices);
    setBoatId(id);
    setPrice(price)
  };

  const [form, setForm] = useState({
    enTitle: "",
    areTitle: "",
    enDesc: "",
    areDesc: "",
    type: "Group",
    enLocation: "",
    areLocation: "",
    status: "Active",
    boatYacht: [],
    addons: [],
    note: "",
    endTime: "",
    enDescHome: "",
    enDescHomeEvent: "",
    areDescHome: "",
    areDescHomeEvent: "",
  });

  const handleDateChange = (date) => {
    setCurrentDate(date);
    let foundTimes = null;
    const convertedDate = convertDateToString(date);
    if (slots.has(convertedDate)) foundTimes = slots.get(convertedDate);
    if (foundTimes !== null) {
      setSelectedTimes(foundTimes);
    } else {
      setSelectedTimes([]);
    }
  };

  const handleTimeChange = (time) => {
    if (time !== "") {
      setSelectedTimes((prev) => {
        let newTimes = [...prev];
        if (newTimes.includes(time)) {
          newTimes = newTimes.filter((t) => t !== time);
        } else {
          newTimes.push(time);
        }
        return newTimes;
      });
    }
  };

  const submitIt = (e) => {
    e.preventDefault();

    const convertedDate = convertDateToString(currentDate);
    slots.set(convertedDate, selectedTimes);

    setSelectedDates([...selectedDates, currentDate]);
    setCurrentDate(null);
    setSelectedTimes([]);
  };

  const removeDate = (date) => {
    slots.delete(convertDateToString(date));
    let updatedDates = [...selectedDates];
    updatedDates.forEach((d, index) => {
      if (convertDateToString(d) === convertDateToString(date)) {
        updatedDates.splice(index, 1);
      }
    });
    setSelectedDates(updatedDates);
    setCurrentDate(null);
  };

  async function fetchData(id) {
    try {
      const { data } = await Trip.getTripById(id);
      const trip = data.data;

      setForm({
        enTitle: trip?.en.title,
        areTitle: trip?.are.title,
        enDesc: trip?.en.description,
        areDesc: trip?.are.description,
        enDescHome: trip?.en?.descriptionHome,
        areDescHome: trip?.are.descriptionHome,
        enDescHomeEvent: trip?.en.descriptionHomeEvent,
        areDescHomeEvent: trip?.are.descriptionHomeEvent,
        type: trip?.type,
        enLocation: trip?.en.location,
        areLocation: trip?.are.location,
        status: trip?.status,
        note: trip?.note,
      });
      setSelectedAddons(trip?.addons);
      setSelectedBoats(trip?.boatYacht);
      setImages(trip?.image);

      //set slots in map
      const allSlots = trip?.slots;
      let tempDates = [];
      if (allSlots !== null) {
        allSlots.forEach((slot) => {
          const date = slot.date;
          const timeArray = slot.time;
          slots.set(date, timeArray);
          tempDates.push(new Date(date));
        });
      }
      setSelectedDates([...tempDates]);

      const selectedBoats = trip?.boatYacht;
      let iniBoats = [];
      selectedBoats.forEach((boat) => {
        iniBoats.push({ boatId: boat?.boatId?._id, price: boat?.price });
      });
      setSelectedBoats(iniBoats);

      const selectedAddons = trip.addons;
      let iniAddons = [];
      selectedAddons.forEach((addon) => {
        iniAddons.push({ title: addon?.en?.title, _id: addon?._id });
      });
      setInitialAddons(iniAddons);

      const selectedPromos = trip.promos;
      let iniPromos = [];
      selectedPromos.forEach((promo) => {
        iniPromos.push({
          code: promo?.promoCode,
          status: promo.promoStatus,
          description: promo?.en?.description,
          _id: promo?._id,
        });
      });
      setInitialPromos(iniPromos.filter((p) => p.status === 'Active'));
      setSelectedPromos(iniPromos.filter((p) => p.status === 'Active'))


      const addons = await AddOn.getAddons();
      const addonData = addons?.data?.data;
      let arr = [];
      if (addonData.length > 0) {
        addonData.forEach((addon, index) => {
          arr.push({ title: addon?.en?.title, _id: addon?._id });
        });
        setAddOns(arr);
      } else {
        setAddOns([]);
      }

      const boats = await API.getBoats();
      const boatData = boats?.data?.data;
      let Boat = [];
      boatData.forEach((boat, index) => {
        if (boat.en && boat.en.title)
          Boat.push({ title: boat?.en?.title, _id: boat?._id });
      });
      setBoats(Boat);

      const promos = await Promo.getAllPromos();
      const promoData = promos?.data?.data;
      let Promos = [];
      promoData.forEach((promo, index) => {
        Promos.push({
          code: promo?.promoCode,
          description: promo?.en?.description,
          _id: promo?._id,
          status: promo?.promoStatus
        });
      });
      setPromos(Promos.filter((p) => p.status === 'Active'));

    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error.response.data.message);
      else toast.error("Failed to load Trip Details");
      console.log(error);
    }
  }

  const handleImageChange = async (e) => {
    const fileList = e.target.files;
    setImages([...fileList]);
  };

  const handleChange = (e) => {
    if (e.target.value === "True") setForm({ ...form, [e.target.name]: true });
    else if (e.target.value === "False")
      setForm({ ...form, [e.target.name]: false });
    else setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (loading)
      return;

    if (form.enTitle === "" || form.enDesc === "" || form.areTitle === "" || form.areDesc === "") {
      toast.warning("All fields are required")
      return;
    }

    try {
      setLoading(true);
      const tripData = new FormData();
      tripData.append("en.title", form.enTitle);
      tripData.append("en.description", form.enDesc);
      tripData.append("are.title", form.areTitle);
      tripData.append("are.description", form.areDesc);
      tripData.append("en.descriptionHome", form.enDescHome);
      tripData.append("en.descriptionHomeEvent", form.enDescHomeEvent);
      tripData.append("are.descriptionHome", form.areDescHome);
      tripData.append("are.descriptionHomeEvent", form.areDescHomeEvent);
      tripData.append("type", form.type);
      tripData.append("en.location", form.enLocation);
      tripData.append("are.location", form.areLocation);
      tripData.append("status", form.status);
      tripData.append("note", form.note);
      let slotsArray = [];
      for (const [key, value] of slots) {
        slotsArray.push({ date: key, time: value });
      }

      slotsArray = JSON.stringify(slotsArray);
      tripData.append(`slots`, slotsArray);

      const boats = JSON.stringify(selectedBoats);
      tripData.append(`boatYacht[]`, boats);

      selectedAddons.forEach((addon) => {
        tripData.append(`addons[]`, addon._id);
      });

      if (selectedPromos.length > 0) {
        selectedPromos.forEach((promo) => {
          tripData.append(`promos[]`, promo._id);
        });
      }
      images.forEach((img, index) => {
        if (typeof img === "string") tripData.append("image[]", img);
        else tripData.append(`image${index}`, img);
      });

      const { data } = await Trip.updateTrip(id, tripData);
      if (data.status) {
        setLoading(false);
        toast.success("Trip Updated successfully");
        navigate("/trip-list");
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message)
        toast.error(error.response.data.message);
      else toast.error("Something went wrong");
      console.log(error.message);
    }
  };

  const filterPastDates = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set current time to 00:00:00
    return date >= today;
  };

  const removeImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const handleConvert = (time) => {
    const currentDate = new Date();
    const [tim, meridian] = time.split(" ");
    let [hours, minutes] = time.split(":");

    hours = parseInt(hours);
    minutes = parseInt(minutes);

    if (meridian === "PM" && hours !== 12) {
      hours += 12;
    }

    if (meridian === "AM" && hours === 12) {
      hours = 0;
    }

    const convertedDate = new Date(currentDate);
    convertedDate.setHours(hours);
    convertedDate.setMinutes(minutes);

    return convertedDate;
  };

  const selectAll = () => {
    if (selectedTimes.length === timeSlots.length) {
      setSelectedTimes([]);
    } else {
      setSelectedTimes(timeSlots);
    }
  }

  useEffect(() => {
    if (id) {
      slots.clear();
      fetchData(id);
    }
  }, [id]);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={form.enTitle}>
              <Button
                className={`material-icons icons p-2`}
                style={{
                  marginLeft: "15px",
                  borderRadius: "100%",
                  color: "white",
                  backgroundColor: "#5e5d72",
                }}
                onClick={() => navigate(`/trip-list`)}
              >
                cancel
              </Button>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={7}>
          <CardLayout>
            <Row>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="english title"
                  name="enTitle"
                  onChange={handleChange}
                  value={form.enTitle}
                  fieldSize="w-100 h-md"
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="arabic title"
                  name="areTitle"
                  onChange={handleChange}
                  value={form.areTitle}
                  fieldSize="w-100 h-md"
                />
              </Col>
              <Col xl={6}>
                <LabelTextarea
                  label="english description"
                  name="enDesc"
                  onChange={handleChange}
                  value={form.enDesc}
                  fieldSize="w-100 h-text-md"
                />
              </Col>
              <Col xl={6}>
                <LabelTextarea
                  label="arabic description"
                  name="areDesc"
                  onChange={handleChange}
                  value={form.areDesc}
                  fieldSize="w-100 h-text-md"
                />
              </Col>
              <Col xl={6}>
                <LabelTextarea
                  label="english description for banner"
                  name="enDescHome"
                  onChange={handleChange}
                  value={form.enDescHome}
                  fieldSize="w-100 h-text-md"
                />
              </Col>
              <Col xl={6}>
                <LabelTextarea
                  label="arabic description for banner"
                  name="areDescHome"
                  onChange={handleChange}
                  value={form.areDescHome}
                  fieldSize="w-100 h-text-md"
                />
              </Col>
              {
                form.type === 'Group'
                &&
                <>
                  <Col xl={6}>
                    <LabelTextarea
                      required
                      label="english banner (for event section)*"
                      name="enDescHomeEvent"
                      onChange={handleChange}
                      value={form.enDescHomeEvent}
                      fieldSize="w-100 h-text-md"
                    />
                  </Col>
                  <Col xl={6}>
                    <LabelTextarea
                      required
                      label="arabic banner (for event section)*"
                      name="areDescHomeEvent"
                      onChange={handleChange}
                      value={form.areDescHomeEvent}
                      fieldSize="w-100 h-text-md"
                    />
                  </Col>
                </>
              }
              {/* <Col xl={6}>
                <LabelField
                  label="category"
                  type="text"
                  name="category"
                  onChange={handleChange}
                  value={form.category}
                  fieldSize="w-100 h-md"
                />
              </Col> */}
              <Col xl={form.type === 'Group' ? 12 : 6}>
                <LabelField
                  label="type"
                  name="type"
                  onChange={handleChange}
                  value={form.type}
                  option={["Group", "Individual"]}
                  fieldSize="w-100 h-md"
                />
              </Col>
              {
                form?.type === 'Group'
                &&
                <>
                  <Col xl={6}>
                    <LabelField
                      type="text"
                      label="en location"
                      name="enLocation"
                      onChange={handleChange}
                      value={form.enLocation}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                  <Col xl={6}>
                    <LabelField
                      type="text"
                      label="arabic location"
                      name="areLocation"
                      onChange={handleChange}
                      value={form.areLocation}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                </>
              }
              <Col xl={form.type === 'Group' ? 12 : 6}>
                <LabelField
                  label="status"
                  option={["Active", "Inactive"]}
                  name="status"
                  onChange={handleChange}
                  value={form.status}
                  fieldSize="w-100 h-md"
                />
              </Col>
              <Col xl={12} className="flex-nowrap">
                <Label className="mc-label-field-title mb-2">Promos</Label>
                <Multiselect
                  options={promos}
                  showCheckbox={true}
                  selectedValues={initialPromos}
                  onSelect={(e) => {
                    setSelectedPromos(e);
                  }}
                  onRemove={(e) => {
                    setSelectedPromos(e);
                  }}
                  getOptionValue={(option) => option._id}
                  displayValue={`code`}
                />
              </Col>
              <Col xl={12}>
                <Label className="mc-label-field-title">boats</Label>
                <div className="container mx-auto py-4">
                  <div className="bg-white rounded-lg shadow-md p-4">
                    <div className="flex gap-4 mb-4">
                      <div>
                        <select
                          className="w-full mt-1 border-gray-300 border rounded-md py-2 px-1"
                          value={boatId}
                          onChange={(e) => setBoatId(e.target.value)}
                        >
                          <option value="">Select boat</option>
                          {boatOptions.map((boat) => (
                            <option key={boat._id} value={boat._id}>
                              {boat.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="flex-grow">
                        <input
                          className="w-full mt-1 border-gray-300 border rounded-md py-2 px-1"
                          type="number"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          placeholder="Enter Price"
                        />
                      </div>
                    </div>
                    <button
                      className="block w-full px-4 py-2 text-sm font-medium text-white btn btn-primary rounded-md "
                      onClick={handleAddBoat}
                    >
                      Add Boat
                    </button>
                    {selectedBoats.map((boat) => {
                      const thisBoat = boats.filter((b) => b._id === boat.boatId);
                      const thisBoatName = thisBoat?.[0]?.title;
                      return (
                        <div key={boat.boatId} className="mt-4 p-4 bg-gray-100 rounded-lg">
                          <p className="text-lg font-semibold">{thisBoatName}</p>
                          <p className="text-gray-700">Price: {boat.price}</p>
                          <button
                            className="mt-2 text-sm text-red-600 hover:text-red-800 cursor-pointer"
                            onClick={() => handleRemoveBoat(boat.boatId)}
                          >
                            Remove
                          </button>
                          <button
                            className="mt-2 text-sm text-green-600 hover:text-red-800 cursor-pointer ml-5"
                            onClick={() => handleEditBoat(boat.boatId, boat.price)}
                          >
                            Edit
                          </button>
                        </div>
                      )
                    }
                    )}
                  </div>
                </div>
              </Col>
              <Col xl={12}>
                <Label className="mc-label-field-title mb-2">Addons</Label>
                <Multiselect
                  options={addons}
                  showCheckbox={true}
                  selectedValues={initialAddons}
                  onSelect={(e) => {
                    setSelectedAddons(e);
                  }}
                  onRemove={(e) => {
                    setSelectedAddons(e);
                  }}
                  getOptionValue={(option) => option._id}
                  displayValue={`title`}
                />
              </Col>
              {form.type !== "Group" && (
                <Col xl={12}>
                  <div className="">
                    <div>
                      <form
                        style={{
                          alignItems: "center",
                          gap: "15px",
                        }}
                        onSubmit={submitIt}
                      >
                        <div className="flex flex-wrap gap-4">
                          <div className="">
                            <DatePicker
                              highlightDates={selectedDates.filter((i) => i >= new Date())}
                              placeholderText="Select a date &#128197;"
                              dateFormat="dd/MM/yyyy"
                              onChange={handleDateChange}
                              selected={currentDate}
                              customInput={
                                <LabelField
                                  type="text"
                                  label="Select Date"
                                  name="date"
                                  fieldSize="w-100 h-md"
                                />
                              }
                              minDate={new Date()}
                              filterDate={filterPastDates}

                            />
                          </div>
                          <div>
                            {currentDate && (
                              <>
                                <Label className="mc-label-field-title">
                                  Select Time
                                </Label>
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                                    gap: "5px",
                                    justifyContent: "space-evenly",
                                    width: "100%",
                                  }}
                                  className="time-intervals"
                                >
                                  {timeSlots.map((time) => (
                                    <div
                                      key={time}
                                      className="flex items-center mb-2"
                                    >
                                      <label className="flex items-center space-x-2">
                                        <input
                                          style={{ marginRight: "5px" }}
                                          type="checkbox"
                                          value={time}
                                          checked={selectedTimes.some(
                                            (t) => t === time
                                          )}
                                          onChange={(e) =>
                                            handleTimeChange(e.target.value)
                                          }
                                          className="form-checkbox h-5 w-5 text-blue-500"
                                        />
                                        <span className="text-lg whitespace-nowrap">{time}</span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  <h1 onClick={selectAll} className={`${selectedTimes.length === timeSlots.length ? 'bg-red-500' : 'bg-blue-500'} text-base px-4 py-2 text-white font-normal rounded-lg w-max cursor-pointer ml-auto`}>{selectedTimes.length === timeSlots.length ? 'Remove all' : 'Select all'}</h1>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "10px",
                          }}
                        >
                          {currentDate &&
                            slots.has(convertDateToString(currentDate)) && (
                              <button
                                type="btn"
                                className="btn btn-danger"
                                onClick={() => {
                                  removeDate(currentDate);
                                }}
                              >
                                Remove Date
                              </button>
                            )}
                          {currentDate &&
                            slots.has(convertDateToString(currentDate)) && (
                              <button type="btn" className="btn btn-primary">
                                Update Date
                              </button>
                            )}
                          {currentDate &&
                            !slots.has(convertDateToString(currentDate)) && (
                              <button
                                type="btn"
                                disabled={
                                  !currentDate || selectedTimes.length === 0
                                }
                                className="btn btn-success"
                              >
                                Add Date
                              </button>
                            )}
                        </div>
                      </form>
                    </div>
                  </div>
                </Col>
              )}
              <Col xl={12}>
                <LabelTextarea
                  label="Note"
                  name="note"
                  onChange={handleChange}
                  value={form.note}
                  fieldSize="w-100 h-text-md"
                />
              </Col>
            </Row>
          </CardLayout>
        </Col>
        <Col xl={5}>
          <CardLayout>
            <Box className="mc-trip-upload-media">
              {images.length === 0 && (
                <Box className="mc-trip-upload-file">
                  <Input
                    type="file"
                    id="image"
                    name="image"
                    onChange={handleImageChange}
                  />
                  <Label htmlFor="image">
                    <Icon type="collections" />
                    <Text>upload image</Text>
                  </Label>
                </Box>
              )}
              {images.map((img, index) => (
                <Box key={img?.name} className="mc-trip-upload-image">
                  {typeof img === "string" ? (
                    <Image
                      src={img}
                      alt="trip image"
                      style={{ maxHeight: "250px" }}
                    />
                  ) : (
                    <Image
                      src={URL?.createObjectURL?.(img)}
                      alt="trip image"
                      style={{ maxHeight: "250px" }}
                    />
                  )}
                  <Button
                    style={{ color: "red" }}
                    onClick={() => {
                      removeImage(index);
                    }}
                    className="material-icons delete position-absolute top-0 end-0 m-2 image-delete-button"
                  >
                    cancel
                  </Button>
                </Box>
              ))}
            </Box>
            <Anchor
              className="flex items-center justify-center gap-1 btn w-100 btn-primary mt-2 "
              text={loading ? "Updating..." : "Update Trip"}
              icon="cloud_upload"
              href="#"
              onClick={() => handleSubmit()}
            />
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}

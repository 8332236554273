import React, { useState } from "react";
import { ThemeProvider } from "./context/Themes";
import { LoaderProvider } from "./context/Preloader";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Error } from "./pages/supports";
import { Dashboard, ForgotPassword, Login, UserList, UserProfile, MyAccount,
    TripList, TripView, TripUpload, InvoiceList, InvoiceDetails, OrderList, CreateAddons, Settings
} from "./pages/master"
import "./css/bootstrap.min.css"
import "./css/icons.css"
import "./css/bullets.css"
import "./css/fonts/text.css"
import "./css/fonts/icofont/icofont.css"
import "./css/styles.min.css"
import './css/styles.css'
import AddBoat from "./pages/master/AddBoat";
import BoatList from "./pages/master/BoatList";
import AddOnList from "./pages/master/AddonList";
import BoatView from "./pages/master/BoatView";
import EditBoat from "./pages/master/EditBoat";
import Faqs from "./pages/master/AddFaq";
import FaqList from "./pages/master/FaqList";
import EditTrip from "./pages/master/EditTrip";
import EditAddon from "./pages/master/EditAddon";
import Gallery from "./pages/master/CreateGallery";
import EditGallery from "./pages/master/EditGallery";
import EditFaq from "./pages/master/EditFaq";
import AddonView from "./pages/master/AddonView";
import FaqView from "./pages/master/FaqView";
import CreateBlog from "./pages/master/CreateBlog";
import EditBlog from "./pages/master/EditBlog";
import BlogsList from "./pages/master/BlogsList";
import BlogView from "./pages/master/BlogView";
import CreatePromo from "./pages/master/CreatePromo";
import PromoList from "./pages/master/PromoList";
import EditPromo from "./pages/master/EditPromo";
import PromoView from "./pages/master/PromoView";
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomerQuestionsList from "./pages/master/CustomerQuestionsList";
import AboutUs from "./pages/master/AboutUs";
import BookingList from "./pages/master/BookingList";
import ReviewList from "./pages/master/ReviewList";
import Terms from "./pages/master/Terms";
import BookingView from "./pages/master/BookingView";
import * as userAPI from './api/users'
import * as API from "./api/messages";
import { toast } from "react-toastify";
import { getAllBookings } from "./api/bookings";
export default function App() {

    const [userData, setUserData] = useState([]);
    const [messageData, setMessageData] = useState([]);
    const [booking, setBooking] = useState([])
    
    const fetchUserData = async () => {
        try {
            const { data } = await userAPI.getAllUsers();
            const users = data.data.filter(user => user.role !== 'admin');
            setUserData(users)
        } catch (error) {
            if (error?.response?.data?.message) toast.error(error.response.data.message)
            else toast.error('Failed to fetch Users details')
            console.log(error.message);
        }
    }

    async function fetchMessages() {
        try {
          const res = await API.getAll();
          let data = res.data.data;
          data.reverse();
          setMessageData(data);
        } catch (error) {
          if (error?.response?.data?.message)
            toast.error(error.response.data.message);
          else toast.error("Failed to fetch messages");
          console.log(error.message);
        }
      }

    const fetchBookings = async () => {
        try {
            const { data } = await getAllBookings()
            if (data.status) {
                const bookings = data?.data
                setBooking(bookings)
            }
        } catch (error) {
            if (error?.response?.data?.message) toast.error(error.response.data.message)
            else toast.error('Failed to fetch Booking details')
            console.log(error.message);
        }
    }

    const fetchData = async () => {
        fetchUserData();
        fetchBookings();
        fetchMessages();
    }


    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });


    return (
        <ThemeProvider>
            <LoaderProvider>
                <BrowserRouter>
                    <ToastContainer
                        position='top-center'
                        pauseOnHover={false}
                        autoClose={1500}
                        transition={Bounce}
                        hideProgressBar={true}
                        closeOnClick={true}
                        limit={1}
                        theme='colored'
                        icon={true}
                        closeButton={false}
                    />
                    <Routes>
                        {/* master Pages */}
                        <Route path="/" element={<Dashboard userData={userData} messageData={messageData} bookings={booking} fetchData={fetchData} />} />
                        <Route path="/login" element={<Login />} />
                        {/* <Route path="/register" element={<Register />} /> */}
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/user-list" element={<UserList userData={userData} fetchUserData={fetchUserData} />} />
                        <Route path="/user-profile" element={<UserProfile />} />
                        <Route path="/my-account" element={<MyAccount />} />

                        <Route path="/bookings" element={<BookingList bookingData={booking} fetchBookings={fetchBookings} />} />
                        <Route path="/bookings-view/:id" element={<BookingView />} />

                        <Route path="/trip-list" element={<TripList />} />
                        <Route path="/trip-view/:id" element={<TripView />} />
                        <Route path="/trip-upload" element={<TripUpload />} />
                        <Route path="/edit-trip/:id" element={<EditTrip />} />

                        <Route path="/boat-list" element={<BoatList />} />
                        <Route path="/add-boat" element={<AddBoat />} />
                        <Route path="/boat-view/:id" element={<BoatView />} />
                        <Route path="/edit-boat/:id" element={<EditBoat />} />
                        <Route path="/reviews" element={<ReviewList />} />

                        <Route path="/invoice-list" element={<InvoiceList />} />
                        <Route path="/invoice-details" element={<InvoiceDetails />} />
                        <Route path="/order-list" element={<OrderList />} />
                        <Route path="/settings" element={<Settings />} />

                        <Route path="/addon-list" element={<AddOnList />} />
                        <Route path="/addons" element={<CreateAddons />} />
                        <Route path="/addon-view/:id" element={<AddonView />} />
                        <Route path="/edit-addon/:id" element={<EditAddon />} />

                        <Route path="/faqs" element={<Faqs />} />
                        <Route path="/faqs-list" element={<FaqList />} />
                        <Route path="/faq-view/:id" element={<FaqView />} />
                        <Route path="/edit-faq/:id" element={<EditFaq />} />

                        <Route path='/gallery' element={<Gallery />} />
                        <Route path='/edit-gallery' element={<EditGallery />} />

                        <Route path='/blogs' element={<CreateBlog />} />
                        <Route path='/blogs-list' element={<BlogsList />} />
                        <Route path='/edit-blog/:id' element={<EditBlog />} />
                        <Route path='/blog-view/:id' element={<BlogView />} />

                        <Route path='/create-promo' element={<CreatePromo />} />
                        <Route path='/promos-list' element={<PromoList />} />
                        <Route path='/edit-promo/:id' element={<EditPromo />} />
                        <Route path='/promo-view/:id' element={<PromoView />} />

                        <Route path='/customer-questions' element={<CustomerQuestionsList messages={messageData} />} />

                        <Route path='/terms' element={<Terms />} />
                        <Route path='/about-us' element={<AboutUs />}


                        />


                        {/* Blocks Pages */}

                        {/* Supports Pages */}
                        <Route path="*" element={<Error />} />
                    </Routes>
                </BrowserRouter>
            </LoaderProvider>
        </ThemeProvider>
    );
}


import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Heading, Box, Text, Icon, Button } from "../elements";
import * as API from '../../api/users'
import { toast } from "react-toastify";
import EmptyList from "../EmptyList";

export default function ReviewTables({ thead, tbody, fetchReviews }) {
    const [editModal, setEditModal] = useState(false);
    const [blockModal, setBlockModal] = useState({ active: false, uname: "", mail: "", review: "", rating: "" ,item:''});
    const [alertModal, setAlertModal] = useState({ active: false, id: '', index: '',item :'' });
    const [data, setData] = useState([]);


    const getDate = (date) => {
        let newDate = date.split('T')[0]
        return newDate
    }
    const handletoogle = async (id) => {
        const { data } = await API.toogleReviewStatus(id);
        if (data.status) {
            fetchReviews();
            if (data?.data?.status === "Inactive") {
                toast.error('Review Disabled')
            } else {
                toast.success('Review Enabled')
            }
        }
    }
    let newData = data;

    const deleteTrip = async (id, index) => {
        const res = await API.deleteReview(id);
        if (res.data.status === true) {
            const updatedData = [...newData]
            updatedData.splice(index, 1)
            setData(updatedData)
            toast.success("Review Deleted")
        }
    }

    useEffect(() => {
        setData(tbody);
    }, [tbody]);
    return (
        <Box className="mc-table-responsive">
            {data.length === 0 && <EmptyList title={'Reviews'} />}
            {data.length !== 0 &&
                <Table className="mc-table trip">
                    <Thead className="mc-table-head primary">
                        <Tr>
                            <Th>
                                <Box className="mc-table-check">
                                    <Text className="" >S.no</Text>
                                </Box>
                            </Th>
                            {thead.map((item, index) => (
                                <Th key={index}>{item}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody className="mc-table-body even">
                        {data?.map((item, index) => (
                            <Tr style={{ height: '50px' }} key={index}>
                                <Td title={index + 1}>
                                    <Box className="mc-table-check">
                                        <Text>#{index + 1}</Text>
                                    </Box>
                                </Td>
                                <Td>
                                    <Box className="mc-table-trip md">
                                        <Box className="mc-table-group">
                                            <Heading as="h6">{item?.userName}</Heading>
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Box className="mc-table-trip md">
                                        <Box className="mc-table-group">
                                            <Text>{item?.email}</Text>
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Box className="mc-table-trip md">
                                        <Box style={{}} className="mc-table-group overflow-y-auto">
                                            <Text>{item?.review}</Text>
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Box className="mc-table-trip md">
                                        <Box className="mc-table-group">
                                            <Text>{item?.rating}</Text>
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Box className="mc-table-trip md">
                                        <Box className="mc-table-group">
                                            <Text>{item?.status}</Text>
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Box className="mc-table-trip md">
                                        <Box className="mc-table-group">
                                            <Text>{item?.createdAt ? getDate(item.createdAt) : ''}</Text>
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Box className="mc-table-action">
                                        <button title="View" className="material-icons view " onClick={() =>
                                            setBlockModal({
                                                active: true,
                                                uname: item?.userName,
                                                mail: item?.email,
                                                review: item?.review,
                                                rating: item?.rating

                                            })
                                        }> visibility </button>
                                        <button type="btn" className={`material-icons ${item.status === 'Active' ? 'bg-danger' : 'bg-primary'} rounded `} value={data?.status} onClick={() => handletoogle(item._id)}>{item.status === 'Active' ? 'hide_source' : 'check_circle'}</button>
                                        {item.status=== "Inactive" && <Button key={item?._id} title="Delete" className="material-icons delete" onClick={(e) => { setAlertModal({ active: true, id: item?._id, index: index}) }}>delete</Button>
}
                                    </Box>
                                    {/* <Button onClick={() => { API.deleteReview(item?._id); fetchReviews() }} className='btn btn-danger'>
                                        delete
                                    </Button> */}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            }
            <Modal
                show={editModal}
                onHide={() => setEditModal(false)}
            >

            </Modal>

            <Modal
                show={blockModal.active}
                onHide={() => setBlockModal({
                    active: false,
                    uname: "",
                    mail: "",
                    review: "",
                    rating: ""
                })}
            >
                <Box className="mc-alert-modal text-left ">
                    <Icon type="reviews" />
                    <Heading as="h3"><span>
                        {blockModal?.uname}
                    </span></Heading>
                    <br />
                    <Heading as="h4"><span>
                        {blockModal?.mail}
                    </span></Heading>
                    <br />
                    <Heading as="h4"><span>
                        Rating:{blockModal?.rating}
                    </span></Heading><br />
                    <Heading as="h4">Review<span>
                    </span></Heading>
                    <h6>
                        {blockModal?.review}
                    </h6>

                    <Modal.Footer>
                        <Button
                            type="btn"
                            className={"btn btn-secondary px-6 w-full"}
                            onClick={() => setBlockModal({ active: false })}
                        >
                            Ok
                        </Button>
                    </Modal.Footer>
                </Box>
            </Modal>
            <Modal show={alertModal.active} onHide={() => setAlertModal({ active: false, id: '', index: '' , item :''})}>
                <Box className="mc-alert-modal">
                    <Icon type="new_releases" />
                    <Heading as="h3">are your sure!</Heading>
                    <Text as="p">Want to delete this Review?</Text>
                    <Modal.Footer>
                        <Button type="btn" className="btn btn-secondary" onClick={() => setAlertModal({ active: false, id: '', index: '' })}>close</Button>
                        <Button type="btn" className="btn btn-danger" onClick={() => { deleteTrip(alertModal.id, alertModal.index);; setAlertModal({ active: false, id: '', index: '' }) }}>Yes</Button>
                    </Modal.Footer>
                </Box>
            </Modal>
        </Box>
    );
}
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "../../components/cards";
import BoatsTable from "../../components/tables/BoatsTable";
import { Breadcrumb } from "../../components";
import Anchor from "../../components/elements/Anchor";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/boatList.json";
import * as API from '../../api/boats'
import { toast } from "react-toastify";

export default function BoatList() {
    const [boatData, setBoatData] = useState([])
    useEffect(() => {
        async function fetchData() {
            try {
                const res = await API.getBoats();
                setBoatData(res.data.data)
            } catch (error) {
                if (error.response.data.message) toast.error(error.response.data.message)
                else toast.error('Failed to fetch Boat/Yacht list')
                console.log(error.message);
            }
        }
        fetchData();
    }, [])

    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title="Boats/Yachts List">
                            {data?.breadcrumb.map((item, index) => (
                                <li key={index} className="mc-breadcrumb-item">
                                    {item.path ? <Anchor className="mc-breadcrumb-link" href={item.path}>{item.text}</Anchor> : item.text}
                                </li>
                            ))}
                        </Breadcrumb>
                    </CardLayout>
                </Col>
                <Col xl={12}>
                    <CardLayout>
                        <Row>
                            <Col xl={12}>
                                <BoatsTable
                                    thead={data?.trip.thead}
                                    tbody={boatData}
                                />
                            </Col>
                        </Row>
                    </CardLayout>
                </Col>
            </Row>
        </PageLayout>
    );
}

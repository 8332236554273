import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Anchor, Box, Text, Icon, Image, Button, Input, Label } from "../../components/elements";
import { Breadcrumb, DivideTitle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import CardLayout from "../../components/cards/CardLayout";
import { useNavigate } from "react-router-dom";

import * as API from '../../api/gallery'
import { toast } from "react-toastify";
export default function EditGallery() {
    const [images, setImages] = useState([]);
   
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const res = await API.getAllImages();
            setImages(res.data.data.gallery.image)
        }
        fetchData();
    }, [])

    const [submitting, setSubmitting] = useState('Publish Changes');

    const removeImage = (index) => {
        let imageArray = [...images];
        imageArray.splice(index, 1)
        setImages(imageArray)
    }

    const handleImageChange = async (e) => {
        const fileList = e.target.files;
        setImages([...images, ...fileList]);
    };

    const handleSubmit = async () => {
        try {
            setSubmitting('Uploading ...')
            const galleryData = new FormData();
            images.forEach((img, index) => {
                if (typeof img === 'string')
                    galleryData.append('image[]', img)
                else
                    galleryData.append(`image${index}`, img)
            })

            const res = await API.updateGallery(galleryData);
            setSubmitting('Publish Changes')
            if (res && res.data.status)

                toast.success('Gallery updated successfully')
                navigate("/gallery")
        } catch (error) {
            setSubmitting('Publish Changes')
            if (error.response.data.message) toast.error(error.response.data.message)
            else
                toast.error('Failed to update gallery')
            console.log(error.message);
        }
    }

    return (
        <PageLayout>
            <CardLayout className="mb-4">
                <Breadcrumb title='Edit Gallery'>
                    <Button
                        className={`material-icons icons p-2`}
                        style={{
                            marginLeft: "15px",
                            borderRadius: "100%",
                            color: "white",
                            backgroundColor: "#5e5d72",
                        }}
                        onClick={() => navigate(`/gallery`)}
                    >
                        cancel
                    </Button>
                </Breadcrumb>
            </CardLayout>
            <CardLayout className="p-lg-5">
                <Row>
                    <Col xl={12}>
                        <CardLayout>
                            <DivideTitle title="gallery" className="mb-4" />
                            <Box className='mc-trip-upload-media'     >
                                <Box className="mc-trip-upload-file">
                                    <Input type="file" accept="image/*"  id='image' name='image' multiple onChange={handleImageChange} />
                                    <Label htmlFor="image"><Icon type="collections" /><Text>{'uploadFile'}</Text></Label>
                                </Box>
                                {
                                    images.map((img, index) => (
                                        <Box key={img.name} className="mc-trip-upload-image">
                                            {typeof img === 'string' ? (
                                                <Image src={img} alt="boat image" style={{ maxHeight: '250px' }} />
                                            ) : (
                                                <Image src={URL?.createObjectURL?.(img)} alt="boat image" style={{ maxHeight: '250px' }} />
                                            )}
                                            <Button style={{ color: 'red' }} onClick={() => { removeImage(index) }} className="material-icons delete position-absolute top-0 end-0 m-2 image-delete-button">cancel</Button>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </CardLayout>
                        <Anchor
                            className="btn w-100 btn-primary mt-5"
                            text={submitting}
                            icon={submitting === 'Edit Gallery' ? "cloud_upload" : undefined}
                            href="#"
                            onClick={handleSubmit}
                        />
                    </Col>

                </Row>
            </CardLayout>
        </PageLayout>
    )
}
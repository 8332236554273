import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Anchor, Button } from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout, CardHeader } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import * as API from '../../api/addons'
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


export default function CreateAddons() {
  
  const [form,setForm] = useState({ enTitle: '', enDesc: 'desc', areTitle: '', areDesc: 'desc', price: '',quantity:"", status: 'Active' })
  const [loading, setLoading] = useState(false);
const navigate = useNavigate();

  const handleSubmit = async() => {
    try {
      if (
        form.enTitle.trim() === '' ||
        form.enDesc.trim() === '' ||
        form.areTitle.trim() === '' ||
        form.areDesc.trim() === '' ||
        form.price === '' ||
        form.quantity === ''
      ) {
        toast.error('Please fill in all required fields.');
        return;
      }
      setLoading(true)
      const res = await API.createAddon({
        en: {
          title: form.enTitle,
          description: form.enDesc
        },
        are: {
          title: form.areTitle,
          description: form.areDesc
        },
        price: form.price,
        quantity: form.quantity,
        status: form.status
      })
      if(res&&res.data.status){
        setLoading(false)
        toast.success('New addon created');
        navigate("/addon-list")
      }

      
    } catch (error) {
      setLoading(false)
      if(error.response.data.message) toast.error(error.response.data.message)
      else  toast.error('Something went wrong')
      console.log(error.message);
    }
  }

  const handleChange = (e) => {
    setForm({...form,[e.target.name]: e.target.value});
  }

  const [data, setData] = useState([]);


  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title='create addon'>
              {data?.breadcrumb?.map((item, index) => (
                <li key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </li>
              ))}
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={6}>
          <CardLayout>
            <CardHeader title="english details" dotsMenu={data?.dotsMenu} />
            <Row>
              <Col xl={12}>
                <LabelField
                  type="text"
                  label="Addon title"
                  fieldSize="w-100 h-md"
                  value = {form.enTitle}
                  onChange = {handleChange}
                  name = 'enTitle'
                />
              </Col>
              {/* <Col xl={12}><LabelTextarea label="description" name ='enDesc' value = { form.enDesc} onChange = {handleChange}fieldSize="w-100 h-text-md" /></Col> */}
            </Row>
          </CardLayout>
        </Col>

        <Col xl={6}>
          <CardLayout>
            <CardHeader title="Arabic details" dotsMenu={data?.dotsMenu} />
            <Row>
              <Col xl={12}>
                <LabelField
                  type="text"
                  label="addon title"
                  fieldSize="w-100 h-md"
                  name = 'areTitle'
                  value = {form.areTitle}
                  onChange = {handleChange}
                />
              </Col>
              {/* <Col xl={12}><LabelTextarea label="description"  name ='areDesc' value ={form.areDesc} onChange = {handleChange} fieldSize="w-100 h-text-md" /></Col> */}
            </Row>
          </CardLayout>
        </Col>
        <Col xl ={12}>
        <CardLayout>
        <Col xl={12}><LabelField className={'mb-2'} type="number" label="price" value = {form.price} onChange ={handleChange} name = 'price' fieldSize="w-100 h-md" /> </Col>
        <Col xl={12}><LabelField className={'mb-2'} type="number" label="max quantity" value = {form.quantity} onChange ={handleChange} name = 'quantity' fieldSize="w-100 h-md" /> </Col>
        <Col xl={12}><LabelField label="status" name= 'status' value ={form.status} onChange={handleChange} option={['Active','Inactive']} fieldSize="w-100 h-md" /> </Col>
        </CardLayout>
        </Col>
        <Col xl = {12}>
          
            <Button type = "btn" className={'btn btn-primary'} onClick={!loading && handleSubmit}>{loading ? 'Adding...' : 'Add Addon'}</Button>
     
        </Col>
      </Row>

      
    </PageLayout>
  );
}

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import {
 
  Button,
  
} from "../../components/elements";

import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout} from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import * as API from "../../api/faqs";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function FaqView() {
  const navigate = useNavigate();
  const id = useParams().id;
  const [form, setForm] = useState({
    enQuestion: "",
    enAnswer: "",
    areQuestion: "",
    areAnswer: "",
  });

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const res = await API.getFaqById(id);
        const data = res.data;
        setForm({
          enQuestion: data.en.question,
          enAnswer: data.en.answer,
          areQuestion: data.are.question,
          areAnswer: data.are.answer,
        });
      } catch (error) {
        if (error?.response?.data?.message) toast.error(error.response.data.message)
        else toast.error('Failed to get FAQ details')
      }
    };
    if (id) fetchData(id);
  }, [id]);
  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title="View faq">
              <Button
                className={`material-icons icons p-2`}
                style={{
                  marginLeft: "15px",
                  borderRadius: "100%",
                  color: "white",
                  backgroundColor: "#5e5d72",
                }}
              onClick={() => navigate(`/edit-faq/${id}`)}
              >
                edit
              </Button>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={6}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <LabelField
                  type="text"
                  label="question"
                  fieldSize="w-100 h-md"
                  value={form.enQuestion}
                  name="enQuestion"
                  disabled
                />
              </Col>
              <Col xl={12}>
                <LabelTextarea
                  label="answer"
                  name="enAnswer"
                  value={form.enAnswer}
                  fieldSize="w-100 h-text-md"
                  disabled
                />
              </Col>
            </Row>
          </CardLayout>
        </Col>

        <Col xl={6}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <LabelField
                  type="text"
                  label="question"
                  fieldSize="w-100 h-md"
                  name="areQuestion"
                  value={form.areQuestion}
                  disabled
                />
              </Col>
              <Col xl={12}>
                <LabelTextarea
                  label="answer"
                  name="areAnswer"
                  value={form.areAnswer}
                  fieldSize="w-100 h-text-md"
                  disabled
                />
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}

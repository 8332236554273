import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Box,
  Anchor,
  Button,
  Image,
  Input,
  Label,
  Icon,
  Text,
} from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/tripUpload.json";
import Multiselect from "multiselect-react-dropdown";
import * as API from "../../api/boats";
import * as AddOn from "../../api/addons";
import * as Trip from "../../api/trips";
import * as Promo from "../../api/promos";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const slots = new Map();

export default function TripUpload() {
  const [currentDate, setCurrentDate] = useState(null);

  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  const [boats, setBoats] = useState([]);
  const [selectedBoats, setSelectedBoats] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [addons, setAddOns] = useState([]);
  const [promos, setPromos] = useState([]);
  const [selectedPromos, setSelectedPromos] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [boatId, setBoatId] = useState('');
  const [price, setPrice] = useState('');

  const boatOptions = boats.filter(obj2 =>
    !selectedBoats.some(obj1 => obj1.boatId === obj2._id)
  );

  const handleAddBoat = () => {
    if (boatId && price) {
      const isBoatAdded = selectedBoats.some((item) => item.boatId === boatId);
      if (isBoatAdded) {
        alert("This boat is already added with a price.");
      } else {
        setSelectedBoats([...selectedBoats, { boatId, price }]);
        setBoatId('');
        setPrice('');
      }
    }
  };

  const handleRemoveBoat = (boatIdToRemove) => {
    const updatedBoatPrices = selectedBoats.filter((item) => item.boatId !== boatIdToRemove);
    setSelectedBoats(updatedBoatPrices);
  };

  const timeSlots = [
    "05:00 AM",
    "05:30 AM",
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
  ];

  const [form, setForm] = useState({
    enTitle: "",
    areTitle: "",
    enDesc: "",
    enDescHome: "",
    enDescHomeEvent: "",
    areDesc: "",
    areDescHome: "",
    areDescHomeEvent: "",
    category: "",
    type: "Individual",
    // price: "",
    // price2: "",
    enLocation: "",
    areLocation: "",
    // duration: 0,
    status: "Active",
    boatYacht: [],
    addons: [],
    note: "",
    // availability: "Saturday",
  });

  const selectAll = () => {
    if (selectedTimes.length === timeSlots.length) {
      setSelectedTimes([]);
    } else {
      setSelectedTimes(timeSlots);
    }
  }

  const convertDateToString = (date) => {
    if (date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    } else {
      return null;
    }
  };


  const handleDateChange = (date) => {
    setCurrentDate(date);
    let foundTimes = null;
    const convertedDate = convertDateToString(date);
    if (slots.has(convertedDate)) foundTimes = slots.get(convertedDate);
    if (foundTimes !== null) {
      setSelectedTimes(foundTimes);
    } else {
      setSelectedTimes([]);
    }
  };

  const handleTimeChange = (time) => {
    if (time !== "") {
      setSelectedTimes((prev) => {
        let newTimes = [...prev];
        if (newTimes.includes(time)) {
          newTimes = newTimes.filter((t) => t !== time);
        } else {
          newTimes.push(time);
        }
        return newTimes;
      });
    }
  };

  const submitIt = (e) => {
    e.preventDefault();

    const convertedDate = convertDateToString(currentDate);
    slots.set(convertedDate, selectedTimes);

    setSelectedDates([...selectedDates, currentDate]);
    setCurrentDate(null);
    setSelectedTimes([]);
  };

  const removeDate = (date) => {
    slots.delete(convertDateToString(date));
    let updatedDates = [...selectedDates];
    updatedDates.forEach((d, index) => {
      if (convertDateToString(d) === convertDateToString(date)) {
        updatedDates.splice(index, 1);
      }
    });
    setSelectedDates(updatedDates);
    setCurrentDate(null);
  };

  async function fetchData() {
    try {
      const res = await AddOn.getAddons();
      const addonData = res.data.data;
      let arr = [];
      addonData.forEach((addon, index) => {
        arr.push({
          title: `${index + 1}. ` + addon?.en?.title,
          _id: addon?._id,
        });
      });
      setAddOns(arr);

      const response = await API.getBoats();
      const boatData = response.data.data;
      let Boat = [];
      boatData.forEach((boat, index) => {
        if (boat.en && boat.en.title)
          Boat.push({
            title: boat?.en?.title,
            _id: boat?._id,
          });
      });
      setBoats(Boat);

      const promoResponse = await Promo.getAllPromos();
      const promoData = promoResponse.data.data;
      let Promos = [];
      promoData.forEach((promo, index) => {
        Promos.push({
          code: `${promo?.promoCode}`,
          description: promo?.en?.description,
          _id: promo?._id,
        });
      });
      setPromos(Promos);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error.response.data.message);
      else toast.error("Failed to load available boats, addons, promos");
      console.log(error.message);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const handleImageChange = async (e) => {
    const fileList = e.target.files;
    setImages([...fileList]);
  };

  const handleChange = (e) => {
    if (e.target.value === "True") setForm({ ...form, [e.target.name]: true });
    else if (e.target.value === "False")
      setForm({ ...form, [e.target.name]: false });
    else setForm({ ...form, [e.target.name]: e.target.value });
  };


  const handleSubmit = async () => {
    try {
      if (form.type === "Individual" && !selectedDates.length > 0) {
        toast.warning("Please select the Date")
        return;
      }
      setLoading(true);
      const tripData = new FormData();
      tripData.append("en.title", form.enTitle);
      tripData.append("en.description", form.enDesc);
      tripData.append("are.title", form.areTitle);
      tripData.append("are.description", form.areDesc);
      tripData.append("en.descriptionHome", form.enDescHome);
      tripData.append("en.descriptionHomeEvent", form.enDescHomeEvent);
      tripData.append("are.descriptionHome", form.areDescHome);
      tripData.append("are.descriptionHomeEvent", form.areDescHomeEvent);
      tripData.append("category", form.category);
      tripData.append("type", form.type);
      tripData.append("en.location", form.enLocation);
      tripData.append("are.location", form.areLocation);
      tripData.append("status", form.status);
      tripData.append("note", form.note);

      if (form.type === 'Individual') {
        let slotsArray = [];
        for (const [key, value] of slots) {
          slotsArray.push({ date: key, time: value });
        }
        slotsArray = JSON.stringify(slotsArray);
        tripData.append(`slots`, slotsArray);
      } else {
        tripData.append(`slots`, null);
      }
      const boats = JSON.stringify(selectedBoats);
      tripData.append(`boatYacht[]`, boats);

      selectedAddons.forEach((addon, index) => {
        tripData.append(`addons[]`, addon._id);
      });
      selectedPromos.forEach((promo, index) => {
        tripData.append(`promos[]`, promo._id);
      });
      images.forEach((img, index) => {
        tripData.append(`image${index}`, img);
      });
      const res = await Trip.createTrip(tripData);
      if (res && res.data.status) {
        setLoading(false);
        slots.clear();
        toast.success("Trip Uploaded successfully");
        navigate("/trip-list");
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message)
        toast.error(error.response.data.message);
      else toast.error("Something went wrong");
      console.log(error.message);
    }
  };

  const removeImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const [uploadFile, setUploadFile] = useState("image upload");


  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={data?.pageTitle}>
              {data?.breadcrumb.map((item, index) => (
                <li key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </li>
              ))}
            </Breadcrumb>
          </CardLayout>
        </Col>
        {/* <BoatForm /> */}
        <Col xl={7}>
          <CardLayout>
            <Row>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="english title"
                  name="enTitle"
                  onChange={handleChange}
                  value={form.enTitle}
                  fieldSize="w-100 h-md"
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="arabic title"
                  name="areTitle"
                  onChange={handleChange}
                  value={form.areTitle}
                  fieldSize="w-100 h-md"
                />
              </Col>
              <Col xl={6}>
                <LabelTextarea
                  label="english description"
                  name="enDesc"
                  onChange={handleChange}
                  value={form.enDesc}
                  fieldSize="w-100 h-text-md"
                />
              </Col>
              <Col xl={6}>
                <LabelTextarea
                  label="arabic description"
                  name="areDesc"
                  onChange={handleChange}
                  value={form.areDesc}
                  fieldSize="w-100 h-text-md"
                />
              </Col>
              <Col xl={6}>
                <LabelTextarea
                  label="english banner description"
                  name="enDescHome"
                  onChange={handleChange}
                  value={form.enDescHome}
                  fieldSize="w-100 h-text-md"
                />
              </Col>
              <Col xl={6}>
                <LabelTextarea
                  label="arabic banner description"
                  name="areDescHome"
                  onChange={handleChange}
                  value={form.areDescHome}
                  fieldSize="w-100 h-text-md"
                />
              </Col>
              <Col xl={12}>
                <LabelField
                  label="type"
                  name="type"
                  onChange={handleChange}
                  value={form.type}
                  option={["Group", "Individual"]}
                  fieldSize="w-100 h-md"
                />
              </Col>
              {
                form.type === 'Group'
                &&
                <>
                  <Col xl={6}>
                    <LabelTextarea
                      label="english banner for event section"
                      required
                      name="enDescHomeEvent"
                      onChange={handleChange}
                      value={form.enDescHomeEvent}
                      fieldSize="w-100 h-text-md"
                    />
                  </Col>
                  <Col xl={6}>
                    <LabelTextarea
                      required
                      label="arabic banner for event section"
                      name="areDescHomeEvent"
                      onChange={handleChange}
                      value={form.areDescHomeEvent}
                      fieldSize="w-100 h-text-md"
                    />
                  </Col>
                </>
              }

              {
                form.type === 'Group'
                &&
                <>
                  <Col xl={6}>
                    <LabelField
                      type="text"
                      label="En Location"
                      name="enLocation"
                      onChange={handleChange}
                      value={form.enLocation}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                  <Col xl={6}>
                    <LabelField
                      type="text"
                      label="Arabic Location"
                      name="areLocation"
                      onChange={handleChange}
                      value={form.areLocation}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                </>
              }
              <Col xl={12}>
                <LabelField
                  label="status"
                  option={["Active", "Inactive"]}
                  name="status"
                  onChange={handleChange}
                  value={form.status}
                  fieldSize="w-100 h-md"
                />
              </Col>
              <Col xl={12}>
                <Label className="mc-label-field-title mb-2">Addons</Label>
                <Multiselect
                  options={addons}
                  showCheckbox={true}
                  selectedValues={selectedAddons}
                  onSelect={(e) => {
                    setSelectedAddons(e);
                  }}
                  onRemove={(e) => {
                    setSelectedAddons(e);
                  }}
                  getOptionValue={(option) => option._id}
                  displayValue={`title`}
                />
              </Col>
              <Col xl={12}>
                <Label className="mc-label-field-title">boats</Label>
                <div className="container mx-auto py-4">
                  <div className="bg-white rounded-lg shadow-md p-4">
                    <div className="flex gap-4 mb-4">
                      <div className="">
                        <select
                          className="w-full mt-1 border-gray-300 border rounded-md py-2 px-1"
                          value={boatId}
                          onChange={(e) => setBoatId(e.target.value)}
                        >
                          <option value="">Select boat</option>
                          {boatOptions.map((boat) => (
                            <option key={boat._id} value={boat._id}>
                              {boat.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="flex-grow">
                        <input
                          className="w-full mt-1 border-gray-300 border rounded-md py-2 px-1"
                          type="number"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          placeholder="Enter Price"
                        />
                      </div>
                    </div>
                    <button
                      className="block w-full px-4 py-2 text-sm font-medium text-white btn btn-primary rounded-md "
                      onClick={handleAddBoat}
                    >
                      Add Boat
                    </button>
                    {selectedBoats.map((boat) => {
                      const thisBoat = boats.filter((b) => b._id === boat.boatId);
                      const thisBoatName = thisBoat?.[0]?.title;
                      return (
                        <div key={boat.boatId} className="mt-4 p-4 bg-gray-100 rounded-lg">
                          <p className="text-lg font-semibold">{thisBoatName}</p>
                          <p className="text-gray-700">Price: {boat.price}</p>
                          <button
                            className="mt-2 text-sm text-red-600 hover:text-red-800 cursor-pointer"
                            onClick={() => handleRemoveBoat(boat.boatId)}
                          >
                            Remove
                          </button>
                        </div>
                      )
                    }
                    )}
                  </div>
                </div>
              </Col>
              <Col xl={12} className="mt-0">
                <Label className="mc-label-field-title">Promos</Label>
                <Multiselect
                  options={promos}
                  showCheckbox={true}
                  selectedValues={selectedPromos}
                  onSelect={(e) => {
                    setSelectedPromos(e);
                  }}
                  onRemove={(e) => {
                    setSelectedPromos(e);
                  }}
                  getOptionValue={(option) => option._id}
                  displayValue={`code`}
                  className="mt-2"
                />
              </Col>
              <Col xl={12}>
                <div className="">
                  <div className="">
                    <form
                      style={{
                        alignItems: "center",
                        gap: "15px",
                      }}
                      onSubmit={submitIt}
                    >
                      {form.type !== "Group" && (
                        <div>
                          <Col xl={4}>
                            <div className="">
                              <DatePicker
                                highlightDates={selectedDates}
                                placeholderText="Select a date &#128197;"
                                dateFormat="dd/MM/yyyy"
                                onChange={handleDateChange}
                                selected={currentDate}
                                customInput={
                                  <LabelField
                                    type="text"
                                    label="Select Date"
                                    name="date"
                                    fieldSize="w-100 h-md"
                                  />
                                }
                                minDate={new Date()}
                              />
                            </div>
                          </Col>
                          <Col xl={12}>
                            {currentDate && form.type !== "Group" && (
                              <>
                                <Label className="mc-label-field-title mt-3">
                                  Select Time
                                </Label>
                                <div className="time-intervals flex flex-wrap gap-x-4 w-full">
                                  {timeSlots.map((time) => (
                                    <>
                                      <div
                                        key={time}
                                        className="flex items-center mb-2 whitespace-nowrap"
                                      >
                                        <label className="flex items-center">
                                          <input
                                            style={{ marginRight: "5px" }}
                                            className="form-checkbox h-5 w-5 text-blue-500"
                                            type="checkbox"
                                            value={time}
                                            checked={selectedTimes.some(
                                              (t) => t === time
                                            )}
                                            onChange={(e) =>
                                              handleTimeChange(e.target.value)
                                            }
                                          />
                                          <span className="text-lg">{time}</span>
                                        </label>
                                      </div>
                                    </>
                                  ))}
                                </div>
                                <div>
                                  <h1 onClick={selectAll} className={`${selectedTimes.length === timeSlots.length ? 'bg-red-500' : 'bg-blue-500'} text-base px-4 py-2 text-white font-normal rounded-lg w-max cursor-pointer ml-auto`}>{selectedTimes.length === timeSlots.length ? 'Remove all' : 'Select all'}</h1>
                                </div>
                              </>
                            )}
                          </Col>
                        </div>
                      )}
                      {form.type !== "Group" && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "10px",
                            gap: "10px",
                          }}
                        >
                          {currentDate &&
                            slots.has(convertDateToString(currentDate)) && (
                              <button
                                type="btn"
                                className="btn btn-danger"
                                onClick={() => {
                                  removeDate(currentDate);
                                }}
                              >
                                Remove Date
                              </button>
                            )}
                          {currentDate &&
                            slots.has(convertDateToString(currentDate)) && (
                              <button type="btn" className="btn btn-primary">
                                Update Date
                              </button>
                            )}
                          {currentDate &&
                            !slots.has(convertDateToString(currentDate)) && (
                              <button
                                type="btn"
                                disabled={
                                  form.type !== "Group"
                                    ? !currentDate || selectedTimes.length === 0
                                    : !currentDate
                                }
                                className="btn btn-success"
                              >
                                Add Date
                              </button>
                            )}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </Col>
              <Col xl={12}>
                <LabelTextarea
                  label="Note"
                  name="note"
                  onChange={handleChange}
                  value={form.note}
                  fieldSize="w-100 h-text-md"
                />
              </Col>
            </Row>
          </CardLayout>
        </Col>
        <Col xl={5}>
          <CardLayout>
            <Box className="mc-trip-upload-media">
              {images.length < 1 && (
                <Box className="mc-trip-upload-file">
                  <Input
                    type="file"
                    id="image"
                    name="image"
                    onChange={handleImageChange}
                  />
                  <Label htmlFor="image">
                    <Icon type="collections" />
                    <Text>{uploadFile}</Text>
                  </Label>
                </Box>
              )}
              {images.map((img, index) => (
                <Box key={img.name} className="mc-trip-upload-image">
                  <Image
                    src={URL?.createObjectURL?.(img)}
                    alt="boat image"
                    style={{ maxHeight: "250px" }}
                  />
                  <Button
                    style={{ color: "red" }}
                    onClick={() => {
                      removeImage(index);
                    }}
                    className="material-icons delete position-absolute top-0 end-0 m-2 image-delete-button"
                  >
                    cancel
                  </Button>
                </Box>
              ))}
            </Box>
            {!!images.length && (
              <div className="max-w-full">

              </div>
            )}
            <Anchor
              className="flex items-center justify-center gap-1 btn w-100 btn-primary mt-2"
              disabled={loading ? true : false}
              text={loading ? "Uploading trip..." : "Add Trip"}
              icon="cloud_upload"
              href="#"
              onClick={!loading && handleSubmit}
            />
          </CardLayout>
        </Col>
      </Row>
    </PageLayout >
  );
}

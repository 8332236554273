import React, { useEffect, useState } from "react";
import Main from "./Mian";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { DrawerProvider } from "../context/Drawer";
import { useNavigate } from "react-router-dom";

export default function PageLayout({ children }) {
    const [user, setUser] = useState(null)
    const location = useLocation();
    const navigate = useNavigate();
    const adminRoutes = [
        "/forgot-password",
        "/user-list",
        "/user-profile",
        "/my-account",
        "/bookings",
        "/bookings-view/:id",
        "/trip-list",
        "/trip-view/:id",
        "/trip-upload",
        "/edit-trip/:id",
        "/boat-list",
        "/add-boat",
        "/boat-view/:id",
        "/edit-boat/:id",
        "/reviews",
        "/invoice-list",
        "/invoice-details",
        "/order-list",
        "/settings",
        "/addon-list",
        "/addons",
        "/addon-view/:id",
        "/edit-addon/:id",
        "/faqs",
        "/faqs-list",
        "/faq-view/:id",
        "/edit-faq/:id",
        "/gallery",
        "/edit-gallery",
        "/blogs",
        "/blogs-list",
        "/edit-blog/:id",
        "/blog-view/:id",
        "/create-promo",
        "/promos-list",
        "/edit-promo/:id",
        "/promo-view/:id",
        "/customer-questions",
        "/terms",
        "/about-us"
    ];

    useEffect(() => {
        const fetchData = async () => {
            let user = localStorage.getItem('admin')
            user = JSON?.parse(user);
            if (user !== null && user.role === 'admin') {
                setUser(user);
            }
            else {
                navigate('/login')
            }
        }
        fetchData();
    }, [location]);

    return (
        <DrawerProvider>
            <Header user={user} setUser={setUser} />
            <Sidebar user={user} setUser={setUser} />
            <Main>
                <>
                    {children}
                    {location.pathname !== "/message" ? <Footer /> : ""}
                </>
            </Main>
        </DrawerProvider>
    )
}
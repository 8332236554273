import {API} from '.'

export const getAllUsers = async() => await API.get('/users')

export const deleteUser = async(id) => await API.delete(`/users/delete/${id}`)

export const blockUser = async(id) => await API.put(`/block/${id}`)

export const unBlockUser = async(id) => await API.put(`/unblock/${id}`)

export const userReviews = async(id) => await API.get(`/reviews`)

export const deleteReview = async(id) => await API.delete(`/reviews/${id}`)

export const toogleReviewStatus = async(id) => await API.put(`/toogleReview/${id}`)

export const toggleBookingConfirm = async(id) => await API.put(`/bookings/${id}/confirm`)

export const toggleBookingCancel = async(id) => await API.put(`/bookings/${id}/cancel`)



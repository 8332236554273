import React from "react";
import { Box, Icon, Item, List, Text } from "./elements";

export default function Pagination({ totalItems, itemsPerPage, currentPage, onPageChange, totalNoPages }) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const renderPageNumbers = () => {
    let pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Item
          key={i}
          className={`mc-paginate-item ${i === currentPage ? "active" : ""}`}
          onClick={() => onPageChange(i)}
        >
          {i}
        </Item>
      );
    }
    return pageNumbers;
  };

  return (
    <Box className="mc-paginate">
      <Text className="mc-paginate-title">
        Showing <b>{itemsPerPage * (currentPage - 1) + 1}</b> -{" "}
        <b>{Math.min(itemsPerPage * currentPage, totalItems)}</b> of <b>{totalItems}</b> Results
      </Text>
      <List className="mc-paginate-list">
        {
          !(currentPage <= 1) &&
          <Item className="mc-paginate-item" onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
            <Icon type="chevron_left" />
          </Item>
        }
        {renderPageNumbers()}
        {
          !(currentPage >= totalNoPages) &&
          <Item
          className="mc-paginate-item"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          >
          <Icon type="chevron_right" />
        </Item>
        }
      </List>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import {Button} from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout, CardHeader } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import * as API from "../../api/addons";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";



export default function AddonView() {
    const id = useParams().id;
    const navigate = useNavigate();
 
  const [form, setForm] = useState({
    enTitle: "",
    enDesc: "",
    areTitle: "",
    areDesc: "",
    price: "",
    status: "Active",
  }); 

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async(id) => {
      try {
        const res = await API.getAddonById(id)
        const data = res.data.data;
        setForm({
          enTitle: data.en.title,
          enDesc: data.en.description,
          areTitle: data.are.title,
          areDesc: data.are.description,
          price: data.price,
          status: data.status,
        })
      } catch (error) {
        if(error.response.data.message) toast.error(error.response.data.message)
        else    toast.error('Failed to get Addon details')
      }
    }
    if(id)
    fetchData(id);
  }, [id]);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title="View Addon">
            <Button
                className={`material-icons icons p-2`}
                style={{
                  marginLeft: "15px",
                  borderRadius: "100%",
                  color: "white",
                  backgroundColor: "#5e5d72",
                }}
              onClick={() => navigate(`/edit-addon/${id}`)}
              >
                edit
              </Button>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={6}>
          <CardLayout>
            <CardHeader title="english details" dotsMenu={data?.dotsMenu} />
            <Row>
              <Col xl={12}>
                <LabelField
                  type="text"
                  label="Addon title"
                  fieldSize="w-100 h-md"
                  value={form.enTitle}
                  onChange={handleChange}
                  name="enTitle"
                  disabled
                />
              </Col>
              <Col xl={12}>
                <LabelTextarea
                  label="description"
                  name="enDesc"
                  value={form.enDesc}
                  onChange={handleChange}
                  fieldSize="w-100 h-text-md"
                  disabled
                />
              </Col>
            </Row>
          </CardLayout>
        </Col>

        <Col xl={6}>
          <CardLayout>
            <CardHeader title="Arabic details" dotsMenu={data?.dotsMenu} />
            <Row>
              <Col xl={12}>
                <LabelField
                  type="text"
                  label="addon title"
                  fieldSize="w-100 h-md"
                  name="areTitle"
                  value={form.areTitle}
                  onChange={handleChange}
                  disabled
                />
              </Col>
              <Col xl={12}>
                <LabelTextarea
                  label="description"
                  name="areDesc"
                  value={form.areDesc}
                  onChange={handleChange}
                  fieldSize="w-100 h-text-md"
                  disabled
                />
              </Col>
            </Row>
          </CardLayout>
        </Col>
        <Col xl={12}>
          <CardLayout>
            <Col xl={12}>
              <LabelField
                type="number"
                label="price"
                value={form.price}
                onChange={handleChange}
                name="price"
                fieldSize="w-100 h-md"
                disabled
              />{" "}
            </Col>
            <Col xl={12}>
              <LabelField
                label="status"
                name="status"
                value={form.status}
                onChange={handleChange}
                option={["Active", "Inactive"]}
                fieldSize="w-100 h-md"
                disabled
              />{" "}
            </Col>
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}

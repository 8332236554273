import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Box, Button, Image } from "../../components/elements";
import { LabelField } from "../../components/fields";
import { CardLayout } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import * as API from "../../api/blogs";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function BlogView() {
  const id = useParams().id;
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const res = await API.getBlogById(id)
        const data = res.data.data;
        setEnTitle(data.en.title)
        setAreTitle(data.are.title)
        setEnPostContent(data.en.content)
        setArePostContent(data.are.content)
        setCoverImage([data.image])
      } catch (error) {
        if (error.response.data.message) toast.error(error.response.data.message)
        else toast.error('Failed to fetch Blog details')
        console.log(error.message);
      }
    }
    if (id)
      fetchData(id);
  }, [id])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [enPostContent, setEnPostContent] = useState("");
  const [arePostContent, setArePostContent] = useState("");
  const [enTitle, setEnTitle] = useState('')
  const [areTitle, setAreTitle] = useState('')

  const [coverImage, setCoverImage] = useState([])

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title='Blog View'>
              <Button
                className={`material-icons icons p-2`}
                style={{
                  marginLeft: "15px",
                  borderRadius: "100%",
                  color: "white",
                  backgroundColor: "#5e5d72",
                }}
                onClick={() => navigate(`/edit-blog/${id}`)}
              >
                edit
              </Button>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={12}>
          {
            coverImage.map((img, index) => (
              <Box key={img.name} className="mc-trip-upload-image d-flex justify-content-center">
                {
                  (typeof img === 'string') ? <Image src={img} alt="boat image" style={{ maxWidth: '300px' }} /> : <Image src={URL?.createObjectURL?.(img)} alt="boat image" style={{ maxWidth: '300px' }} />
                }
              </Box>
            ))
          }

        </Col>
        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <LabelField
                  type="text"
                  label="english title"
                  fieldSize="w-100 h-md"
                  value={enTitle}
                  disabled
                  name="title"
                />
              </Col>
              <Col xl={12}>
                <div className=" mx-auto p-6 blog-view" style={{ border: '1px solid #9a9a9a', padding: '5px', borderRadius: '5px', wordWrap: 'break-word' }}>
                  <div className="" dangerouslySetInnerHTML={{ __html: enPostContent }}></div>
                </div>
              </Col>
            </Row>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <LabelField
                  type="text"
                  label="arabic title"
                  fieldSize="w-100 h-md"
                  value={areTitle}
                  name="title"
                  disabled
                />
              </Col>
              <Col xl={12}>
                <div className="max-w-4xl mx-auto p-6" style={{ border: '1px solid #9a9a9a', padding: '5px', borderRadius: '5px', wordWrap: 'break-word' }}>
                  <div className="" dangerouslySetInnerHTML={{ __html: arePostContent }}></div>
                </div>
              </Col>
            </Row>
          </CardLayout>
        </Col>

      </Row>
    </PageLayout>
  );
}
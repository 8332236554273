import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Label } from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout, CardHeader } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import * as API from '../../api/promos'
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
export default function EditPromo() {
  const navigate = useNavigate()

  const id = useParams().id
  const currentDate = new Date().toString().split('T')[0]
  const resetForm = () => {
    setForm({
      enDescription: '',
      areDescription: '',
      minAmount: '',
      discount: '',
      promoStatus: 'Active',
      promoExpiry: '',
      promoUsageLimit: '',
      promoCode: '',
      type: 'percentage'
    })
  }
  const handleSubmit = async () => {
    try {
      const enteredDate = new Date(form.promoExpiry);
      const currentDate = new Date();
      if (enteredDate < currentDate) {
        toast.error("Promo expiry date must be in the future");
        return;
      }
      const res = await API.updatePromo(id, {
        enDescription: form.enDescription,
        areDescription: form.areDescription,
        minAmount: form.minAmount,
        discount: form.discount,
        promoStatus: form.promoStatus,
        promoExpiry: form.promoExpiry,
        promoUsageLimit: form.promoUsageLimit,
        promoCode: form.promoCode,
        type: form.type
      })

      if (res.data.status) toast.success('Promo Updated successfully')
      resetForm();
      navigate('/promos-list')

    } catch (error) {
      if (error.response.data.message) toast.error(error.response.data.message)
      else toast.error('Something went wrong')
      console.log(error.message);
    }
  }



  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  const [form, setForm] = useState({
    enDescription: '',
    areDescription: '',
    minAmount: '',
    discount: '',
    promoStatus: 'Active',
    promoExpiry: '',
    promoUsageLimit: '',
    promoCode: '',
    type: 'percentage'
  })

  useEffect(() => {
    const fetchData = async (id) => {

      try {
        const res = await API.getPromoById(id);
        const data = res.data.data
        let date = res.data.data.promoExpiry
        date = date.split('T')
        date = date[0]

        setForm({
          enDescription: data.en.description,
          areDescription: data.are.description,
          minAmount: data.minAmount,
          discount: data.discount,
          promoStatus: data.promoStatus,
          promoExpiry: date,
          promoUsageLimit: data.promoUsageLimit,
          promoCode: data.promoCode,
          type: data.type
        })
      } catch (error) {
        if (error?.response?.data?.status) toast.error(error?.response?.data?.message)
        else toast.error('Failed to get Promo details')
        console.log(error.message);
      }
    }
    if (id)
      fetchData(id)
  }, [id])


  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title='Edit Promo'>
              <Button
                className={`material-icons icons p-2`}
                style={{
                  marginLeft: "15px",
                  borderRadius: "100%",
                  color: "white",
                  backgroundColor: "#5e5d72",
                }}
                onClick={() => navigate(`/promos-list`)}
              >
                cancel
              </Button>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={12}>
          <CardLayout>
            <CardHeader title="promo details" />
            <Row>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="promo code"
                  fieldSize="w-100 h-md"
                  value={form.promoCode}
                  onChange={handleChange}
                  name='promoCode'
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  type="number"
                  label="promo usage limit"
                  fieldSize="w-100 h-md"
                  value={form.promoUsageLimit}
                  onChange={handleChange}
                  name='promoUsageLimit'
                />
              </Col>
              <Col xl={6}><LabelTextarea label="english description" name='enDescription' value={form.enDescription} onChange={handleChange} fieldSize="w-100 h-text-md" /></Col>
              <Col xl={6}><LabelTextarea label="arabic description" name='areDescription' value={form.areDescription} onChange={handleChange} fieldSize="w-100 h-text-md" /></Col>
              <Col xl={6}>
                <LabelField
                  label="discount by"
                  fieldSize="w-100 h-md"
                  option={['percentage', 'amount']}
                  value={form.type}
                  onChange={handleChange}
                  name='type'
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  type="number"
                  label={`discount ${form.type || ''}`}
                  fieldSize="w-100 h-md"
                  value={form.discount}
                  onChange={handleChange}
                  name='discount'
                />
              </Col>

              <Col xl={6}>
                <LabelField
                  type="number"
                  label="minimum amount"
                  fieldSize="w-100 h-md"
                  value={form.minAmount}
                  onChange={handleChange}
                  name='minAmount'
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  label="promo status"
                  option={['Active', 'Inactive']}
                  fieldSize="w-100 h-md"
                  value={form.promoStatus}
                  onChange={handleChange}
                  name='promoStatus'
                />
              </Col>
              <Col xl={6}>
                <Label className="mc-label-field-title">Promo Expiry</Label>

                <input type="date" min={currentDate} name='promoExpiry' className={`mc-label-field-input w-100 h-md `} value={form.promoExpiry} onChange={handleChange}></input>
              </Col>
              <Col xl={12}>
                <Button type ="btn"  className={'btn btn-primary text-xl'} onClick={handleSubmit}>Update Promo</Button>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}

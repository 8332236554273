import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Text, Item, Anchor, Button, Label, Input, Icon, Image } from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { Breadcrumb, DivideTitle } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/settings.json";
import * as API from "../../api/cms";
import { useState } from "react";
import { BsFillTrashFill } from 'react-icons/bs'
import { toast } from "react-toastify";

export default function AboutUs() {
    const [edit, setEdit] = useState(false);
    const [image1, setImage1] = useState(null)
    const [image2, setImage2] = useState(null)
    const [image3, setImage3] = useState(null);
    const [loading, setLoading] = useState(false)

    const [form, setForm] = useState({
        sec1EnTitle: "",
        sec1AreTitle: "",
        sec1EnDescription: "",
        sec1AreDescription: "",
        sec2EnTitle: "",
        sec2AreTitle: "",
        sec2EnDescription: "",
        sec2AreDescription: "",
        sec3EnTitle: "",
        sec3AreTitle: "",
        experience: "",
        boatYacht: "",
        booking: "",
        repeatedUser: "",
    });
    const [points, setPoints] = useState([{ en: "", are: "" }]);

console.log(form);
    const handleSubmit = async () => {
        try {
            setLoading(true);
            const aboutData = new FormData();
            aboutData.append('section1.en.title', form.sec1EnTitle)
            aboutData.append('section1.en.desc', form.sec1EnDescription)
            aboutData.append('section1.are.title', form.sec1AreTitle)
            aboutData.append('section1.are.desc', form.sec1AreDescription)

            aboutData.append('section2.en.title', form.sec2EnTitle)
            aboutData.append('section2.en.desc', form.sec2EnDescription)
            aboutData.append('section2.are.title', form.sec2AreTitle)
            aboutData.append('section2.are.desc', form.sec2AreDescription)

            aboutData.append('section3.en.title', form.sec3EnTitle)
            aboutData.append('section3.are.title', form.sec3AreTitle)
            points.forEach((point) => {
                aboutData.append('section3.en.points[]', point.en);
            });
            points.forEach((point) => {
                aboutData.append('section3.are.points[]', point.are);
            });

            aboutData.append('experience', form.experience)
            aboutData.append('boatYacht', form.boatYacht)
            aboutData.append('booking', form.booking)
            aboutData.append('repeatedUser', form.repeatedUser)

            aboutData.append('section1.image', image1);
            aboutData.append('section2.image', image2)
            aboutData.append('section3.image', image3)

            await API.updateAbout(aboutData);
            setEdit(!edit);
            setLoading(false)
            toast.success("Data updated successfully");
        } catch (error) {
            setLoading(false)
            if (error?.response?.data?.message)
                toast.error(error.response.data.message);
            else toast.error("Something went wrong");
            console.log(error.message);
        }
    };

    const addPoint = () => {
        setPoints([...points, { en: "", are: "" }]);
    };

    const removePoint = (index) => {
        let temp = points;
        temp.splice(index, 1);
        setPoints([...temp]);
    };

    const handleImage1Change = (e) => {
        const file = e.target.files[0];
        setImage1(file)
    };

    const handleImage2Change = (e) => {
        const file = e.target.files[0];
        setImage2(file)
    };

    const handleImage3Change = (e) => {
        const file = e.target.files[0];
        setImage3(file)
    };

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handlePointChange = (index, fieldName, value) => {
        const updatedPoints = [...points];
        updatedPoints[index] = {
            ...updatedPoints[index],
            [fieldName]: value,
        };
        setPoints(updatedPoints);
    };

    const fetchData = async () => {
        try {
            const { data } = await API.getAboutData();
            const newData = data?.[0];
            setForm({
                sec1EnTitle: newData.section1.en.title,
                sec1AreTitle: newData.section1.are.title,
                sec1EnDescription: newData.section1.en.desc,
                sec1AreDescription: newData.section1.are.desc,
                sec2EnTitle: newData.section2.en.title,
                sec2AreTitle: newData.section2.are.title,
                sec2EnDescription: newData.section2.en.desc,
                sec2AreDescription: newData.section2.en.desc,
                sec3EnTitle: newData.section3.en.title,
                sec3AreTitle: newData.section3.are.title,
                experience: newData.experience,
                boatYacht: newData.boatYacht,
                booking: newData.booking,
                repeatedUser: newData.repeatedUser
            });
            setImage1(newData.section1.image)
            setImage2(newData.section2.image)
            setImage3(newData.section3.image)
            const enPoints = newData?.section3?.en?.points;
            const arePoints = newData?.section3?.are?.points;
            const currentPoints = enPoints.map((point, i) => (
                { en: point, are: arePoints[i] }
            ));
            setPoints(currentPoints);
        } catch (error) {
            if (error?.response?.data?.message)
                toast.error(error.response.data.message);
            else toast.error("Failed to fetch details");
        }
    };

    useEffect(() => {
        fetchData();
    }, [edit]);

    const handleEdit = () => {
        setEdit(!edit);
    };

    return (
        <PageLayout>
            <CardLayout className="mb-4">
                <Breadcrumb title="About Us ">
                    {data?.breadcrumb.map((item, index) => (
                        <Item key={index} className="mc-breadcrumb-item">
                            {item.path ? (
                                <Anchor className="mc-breadcrumb-link" href={item.path}>
                                    {item.text}
                                </Anchor>
                            ) : (
                                <p>About Us</p>
                            )}
                        </Item>
                    ))}
                </Breadcrumb>
            </CardLayout>
            <CardLayout className="p-sm-5">
                <Box className="mb-5">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <DivideTitle
                            title="About Us"
                            className="mb-4"
                            style={{ marginBottom: "0 !important" }}
                        />
                        <Button
                            className={`material-icons icons mb-4 p-2 ${edit ? "d-none" : ""}`}
                            style={{
                                marginLeft: "15px",
                                borderRadius: "100%",
                                color: "white",
                                backgroundColor: "#5e5d72",
                            }}
                            onClick={handleEdit}
                        >
                            edit
                        </Button>
                        <Button
                            className={`material-icons icons mb-4 p-2 ${!edit ? "d-none" : ""}`}
                            style={{
                                marginLeft: "15px",
                                borderRadius: "100%",
                                color: "white",
                                backgroundColor: "#5e5d72",
                            }}
                            onClick={handleEdit}
                        >
                            cancel
                        </Button>
                    </div>
                    <Row>
                        <Col xl={12} className="ps-xl-5 ">
                            <Row>
                                <DivideTitle
                                    title="Section 1"
                                    className="mb-4"
                                    style={{ marginBottom: "0 !important" }}
                                />
                                <Col xl={6}>
                                    <LabelField
                                        className={`${edit && 'bg-white'}`}
                                        type="text"
                                        disabled={!edit}
                                        name="sec1EnTitle"
                                        value={form.sec1EnTitle}
                                        onChange={handleChange}
                                        label="english title"
                                        fieldSize="w-100 h-md"
                                    />
                                </Col>
                                <Col xl={6}>
                                    <LabelField
                                        className={`${edit && 'bg-white'}`}
                                        type="text"
                                        disabled={!edit}
                                        name="sec1AreTitle"
                                        value={form.sec1AreTitle}
                                        onChange={handleChange}
                                        label="arabic title"
                                        fieldSize="w-100 h-md"
                                    />
                                </Col>
                                <Col xl={6}>
                                    <LabelTextarea
                                        disabled={!edit}
                                        className={`${edit ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`}
                                        rows={5}
                                        name="sec1EnDescription"
                                        value={form.sec1EnDescription}
                                        onChange={handleChange}
                                        label="english description"
                                        fieldSize=""
                                    />
                                </Col>
                                <Col xl={6}>
                                    <LabelTextarea
                                        disabled={!edit}
                                        className={`${edit ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`}
                                        rows={5}
                                        name="sec1AreDescription"
                                        value={form.sec1AreDescription}
                                        onChange={handleChange}
                                        label="arabic description"
                                        fieldSize="w-100 h-text-md"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <DivideTitle
                                    title="Section 2"
                                    className="my-4"
                                    style={{ marginBottom: "0 !important" }}
                                />
                                <Col xl={6}>
                                    <LabelField
                                        className={`${edit && 'bg-white'}`}
                                        type="text"
                                        disabled={!edit}
                                        name="sec2EnTitle"
                                        value={form.sec2EnTitle}
                                        onChange={handleChange}
                                        label="english title"
                                        fieldSize="w-100 h-md"
                                    />
                                </Col>
                                <Col xl={6}>
                                    <LabelField
                                        className={`${edit && 'bg-white'}`}
                                        type="text"
                                        disabled={!edit}
                                        name="sec2AreTitle"
                                        value={form.sec2AreTitle}
                                        onChange={handleChange}
                                        label="arabic title"
                                        fieldSize="w-100 h-md"
                                    />
                                </Col>
                                <Col xl={6}>
                                    <LabelTextarea
                                        disabled={!edit}
                                        name="sec2EnDescription"
                                        className={`${edit ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`}
                                        rows={5}
                                        value={form.sec2EnDescription}
                                        onChange={handleChange}
                                        label="english description"
                                        type="text"
                                        fieldSize="w-100 h-text-md"
                                    />
                                </Col>
                                <Col xl={6}>
                                    <LabelTextarea
                                        disabled={!edit}
                                        className={`${edit ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`}
                                        name="sec2AreDescription"
                                        value={form.sec2AreDescription}
                                        rows={5}
                                        onChange={handleChange}
                                        label="arabic description"
                                        fieldSize="w-100 h-text-md"
                                    />
                                </Col>
                            </Row>
                            {/* <Col xl={6}><LabelTextarea name="sec3EnTitle" value={form.enAboutTitle} onChange={handleChange} className={`${editHome ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} label="English About Section(3) Title" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea name="sec3AreTitle" value={form.areAboutTitle} onChange={handleChange} className={`${editHome ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} label="Arabic About Section(3) Title" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea name="sec3EnDescription" value={form.enAboutDescription} onChange={handleChange} className={`${editHome ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} label="English About Section(3) Description" fieldSize="w-100 h-text-md" /></Col>
                                <Col xl={6}><LabelTextarea name="sec3AreDescription" value={form.areAboutDescription} onChange={handleChange} className={`${editHome ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`} rows={5} label="Arabic About Section(3) Description" fieldSize="w-100 h-text-md" /></Col> */}
                                {/* <Col xl={6}>
                                    <Label className="mc-label-field-title">About Page Section(3) Image</Label>
                                    {
                                        coverImage.length === 0
                                            ?
                                            <Box className="mc-trip-upload-file mt-2">
                                                <Input type="file" id='coverimage' name='coverimage' onChange={handleCoverImage} />
                                                <Label htmlFor="coverimage"><Icon type="collections" /><Text className={'w-100'}>Upload Cover Image for Blog (3:4)</Text></Label>
                                            </Box>
                                            :
                                            coverImage.map((img, index) => (
                                                <Box key={img.name} className="mc-trip-upload-image d-flex mt-2">
                                                    {
                                                        typeof img === 'string'
                                                            ?
                                                            <Image src={img} alt="boat image" style={{ maxWidth: '300px' }} />
                                                            :
                                                            <Image src={URL?.createObjectURL?.(img)} alt="boat image" style={{ maxWidth: '300px' }} />
                                                    }
                                                    {
                                                        editHome &&
                                                        <Button onClick={() => { setCoverImage([]) }} className="btn btn-danger position-absolute top-0 end-0 m-2 " >Remove Image</Button>
                                                    }
                                                </Box>
                                            ))
                                    }
                                </Col> */}
                                {/* <Col xl={6}>
                                    <LabelField
                                        className={`${editHome ? 'bg-white w-100 border p-2 rounded' : 'bg-light rounded p-2 w-100 border'}`}
                                        disabled={!editHome}
                                        label="home about section visiblity"
                                        option={["true", "false"]}
                                        name="status"
                                        onChange={handleStatusChange}
                                        value={form.status}
                                        fieldSize="w-100 h-md"
                                    />
                                </Col> */}

                            <Row className="mt-2">
                                <DivideTitle
                                    title="Home Page Section**"
                                    className="mt-4"
                                    style={{ marginBottom: "0 !important" }}
                                />
                                <Row className="mt-2">
                                    <Col xl={6}>
                                        <LabelField
                                            className={`${edit && 'bg-white'}`}
                                            type="text"
                                            disabled={!edit}
                                            name="sec3EnTitle"
                                            value={form.sec3EnTitle}
                                            onChange={handleChange}
                                            label="english title"
                                            fieldSize="w-100 h-md"
                                        />
                                    </Col>
                                    <Col xl={6}>
                                        <LabelField
                                            className={`${edit && 'bg-white'}`}
                                            type="text"
                                            disabled={!edit}
                                            name="sec3AreTitle"
                                            value={form.sec3AreTitle}
                                            onChange={handleChange}
                                            label="arabic title"
                                            fieldSize="w-100 h-md"
                                        />
                                    </Col>
                                </Row>

                                <DivideTitle
                                    title="Pointers..."
                                    className="mt-4"
                                    style={{ marginBottom: "0 !important" }}
                                />
                                <Col xl={5}>
                                    <DivideTitle
                                        title="English"
                                        style={{ marginBottom: "0 !important" }}
                                    />
                                </Col>
                                <Col xl={5}>
                                    <DivideTitle
                                        title="Arabic"
                                        style={{ marginBottom: "0 !important" }}
                                    />
                                </Col>

                                {points.map((ele, index) => (
                                    <Row key={index}>
                                        <Col xl={5}>

                                            <LabelField
                                                className={`${edit && 'bg-white'}`}
                                                type="text"
                                                disabled={!edit}
                                                name={`enPoint${index}`}
                                                value={points[index].en}
                                                onChange={(e) =>
                                                    handlePointChange(index, "en", e.target.value)
                                                }
                                                fieldSize="w-100 h-md"
                                            />
                                        </Col>
                                        <Col xl={5}>

                                            <LabelField
                                                className={`${edit && 'bg-white'}`}
                                                type="text"
                                                disabled={!edit}
                                                name={`arePoint${index}`}
                                                value={points[index].are}
                                                onChange={(e) =>
                                                    handlePointChange(index, "are", e.target.value)
                                                }
                                                fieldSize="w-100 h-md"
                                            />
                                        </Col>
                                        {edit && <Col xl={2}>
                                            <Button
                                                onClick={() => {
                                                    removePoint(index);
                                                }}
                                                className={"btn btn-danger"}
                                            >
                                                <BsFillTrashFill className="h6" style={{ marginBottom: 0 }} />
                                            </Button>
                                        </Col>}
                                    </Row>
                                ))}
                                {edit &&
                                    <Col xl={10} style={{ textAlign: 'center' }}>
                                        <Button type = "btn" className={"btn-primary btn w-50"} onClick={addPoint}>
                                            Add point
                                        </Button>
                                    </Col>
                                }
                            </Row>
                            <Row className="mt-2">
                                <DivideTitle
                                    title="Stats"
                                    className="mb-4"
                                    style={{ marginBottom: "0 !important" }}
                                />
                                <Col xl={6}>
                                    <LabelField
                                        className={`${edit && 'bg-white'}`}
                                        type="text"
                                        disabled={!edit}
                                        name="experience"
                                        value={form.experience}
                                        onChange={handleChange}
                                        label="bookings"
                                        fieldSize="w-100 h-md"
                                    />
                                </Col>
                                <Col xl={6}>
                                    <LabelField
                                        type="text"
                                        className={`${edit && 'bg-white'}`}
                                        disabled={!edit}
                                        name="boatYacht"
                                        value={form.boatYacht}
                                        onChange={handleChange}
                                        label="5-Star Reviews"
                                        fieldSize="w-100 h-md"
                                    />
                                </Col>
                                <Col xl={6}>
                                    <LabelField
                                        className={`${edit && 'bg-white'}`}
                                        type="text"
                                        disabled={!edit}
                                        name="booking"
                                        value={form.booking}
                                        onChange={handleChange}
                                        label="Targeted Species"
                                        fieldSize="w-100 h-md"
                                    />
                                </Col>
                                <Col xl={6}>
                                    <LabelField
                                        className={`${edit && 'bg-white'}`}
                                        type="text"
                                        disabled={!edit}
                                        name="repeatedUser"
                                        value={form.repeatedUser}
                                        onChange={handleChange}
                                        label="happy customers"
                                        fieldSize="w-100 h-md"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={12}>
                            <CardLayout>
                                <Box className="mc-trip-upload-media">
                                    {/* <input type="file" name='image' multiple onChange={handleImageChange} /> */}
                                    {image1 === null || image1 === undefined ? <Box className="mc-trip-upload-file">
                                        <Input
                                            type="file"
                                            id="image1"
                                            name="image1"
                                            onChange={handleImage1Change}
                                        />
                                        <Label htmlFor="image1">
                                            <Icon type="collections" />
                                            <Text>{'image 1'}</Text>
                                        </Label>
                                    </Box>
                                        :
                                        <Box className="mc-trip-upload-image">
                                            {typeof image1 === 'string' ? (
                                                <Image src={image1} alt="boat image" style={{ maxHeight: '250px' }} />
                                            ) : (
                                                <Image src={URL?.createObjectURL?.(image1)} alt="boat image" style={{ maxHeight: '250px' }} />
                                            )}
                                            {edit && <Button style={{ color: 'red' }} onClick={() => { setImage1(null) }} className="material-icons delete position-absolute top-0 end-0 m-2 image-delete-button" >cancel</Button>}
                                        </Box>
                                    }
                                    {image2 === null || image2 === undefined ? <Box className="mc-trip-upload-file">

                                        <Input
                                            type="file"
                                            id="image2"
                                            name="image2"
                                            onChange={handleImage2Change}
                                        />
                                        <Label htmlFor="image2">
                                            <Icon type="collections" />
                                            <Text>{'image 2'}</Text>
                                        </Label>
                                    </Box> :
                                        <Box className="mc-trip-upload-image">
                                            {typeof image2 === 'string' ? (
                                                <Image src={image2} alt="boat image" style={{ maxHeight: '250px' }} />
                                            ) : (
                                                <Image src={URL?.createObjectURL?.(image2)} alt="boat image" style={{ maxHeight: '250px' }} />
                                            )}
                                            {edit && <Button style={{ color: 'red' }} onClick={() => { setImage2(null) }} className="material-icons delete position-absolute top-0 end-0 m-2 image-delete-button" >cancel</Button>}
                                        </Box>
                                    }
                                    {image3 === null || image3 === undefined ? <Box className="mc-trip-upload-file">
                                        <Input
                                            type="file"
                                            id="image3"
                                            name="image3"
                                            onChange={handleImage3Change}
                                        />
                                        <Label htmlFor="image3">
                                            <Icon type="collections" />
                                            <Text>{'image 3'}</Text>
                                        </Label>
                                    </Box>
                                        :
                                        <Box className="mc-trip-upload-image">
                                            {typeof image3 === 'string' ? (
                                                <Image src={image3} alt="boat image" style={{ maxHeight: '250px' }} />
                                            ) : (
                                                <Image src={URL?.createObjectURL?.(image3)} alt="boat image" style={{ maxHeight: '250px' }} />
                                            )}
                                            {edit && <Button style={{ color: 'red' }} onClick={() => { setImage3(null) }} className="material-icons delete position-absolute top-0 end-0 m-2 image-delete-button" >cancel</Button>}
                                        </Box>
                                    }

                                </Box>
                                <Button
                                    className={`flex justify-center items-center gap-1 btn w-100 btn-primary mt-5 ${edit ? "" : "d-none"}`}
                                    text={loading ? "Updating..." : "Update"}
                                    icon="cloud_upload"
                                    onClick={!loading && handleSubmit}
                                />
                            </CardLayout>
                        </Col>
                    </Row>
                </Box>
            </CardLayout>
        </PageLayout>
    );
}
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Anchor, Icon, Item } from "../../components/elements";
import { CardLayout, CardHeader, FloatCard } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import BookingTable from "../../components/tables/BookingTable";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/bookingList.json";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import ReactPaginate from 'react-paginate';

const localizer = momentLocalizer(moment);

export default function BookingList({ bookingData, fetchBookings }) {

    const [bookings, setBookings] = useState(bookingData)
    const [events, setEvents] = useState([]);
    const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const navigate = useNavigate();


    const setCalenderData = () => {
        const newEvents = bookings?.map((booking) => {
            const { date, time } = booking.selectedSlot;
            const [year, month, day] = date?.split('-')?.map(Number);
            const [, hours, minutes, ampm] = time?.match(/(\d+):(\d+) ([APap][Mm])/);

            // Convert hours to a 24-hour format
            let hourValue = parseInt(hours, 10);
            if (ampm.toLowerCase() === 'pm' && hourValue !== 12) {
                hourValue += 12;
            } else if (ampm.toLowerCase() === 'am' && hourValue === 12) {
                hourValue = 0;
            }
            const start = new Date(year, month - 1, day, hourValue, minutes);
            const end = new Date(year, month - 1, day, hourValue + 4, minutes);
            return {
                title: booking?.trip?.en?.title + `(${booking?.guests} per.)`,
                start: start,
                end: end,
                id: booking._id,
                color: new Date() < start ? (booking.status === 'Confirmed' ? 'green' : 'red') : 'purple'
            };
        });

        setEvents(newEvents);
    };


    const onSelectEvent = useCallback((calEvent) => {
        navigate(`/bookings-view/${calEvent.id}`)
    }, [])


    useEffect(() => {
        setCalenderData();
    }, [bookings])


    const getEventStyle = (event, start, end, isSelected) => {
        const backgroundColor = event.color;
        const style = {
            backgroundColor,
            borderRadius: '5px',
            opacity: 0.8,
            color: 'white',
            border: 'none',
        };

        return {
            style: style,
        };
    };


    const cancelledBookings = bookingData?.filter((c) => c?.status === "Cancelled")
    const confirmedBookings = bookingData?.filter((r) => r?.status === "Confirmed")
    const pending = bookingData?.filter((p) => p?.status === "Pending")



    useEffect(() => {
        setBookings(bookingData)
    }, [bookingData]);



    useEffect(() => {
        let myInterval
        fetchBookings();
        // myInterval = setInterval(() => {
        //     if (window.location.pathname !== '/bookings') {
        //         clearInterval(myInterval)
        //         return;
        //     }
        //     try {
        //         fetchBookings();
        //     } catch (error) {
        //         console.error(error.message);
        //         clearInterval(myInterval);
        //     }
        // }, 5000);
    }, []);


    const handleDateChange = (e) => {
        setDateRange({ ...dateRange, [e.target.name]: e.target.value });
    }
    const filterBookings = (e) => {
        if (!dateRange.startDate) {
            alert('Please select a start date!')
            return;
        }
        if (!dateRange.endDate) {
            alert('Please select a end date!')
            return;
        }
        e.preventDefault();
        const filteredBookings = bookingData.filter((booking) => new Date(booking.selectedSlot.date) >= new Date(dateRange.startDate) && new Date(booking.selectedSlot.date) <= new Date(dateRange.endDate));
        console.log(filteredBookings);
        setBookings(filteredBookings)
    }




    const getSlot = (selectedDate, selectedTime) => {
        const timeSlot = selectedTime;

        const [time, meridiem] = timeSlot.split(' ');
        const [hours, minutes] = time.split(':');

        let adjustedHours = parseInt(hours, 10);

        if (meridiem === 'PM' && adjustedHours !== 12) {
            adjustedHours += 12;
        }

        if (meridiem === 'AM' && adjustedHours === 12) {
            adjustedHours = 0;
        }

        const selectedSlot = new Date(selectedDate.replaceAll('-', '/'));

        selectedSlot.setHours(adjustedHours);
        selectedSlot.setMinutes(parseInt(minutes, 10));
        selectedSlot.setSeconds(0);

        return selectedSlot
    }

    const upcomingTrips = bookings.filter((trip) => {
        const currentDate = new Date();
        const selectedSlot = getSlot(trip.selectedSlot.date, trip.selectedSlot.time);
        return selectedSlot > currentDate && trip.status !== 'Cancelled';
    })

    const pastTrips = bookings.filter((trip) => {
        const currentDate = new Date();
        const selectedSlot = getSlot(trip.selectedSlot.date, trip.selectedSlot.time);
        return selectedSlot <= currentDate && trip.status !== 'Cancelled';
    })

    const cancelledTrips = bookings.filter((trip) => trip.status === 'Cancelled').sort((a, b) => {
        const selectedSlot1 = getSlot(a.selectedSlot.date, a.selectedSlot.time);
        const selectedSlot2 = getSlot(b.selectedSlot.date, b.selectedSlot.time);
        return new Date(selectedSlot2) - new Date(selectedSlot1)
    });

    const sortedUpcomingData = upcomingTrips?.sort((a, b) => {
        const selectedSlot1 = getSlot(a.selectedSlot.date, a.selectedSlot.time);
        const selectedSlot2 = getSlot(b.selectedSlot.date, b.selectedSlot.time);
        return new Date(selectedSlot1) - new Date(selectedSlot2)
    });

    const sortedPastData = pastTrips?.sort((a, b) => {
        const selectedSlot1 = getSlot(a.selectedSlot.date, a.selectedSlot.time);
        const selectedSlot2 = getSlot(b.selectedSlot.date, b.selectedSlot.time);
        return new Date(selectedSlot2) - new Date(selectedSlot1)
    });


    const dataCount = sortedPastData.length;
    const indexOfLastItem = (currentPage + 1) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedPastData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(dataCount / itemsPerPage);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage.selected);
    };

    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title={data?.pageTitle} loadingIcon={true} fetchData={fetchBookings}>
                            {data?.breadcrumb.map((item, index) => (
                                <Item key={index} className="mc-breadcrumb-item">
                                    {item.path ? <Anchor className="mc-breadcrumb-link" href={item.path}>{item.text}</Anchor> : item.text}
                                </Item>
                            ))}
                        </Breadcrumb>
                    </CardLayout>
                </Col>
                <Col xl={3}>
                    <div className="cursor-pointer" onClick={() => { setBookings(bookingData); setDateRange({ startDate: '', endDate: '' }) }}>
                        <FloatCard
                            variant={'lg blue'}
                            digit={bookingData.length}
                            title={'All Bookings'}
                            icon={'sailing'}
                        />
                    </div>
                </Col>
                <Col xl={3}>
                    <div className="cursor-pointer" onClick={() => { setBookings(pending); setDateRange({ startDate: '', endDate: '' }) }}>
                        <FloatCard
                            variant={'lg purple'}
                            digit={pending.length}
                            title={'Pending Actions'}
                            icon={'pending'}
                        />
                    </div>
                </Col>
                <Col xl={3}>
                    <div className="cursor-pointer" onClick={() => { setBookings(confirmedBookings); setDateRange({ startDate: '', endDate: '' }) }}>
                        <FloatCard
                            variant={'lg green'}
                            title={'confirmed Bookings'}
                            icon={'check_circle'}
                            digit={confirmedBookings.length}
                        />
                    </div>
                </Col>
                <Col xl={3}>
                    <div className="cursor-pointer" onClick={() => { setBookings(cancelledBookings); setDateRange({ startDate: '', endDate: '' }) }}>
                        <FloatCard
                            variant={'lg red'}
                            title={'canceled Bookings'}
                            icon={'remove_circle'}
                            digit={cancelledBookings.length}
                        />
                    </div>
                </Col>
                <Breadcrumb className={'bg-white p-3.5 rounded-lg'} title={'filter bookings'}>
                    <div className="flex items-center gap-20 date-range">
                        {/* <div class="col-sm-4 col-lg-4 col-md-4 relative">
                            <input type="date" class="form-control" id="start_date" name="start_date" value="{{ old('start_date') }}" required />
                        </div> */}
                        <div>
                            <label className="block" for="startDate">From</label>
                            <input onChange={handleDateChange} value={dateRange.startDate} className="bg-gray-100 p-2 rounded-lg relative" type="date" name="startDate" />
                        </div>
                        <div>
                            <label className="block" for="endDate">To</label>
                            <input onChange={handleDateChange} value={dateRange.endDate} min={dateRange.startDate} className="bg-gray-100 p-2 rounded-lg relative" type="date" name="endDate" />
                        </div>
                        <button onClick={filterBookings} className="btn btn-primary !py-2 !px-4">Filter</button>
                    </div>
                </Breadcrumb>
                <div className='h-[100vh] 2xl:h-[80vh] '>
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        eventPropGetter={getEventStyle}
                        onSelectEvent={onSelectEvent}
                        dayLayoutAlgorithm={'no-overlap'}
                        scrollToTime
                        popup
                    />
                </div>
                {
                    sortedUpcomingData.length > 0 &&
                    <Col xl={12}>
                        <CardLayout className={'relative'}>
                            <CardHeader className={'!text-2xl'} title={'Upcoming Bookings'} />
                            <Row xs={1} sm={4} className="mb-4">
                            </Row>
                            <BookingTable
                                thead={data?.table.thead}
                                fetchData={fetchBookings}
                                tbody={sortedUpcomingData}
                                bookingType={'upcoming'}
                            />
                        </CardLayout>
                    </Col>
                }
                {
                    currentItems.length > 0
                    &&
                    <Col xl={12}>
                        <CardLayout>
                            <CardHeader className={'!text-2xl'} title={'Past Bookings'} />
                            <Row xs={1} sm={4} className="mb-4">
                            </Row>
                            <BookingTable
                                currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                thead={data?.table.thead}
                                fetchData={fetchBookings}
                                tbody={currentItems}
                            />
                            {/* <Pagination totalItems={dataCount} itemsPerPage={itemsPerPage} currentPage={currentPage} onPageChange={(page) => handlePageChange(page)} totalNoPages={totalPages} /> */}
                            <div>
                                <ReactPaginate
                                    pageCount={totalPages}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    previousLabel={
                                        <Item className="mc-paginate-item" disabled={currentPage === 1}>
                                            <Icon type="chevron_left" />
                                        </Item>
                                    }
                                    nextLabel={
                                        <Item className="mc-paginate-item" disabled={currentPage === totalPages}>
                                            <Icon type="chevron_right" />
                                        </Item>
                                    }
                                    breakLabel={'...'}
                                    onPageChange={(page) => handlePageChange(page)}
                                    containerClassName={'flex justify-end mt-4 items-center'}
                                    pageClassName={'page-class'}
                                    activeClassName={'active-page'}
                                    previousClassName={'select-none'}
                                    nextClassName={'select-none'}
                                    breakClassName={'break'}
                                />
                            </div>
                        </CardLayout>
                    </Col>
                }
                {
                    cancelledTrips.length > 0 &&
                    <Col xl={12}>
                        <CardLayout className={'relative'}>
                            <CardHeader className={'!text-2xl'} title={'Cancelled Bookings'} />
                            <Row xs={1} sm={4} className="mb-4">
                            </Row>
                            <BookingTable
                                thead={data?.table.thead}
                                fetchData={fetchBookings}
                                tbody={cancelledTrips}
                                bookingType={'upcoming'}
                            />
                        </CardLayout>
                    </Col>
                }
            </Row>
        </PageLayout>
    );
}
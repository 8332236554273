import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Box,
  Anchor,
  Button,
  Image,
  Input,
  Label,
  Icon,
  Text,
} from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/addBoat.json";
import { createBoat } from "../../api/boats";
import ChipInput from "material-ui-chip-input";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const slots = new Map();

const timeSlots = [
  "05:00 AM",
  "05:30 AM",
  "06:00 AM",
  "06:30 AM",
  "07:00 AM",
  "07:30 AM",
  "08:00 AM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
];

export default function AddBoat() {
  const [uploadFile, setUploadFile] = React.useState("image upload");
  const [image, setImage] = useState([]);
  const [enSpecs, setEnSpecs] = useState([]);
  const [areSpecs, setAreSpecs] = useState([]);
  const [currentDate, setCurrentDate] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    enTitle: "",
    enDesc: "",
    areTitle: "",
    areDesc: "",
    type: "Boat",
    totalCapacity: "",
    status: "Active",
    bedrooms: "",
    washrooms: "",
    saloon: true,
    flyBridge: true,
    sunBed: true,
    diningArea: true,
    musicSystem: "",
    boatNo: "",
    captainName: "",
    areCaptainName: "",
    captainPhone: ""
  });

  const resetForm = () => {
    setForm({
      enTitle: "",
      enDesc: "",
      areTitle: "",
      areDesc: "",
      type: "Boat",
      totalCapacity: "",
      // size: "",
      status: "Active",
      bedrooms: "",
      washrooms: "",
      saloon: true,
      flyBridge: true,
      sunBed: true,
      diningArea: true,
      musicSystem: "",
      boatNo: "",
      captainName: "",
      areCaptainName: "",
      captainPhone: ""
    });
    setImage([]);
    setAreSpecs([]);
    setEnSpecs([]);
  };

  const convertDateToString = (date) => {
    if (date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    } else {
      return null;
    }
  };

  const handleDateChange = (date) => {
    setCurrentDate(date);
    let foundTimes = null;
    const convertedDate = convertDateToString(date);
    if (slots.has(convertedDate)) foundTimes = slots.get(convertedDate);
    if (foundTimes !== null) {
      setSelectedTimes(foundTimes);
    } else {
      setSelectedTimes([]);
    }
  };

  const handleTimeChange = (time) => {
    if (time !== "") {
      setSelectedTimes((prev) => {
        let newTimes = [...prev];
        if (newTimes.includes(time)) {
          newTimes = newTimes.filter((t) => t !== time);
        } else {
          newTimes.push(time);
        }
        return newTimes;
      });
    }
  };

  const selectAll = () => {
    if (selectedTimes.length === timeSlots.length) {
      setSelectedTimes([]);
    } else {
      setSelectedTimes(timeSlots);
    }
  }

  const removeDate = (date) => {
    slots.delete(convertDateToString(date));
    let updatedDates = [...selectedDates];
    updatedDates.forEach((d, index) => {
      if (convertDateToString(d) === convertDateToString(date)) {
        updatedDates.splice(index, 1);
      }
    });
    setSelectedDates(updatedDates);
    setCurrentDate(null);
  };

  const addDate = (e) => {
    e.preventDefault();

    const convertedDate = convertDateToString(currentDate);
    slots.set(convertedDate, selectedTimes);

    setSelectedDates([...selectedDates, currentDate]);
    setCurrentDate(null);
    setSelectedTimes([]);
  };


  const handleSubmit = async () => {
    try {
      if (
        image.length <= 0 ||
        form.enTitle === "" ||
        // form.enDesc === "" ||
        form.totalCapacity <= 0 ||
        form.boatNo === ""
      ) {
        toast.warning("Please fill all the details!");
        return;
      }
      setLoading(true);
      const boatData = new FormData();
      boatData.append("en.title", form.enTitle);
      boatData.append("en.description", form.enDesc);
      boatData.append("are.title", form.areTitle);
      boatData.append("are.description", form.areDesc);
      boatData.append("type", form.type);
      boatData.append("totalCapacity", form.totalCapacity);
      // boatData.append("size", form.size);
      let slotsArray = [];
      // for (const [key, value] of slots) {
      //   slotsArray.push({ date: key, time: value });
      // }
      // slotsArray = JSON.stringify(slotsArray);
      boatData.append(`slots`, slotsArray);
      boatData.append("status", form.status);
      boatData.append("boatNumber", form.boatNo);
      boatData.append("captainName", form.captainName);
      boatData.append("areCaptainName", form.areCaptainName);
      boatData.append("captainPhone", form.captainPhone);
      enSpecs.forEach((spec) => {
        boatData.append("en.specification[]", spec);
      });
      areSpecs.forEach((spec) => {
        boatData.append("are.specification[]", spec);
      });
      image.forEach((img, index) => {
        boatData.append(`image${index}`, img);
      });
      const res = await createBoat(boatData);
      resetForm();
      if (res && res?.data?.status) {
        setLoading(false);
        toast.success("New Boat Created");
        navigate("/boat-list");
      }
    } catch (error) {
      setLoading(false);
      if (error?.response.data.message)
        toast.error(error.response.data.message);
      else toast.error("Something went wrong");
      console.log(error.message);
    }
  };

  const handleChange = (e) => {
    if (e.target.value === "true") setForm({ ...form, [e.target.name]: true });
    else if (e.target.value === "false")
      setForm({ ...form, [e.target.name]: false });
    else setForm({ ...form, [e.target.name]: e.target.value });
  };

  const removeImage = (index) => {
    let imageArray = [...image];
    imageArray.splice(index, 1);
    setImage(imageArray);
  };

  const handleImageChange = async (e) => {
    const fileList = e.target.files;
    setImage([...image, ...fileList]);
  };

  const handleEnglishAdd = (spec) => {
    setEnSpecs([...enSpecs, spec]);
  };

  const handleEnglishDelete = (spec) => {
    setEnSpecs(enSpecs.filter((tag) => tag !== spec));
  };
  const handleArabicAdd = (spec) => {
    setAreSpecs([...areSpecs, spec]);
  };

  const handleArabicDelete = (spec) => {
    setAreSpecs(areSpecs.filter((tag) => tag !== spec));
  };
  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title="Add Boat">
              {data?.breadcrumb.map((item, index) => (
                <li key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </li>
              ))}
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={6}>
          <CardLayout>
            <Row>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="english title"
                  onChange={handleChange}
                  required
                  name="enTitle"
                  value={form.enTitle}
                  fieldSize="w-100 h-md"
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="arabic title"
                  onChange={handleChange}
                  required
                  name="areTitle"
                  value={form.areTitle}
                  fieldSize="w-100 h-md"
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="Boat Number"
                  onChange={handleChange}
                  required
                  name="boatNo"
                  value={form.boatNo}
                  fieldSize="w-100 h-md"
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  label="boat type"
                  onChange={handleChange}
                  required
                  name="type"
                  value={form.type}
                  option={["Boat", "Yacht"]}
                  fieldSize="w-100 h-md"
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="captain Name"
                  onChange={handleChange}
                  required
                  name="captainName"
                  value={form.captainName}
                  fieldSize="w-100 h-md"
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="captain Name(Arabic)"
                  onChange={handleChange}
                  required
                  name="areCaptainName"
                  value={form.areCaptainName}
                  fieldSize="w-100 h-md"
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="captain Phone No."
                  onChange={handleChange}
                  required
                  name="captainPhone"
                  value={form.captainPhone}
                  fieldSize="w-100 h-md"
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  label="status"
                  onChange={handleChange}
                  required
                  name="status"
                  value={form.status}
                  option={["Active", "Inactive"]}
                  fieldSize="w-100 h-md"
                />
              </Col>
              <Col xl={12}>
                <LabelField
                  type="number"
                  label="total capacity"
                  onChange={handleChange}
                  required
                  name="totalCapacity"
                  value={form.totalCapacity}
                  fieldSize="w-100 h-md"
                />
              </Col>
              <Col xl={12}>
                <Label className="mc-label-field-title block">
                  english Specifications
                </Label>
                <ChipInput
                  style={{ margin: "10px 0", display: 'block' }}
                  value={enSpecs}
                  onAdd={handleEnglishAdd}
                  onDelete={handleEnglishDelete}
                  variant="outlined"
                />
              </Col>
              <Col xl={12}>
                <Label className="mc-label-field-title block">
                  arabic specifications
                </Label>
                <ChipInput
                  style={{ margin: "10px 0", display: 'block' }}
                  value={areSpecs}
                  onAdd={handleArabicAdd}
                  onDelete={handleArabicDelete}
                  variant="outlined"
                />
              </Col>
            </Row>
          </CardLayout>
        </Col>
        <Col xl={6}>
          <CardLayout>
            <Box className="mc-trip-upload-media">
              <Box className="mc-trip-upload-file">
                <Input
                  type="file"
                  id="image"
                  name="image"
                  required
                  multiple
                  onChange={handleImageChange}
                />
                <Label htmlFor="image">
                  <Icon type="collections" />
                  <Text>{uploadFile}</Text>
                </Label>
              </Box>
              {image.map((img, index) => (
                <Box key={img.name} className="mc-trip-upload-image">
                  <Image
                    src={URL?.createObjectURL?.(img)}
                    alt="boat image"
                    style={{ maxHeight: "250px" }}
                  />
                  <Button
                    style={{ color: "red" }}
                    onClick={() => {
                      removeImage(index);
                    }}
                    className="material-icons delete position-absolute top-0 end-0 m-2 image-delete-button"
                  >
                    cancel
                  </Button>
                </Box>
              ))}
            </Box>
            <Anchor
              className="flex items-center justify-center gap-1 btn w-100 btn-primary mt-2"
              text={loading ? "Adding Boat..." : "publish & view"}
              icon="cloud_upload"
              href="#"
              onClick={!loading && handleSubmit}
            />
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}

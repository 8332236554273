import React, { useState, useEffect } from "react";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import {
  Text,
  Box,
  Anchor,
  Icon,
  Heading,
  Button,
} from "../elements";
import { toast } from "react-toastify";

import EmptyList from "../EmptyList";
import moment from "moment";
import { deleteBooking } from "../../api/bookings";
import { Modal, Row } from "react-bootstrap";
import Breadcrumb from "../Breadcrumb";
import { CardHeader } from "../cards";
import Pagination from "../Pagination";


export default function BookingTable({ thead, tbody, fetchData, currentPage, itemsPerPage, bookingType }) {

  const [data, setData] = useState([]);
  const [alertModal, setAlertModal] = useState({
    active: false,
    _id: '',
    index: ''
  });

  const deleteThisBooking = async (id, index) => {
    try {
      const res = await deleteBooking(id);
      fetchData();
      if (res && res.data.status) toast.warning('Booking deleted')
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error.response.data.message)
      else toast.error('Failed to delete Booking')
      console.log(error.message);
    }
  }

  useEffect(() => {
    setData(tbody);
  }, [tbody]);


  const getSlot = (selectedDate, selectedTime) => {
    const timeSlot = selectedTime;

    const [time, meridiem] = timeSlot.split(' ');
    const [hours, minutes] = time.split(':');

    let adjustedHours = parseInt(hours, 10);

    if (meridiem === 'PM' && adjustedHours !== 12) {
      adjustedHours += 12;
    }

    if (meridiem === 'AM' && adjustedHours === 12) {
      adjustedHours = 0;
    }

    const selectedSlot = new Date(selectedDate.replaceAll('-', '/'));

    selectedSlot.setHours(adjustedHours);
    selectedSlot.setMinutes(parseInt(minutes, 10));
    selectedSlot.setSeconds(0);

    return selectedSlot
  }



  return (
    <Box className="mc-table-responsive">
      {data?.length === 0 && <EmptyList title={"Bookings"} />}
      {data?.length !== 0 && (
        <>
          <Table className="mc-table">
            <Thead className="mc-table-head primary">
              <Tr>
                {thead.map((item, index) => (
                  <Th key={index}>{item}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody className="mc-table-body even">
              {tbody?.map((item, index) => {

                const currentDate = new Date();
                const selectedSlot = getSlot(item.selectedSlot.date, item.selectedSlot.time);
                const isCreatedToday = moment(item.createdAt).format('L') === moment(currentDate).format('L');
                return (
                  <Tr key={index} className={selectedSlot < currentDate || item.status !== 'Confirmed' ? '!bg-red-100' : '!bg-green-50'}>
                    <Td title="id">
                      <Box className="mc-table-check">
                        <Text># {bookingType === 'upcoming' ? `${index + 1}` : `${((currentPage) * itemsPerPage) + index + 1}`}</Text>
                      </Box>
                    </Td>
                    <Td className={'!w-max !max-w-max'}>
                      <Text>{item?.user?.userName}</Text>
                    </Td>

                    {/* <Td>{item?.user?.email}</Td> */}

                    <Td className={'!w-max !max-w-max'}>
                      <div>
                      {item?.trip?.en?.title}
                      </div>
                      <div className="text-[12px]">
                      ({item?.guests} per.)
                      </div>
                    </Td>
                    <Td title={item?.selectedSlot} className={'!max-w-max'}>
                      <Text className="mc-table-badge ">
                        <div>
                          {moment(selectedSlot).format('MMM Do YYYY')}
                        </div>
                        <div className="text-[12px]">
                          {moment(selectedSlot).format('LT')}
                        </div>
                      </Text>

                    </Td>

                    <Td>
                      <Text className="mc-table-badge ">{item?.totalPrice?.toFixed(2)}</Text>
                    </Td>

                    <Td className={'!w-max !max-w-max'}>
                      <Text className={'!w-max'}>{item?.payment?.transactionNo}</Text>
                    </Td>
                    <Td className={'!w-max !max-w-max'}>
                      <Text className={`!w-max mc-table-badge ${item?.payment?.status === 'success' ? 'green' : item?.payment?.status === 'pending' ? 'purple' : 'red'}`}>{item?.payment?.status}</Text>
                    </Td>

                    <Td title={item?.status}>
                      {item?.status === "Pending" && (
                        <Text className="mc-table-badge purple">Pending</Text>
                      )}
                      {item?.status === "Cancelled" && (
                        <Text className="mc-table-badge red">Cancelled</Text>
                      )}
                      {item?.status === "Confirmed" && (
                        <Text className="mc-table-badge green">Confirmed</Text>
                      )}
                    </Td>

                    <Td title={item?.status}>
                      {item?.bookedBy === "admin"
                        ?
                        <Text className="mc-table-badge purple">admin</Text>
                        :
                        <Text className="mc-table-badge green">user</Text>
                      }
                    </Td>

                    <Td>
                      <Box className="mc-table-action">
                        <Anchor
                          href={`/bookings-view/${item._id}`}
                          title="View"
                          className="material-icons view"
                        >
                          visibility
                        </Anchor>
                        {
                          item.status === 'Pending' && !isCreatedToday &&
                          <Button className="material-icons delete !bg-[#ffaab6]" onClick={() => setAlertModal({ active: true, id: item?._id, index: index })}>delete</Button>
                        }
                      </Box>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </>
      )}


      <Modal show={alertModal.active} onHide={() => setAlertModal({ active: false, id: '', index: '' })}>
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">Want to delete this booking?</Text>
          <Modal.Footer>
            <Button type="btn" className="btn btn-secondary" onClick={() => setAlertModal({ active: false, id: '', index: '' })}>nop, close</Button>
            <Button type="btn" className="btn btn-danger" onClick={() => { deleteThisBooking(alertModal.id, alertModal.index); setAlertModal({ active: false, id: '', index: '' }) }}>yes, delete</Button>
          </Modal.Footer>
        </Box>
      </Modal>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Image, Text, Box, Icon, Heading } from "../elements/index";
import Button from "../elements/Button"
import * as API from "../../api/users";
import EmptyList from "../EmptyList";
import "../../css/bootstrap.min.css"
import moment from "moment";
export default function UsersTable({
  itemsPerPage,
  currentPage,
  thead,
  tbody,
  fetchUserData
}) {
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [blockModal, setBlockModal] = useState({
    active: false,
    id: "",
    index: "",
  });

  useEffect(() => {
    setData(tbody);
  }, [tbody]);

  const blockUser = async (id, index) => {
    try {
      const res = await API.blockUser(id);
      if (res.data.status === true) {
        fetchUserData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const unBlockUser = async (id, index) => {
    try {
      const res = await API.unBlockUser(id);
      if (res.data.status === true) {
        fetchUserData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Box className="mc-table-responsive">
      {data.length === 0 && <EmptyList title={"Users"} />}
      {
        data.length !== 0 &&
        <Table className="mc-table">
          <Thead className="mc-table-head primary">
            <Tr>
              <Th>
                <Box className="mc-table-check">
                  <Text>uid</Text>
                </Box>
              </Th>
              {thead.map((item, index) => (
                <Th key={index}>{item}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody className="mc-table-body even">
            {data?.map((item, index) => (
              <Tr key={index}>
                <Td title="id">
                  <Box className="mc-table-check">
                    <Text>#{((currentPage) * itemsPerPage) + index + 1}</Text>
                  </Box>
                </Td>
                <Td title={item?.userName} className={'!max-w-max'}>
                  <Box className="mc-table-profile text !w-max">
                    {item.image && <Image src={item?.image} />}
                    <Text>{item?.userName}</Text>
                  </Box>
                </Td>
                {/* <Td title={item?.role}>
                  <Box className="mc-table-icon role">
                    {item?.role === "member" && (
                      <Icon className="material-icons yellow">
                        {item?.role.icon}
                      </Icon>
                    )}
                    {item?.role === "user" && (
                      <Icon className="material-icons green">person </Icon>
                    )}
                    {item?.role === "admin" && (
                      <Icon className="material-icons orange">settings</Icon>
                    )}
                    {item?.role === "founder" && (
                      <Icon className="material-icons blue">
                        {item?.role.icon}
                      </Icon>
                    )}
                    <Text as="span">{item?.role}</Text>
                  </Box>
                </Td> */}
                <Td className={'!max-w-max'} title={item?.email}>{item?.email}</Td>
                <Td title={item?.isEmailVerified}>
                  {item?.isEmailVerified === true && (
                    <Text className="mc-table-badge green">True</Text>
                  )}
                  {item?.isEmailVerified === false && (
                    <Text className="mc-table-badge red">False</Text>
                  )}
                </Td>
                <Td title={item?.fromGoogle}>
                  {item?.fromGoogle === true && (
                    <Text className="mc-table-badge green">True</Text>
                  )}
                  {item?.fromGoogle === false && (
                    <Text className="mc-table-badge red">False</Text>
                  )}
                </Td>
                <Td>
                  <Box className="d-flex gap-1 justify-content-center">
                    <button
                      title="Block User"
                      className={`btn ${item.isBlocked ? 'btn-success' : 'btn-danger'}`}
                      onClick={() =>
                        setBlockModal({
                          active: true,
                          id: item?._id,
                          index: index,
                        })
                      }
                    >
                      {item?.isBlocked ? 'unblock' : 'block'}
                    </button>
                    {/* <Button onClick={async() => { await API.deleteUser(item?._id); fetchUserData() }} className='material-icons delete'>
                      delete
                    </Button> */}
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      }

      <Modal
        show={editModal}
        onHide={() => setEditModal(false, setUserData(""))}
      >
        <Box className="mc-user-modal">
          <Text as="p">{userData?.email}</Text>

          <Form.Group className="form-group inline">
            <Form.Label>status</Form.Label>
            <Form.Select>
            </Form.Select>
          </Form.Group>
          <Modal.Footer>
            <Button
              type="btn"
              className="btn btn-secondary"
              onClick={() => setEditModal(false)}
            >
              close popup
            </Button>
            <Button
              type="btn"
              className="btn btn-success"
              onClick={() => setEditModal(false)}
            >
              save Changes
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>

      <Modal
        show={blockModal.active}
        onHide={() => setBlockModal({ active: false, id: "", index: "" })}
      >
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">
            Want to {data[blockModal.index]?.isBlocked ? "Unblock" : "Block"}{" "}
            this user's account?
          </Text>
          <Modal.Footer>
            <button
              className={"btn btn-secondary"}
              onClick={() =>
                setBlockModal({ active: false, id: "", index: "" })
              }
            >
              No, close
            </button>
            <button
              className={'btn btn-danger'}
              onClick={() => {
                if (data[blockModal.index]?.isBlocked === true)
                  unBlockUser(blockModal.id, blockModal.index);
                else blockUser(blockModal.id, blockModal.index);
                setBlockModal({ active: false, id: "", index: "" });
              }}
            >
              yes, {data[blockModal.index]?.isBlocked ? "Unblock" : "Block"}
            </button>
          </Modal.Footer>
        </Box>
      </Modal>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Anchor, Button, Image, Input, Label, Icon, Text } from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import * as API from "../../api/boats";
import ChipInput from 'material-ui-chip-input'
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

const slots = new Map();

export default function EditBoat() {
    const navigate = useNavigate();
    const id = useParams().id

    const [currentDate, setCurrentDate] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedDatesForGroup, setSelectedDatesForGroup] = useState([]);
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [uploadFile, setUploadFile] = useState('image upload');
    const [image, setImage] = useState([])
    const [enSpecs, setEnSpecs] = useState([])
    const [areSpecs, setAreSpecs] = useState([])
    const [loading, setLoading] = useState(false);
    const [boatAvailability, setBoatAvailability] = useState([{ value: 0, label: 'Sunday', slot: '06:00 AM' }]);
    const [form, setForm] = useState({ enTitle: '', enDesc: '', areTitle: '', areDesc: '', type: 'Boat', totalCapacity: '', status: 'Active', boatNo: "", captainName: "", areCaptainName: "", captainPhone: "" })
    const handleEnglishAdd = (spec) => {
        setEnSpecs([...enSpecs, spec])
    }

    const timeSlots = [
        "05:00 AM",
        "05:30 AM",
        "06:00 AM",
        "06:30 AM",
        "07:00 AM",
        "07:30 AM",
        "08:00 AM",
        "02:00 PM",
        "02:30 PM",
        "03:00 PM",
        "03:30 PM",
        "04:00 PM",
        "04:30 PM",
        "05:00 PM",
    ];

    const availabilityOptions = [
        { value: 0, label: 'Sunday', slot: '06:00 AM' },
        { value: 1, label: 'Monday', slot: '06:00 AM' },
        { value: 2, label: 'Tuesday', slot: '06:00 AM' },
        { value: 3, label: 'Wednesday', slot: '06:00 AM' },
        { value: 4, label: 'Thursday', slot: '06:00 AM' },
        { value: 5, label: 'Friday', slot: '06:00 AM' },
        { value: 6, label: 'Saturday', slot: '06:00 AM' },
    ]

    const handleSelectChange = (selectedOptions) => {
        setBoatAvailability(selectedOptions);
    };

    const handleGroupSlotChange = (e, value) => {
        setBoatAvailability(prevState => prevState.map(day => {
            if (day.value === value) {
                return { ...day, slot: e.target.value };
            }
            return day;
        }));
    };

    const handleEnglishDelete = (spec) => {
        setEnSpecs(enSpecs.filter((tag) => tag !== spec))
    }
    const handleArabicAdd = (spec) => {
        setAreSpecs([...areSpecs, spec])
    }

    const handleArabicDelete = (spec) => {
        setAreSpecs(areSpecs.filter((tag) => tag !== spec))
    }

    const fetchData = async () => {
        try {
            const res = await API.getBoatDetails(id);
            const data = res.data.data
            setForm({
                enTitle: data.en.title,
                enDesc: data.en.description,
                areTitle: data.are.title,
                areDesc: data.are.description,
                type: data.type,
                totalCapacity: data.totalCapacity,
                status: data.status,
                boatNo: data?.boatNumber,
                captainName: data?.captainName,
                areCaptainName: data?.areCaptainName,
                captainPhone: data?.captainPhone
            })
            setEnSpecs(data.en.specification)
            setAreSpecs(data.are.specification)
            setImage(data.image)
            setBoatAvailability(data.availability)
            if (data.groupSlots) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const futureDates = data.groupSlots.filter(date => {
                    const currentDate = new Date(date);
                    currentDate.setHours(0, 0, 0, 0);
                    return currentDate >= today;
                });
                setSelectedDatesForGroup(futureDates.map((s) => new Date(s)));
            }

            const allSlots = data?.slots;
            let tempDates = [];
            if (allSlots) {
                allSlots.forEach((slot) => {
                    const date = slot.date;
                    const timeArray = slot.time;
                    slots.set(date, timeArray);
                    tempDates.push(new Date(date));
                });
            }
            setSelectedDates([...tempDates]);

        } catch (error) {
            console.log('errorrrrrrrr', error);
            if (error.response?.data?.message) {
                toast.error(error.response?.data?.message)
            }
            else
                toast.error('Failed to get boat/yacht details')
        }
    }

    const resetForm = () => {
        setForm({ enTitle: '', enDesc: '', areTitle: '', areDesc: '', type: 'Boat', totalCapacity: '', status: 'Active', boatNo: '', captainName: "", areCaptainName: "", captainPhone: "" });
        slots.clear();
        setImage([])
        setAreSpecs([])
        setEnSpecs([])
    }

    useEffect(() => {
        slots.clear();
        fetchData();
    }, [])

    const convertDateToString = (date) => {
        if (date) {
            return `${date?.getFullYear()}-${date?.getMonth() + 1}-${date?.getDate()}`;
        } else {
            return null;
        }
    };

    const handleDateChange = (date) => {
        setCurrentDate(date);
        let foundTimes = null;
        const convertedDate = convertDateToString(date);
        if (slots.has(convertedDate))
            foundTimes = slots.get(convertedDate);
        if (foundTimes !== null) {
            setSelectedTimes(foundTimes);
        } else {
            setSelectedTimes([]);
        }
    };
    const handleTimeChange = (time) => {
        if (time !== "") {
            setSelectedTimes((prev) => {
                let newTimes = [...prev];
                if (newTimes.includes(time)) {
                    newTimes = newTimes.filter((t) => t !== time);
                } else {
                    newTimes.push(time);
                }
                return newTimes;
            });
        }
    };

    const selectAll = () => {
        if (selectedTimes.length === timeSlots.length) {
            setSelectedTimes([]);
        } else {
            setSelectedTimes(timeSlots);
        }
    }

    const removeDate = (date) => {
        slots.delete(convertDateToString(date));
        let updatedDates = [...selectedDates];
        updatedDates.forEach((d, index) => {
            if (convertDateToString(d) === convertDateToString(date)) {
                updatedDates.splice(index, 1);
            }
        });
        setSelectedDates(updatedDates);
        setCurrentDate(null);
    };
    const addDate = (e) => {
        e.preventDefault();

        const convertedDate = convertDateToString(currentDate);
        slots.set(convertedDate, selectedTimes);

        setSelectedDates([...selectedDates, currentDate]);
        setCurrentDate(null);
        setSelectedTimes([]);
    };

    const handleSubmit = async () => {
        try {
            if (
                image.length <= 0 ||
                form.enTitle === "" ||
                form.totalCapacity <= 0 ||
                form.price <= 0 ||
                form.boatNo === ''
            ) {
                toast.warning("Please fill all the details!");
                return;
            }
            setLoading(true);
            const boatData = new FormData();
            boatData.append('en.title', form.enTitle)
            boatData.append('en.description', form.enDesc)
            boatData.append('are.title', form.areTitle)
            boatData.append('are.description', form.areDesc)
            boatData.append('type', form.type)
            boatData.append('totalCapacity', form.totalCapacity)
            // boatData.append('size', form.size)
            let slotsArray = [];
            for (const [key, value] of slots) {
                slotsArray.push({ date: key, time: value });
            }
            slotsArray = JSON.stringify(slotsArray);
            boatData.append(`slots`, slotsArray);
            boatData.append(`groupSlots`, JSON.stringify(selectedDatesForGroup));
            boatData.append('status', form.status)
            boatData.append("boatNumber", form.boatNo);
            boatData.append("captainName", form.captainName);
            boatData.append("areCaptainName", form.areCaptainName);
            boatData.append("captainPhone", form.captainPhone);
            boatData.append('availability', JSON.stringify(boatAvailability));
            enSpecs.forEach((spec) => {
                boatData.append('en.specification[]', spec);
            });
            areSpecs.forEach((spec) => {
                boatData.append('are.specification[]', spec);
            });
            image.forEach((img, index) => {
                if (typeof img === 'string')
                    boatData.append('image[]', img)
                else
                    boatData.append(`image${index}`, img);
            });
            const res = await API.boatYachtUpdateById(id, boatData)
            if (res && res.data.status) {
                setLoading(false);
                toast.success('Boat/Yacht updated successfully')
                resetForm();
                navigate('/boat-list');
            }
        } catch (error) {
            setLoading(false);
            if (error.response.data.message) toast.error(error.response.data.message)
            else (toast.error('Something went wrong'))
            console.log(error.message)
        }
    }

    const handleChange = (e) => {
        if (e.target.value === 'true') setForm({ ...form, [e.target.name]: true })
        else if (e.target.value === 'false') setForm({ ...form, [e.target.name]: false })
        else
            setForm({ ...form, [e.target.name]: e.target.value });
    }

    const handleImageChange = (e) => {
        const fileList = e.target.files;
        setImage([...image, ...fileList]);
    };

    const removeImage = (index) => {
        let imageArray = [...image];
        imageArray.splice(index, 1)
        setImage(imageArray)
    }

    const handleGroupDateChange = (date) => {
        const currentDates = selectedDatesForGroup;
        const converted = currentDates.map((d) => convertDateToString(d));
        if (converted.includes(convertDateToString(date))) {
            const newDates = converted.filter((d) => d !== convertDateToString(date));
            const originalDates = newDates.map((d) => new Date(d));
            setSelectedDatesForGroup(originalDates);
        } else
            setSelectedDatesForGroup([...selectedDatesForGroup, new Date(new Date(date).setHours(10))])
    }
    const isWeekday = (date) => {
        const day = date.getDay()
        const availability = boatAvailability.map((avail) => avail.value);
        return availability.includes(day);
    };

    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title={form.enTitle}>
                            <Button
                                className={`material-icons icons p-2`}
                                style={{
                                    marginLeft: "15px",
                                    borderRadius: "100%",
                                    color: "white",
                                    backgroundColor: "#5e5d72",
                                }}
                                onClick={() => navigate(`/boat-list`)}
                            >
                                cancel
                            </Button>
                        </Breadcrumb>
                    </CardLayout>
                </Col>
                <Col xl={6}>
                    <CardLayout>
                        <Row>
                            <Col xl={6}><LabelField type="text" label="english title" onChange={handleChange} required name='enTitle' value={form.enTitle} fieldSize="w-100 h-md" /></Col>
                            <Col xl={6}><LabelField type="text" label="arabic title" onChange={handleChange} required name='areTitle' value={form.areTitle} fieldSize="w-100 h-md" /></Col>
                            <Col xl={6}><LabelField type="text" label="Boat Number" onChange={handleChange} required name="boatNo" value={form.boatNo} fieldSize="w-100 h-md" /></Col>
                            <Col xl={6}><LabelField label="boat type" onChange={handleChange} required name='type' value={form.type} option={['Boat', 'Yacht']} fieldSize="w-100 h-md" /></Col>
                            <Col xl={6}>
                                <LabelField
                                    type="text"
                                    label="captain Name"
                                    onChange={handleChange}
                                    required
                                    name="captainName"
                                    value={form.captainName}
                                    fieldSize="w-100 h-md"
                                />
                            </Col>
                            <Col xl={6}>
                                <LabelField
                                    type="text"
                                    label="captain Name(Arabic)"
                                    onChange={handleChange}
                                    required
                                    name="areCaptainName"
                                    value={form.areCaptainName}
                                    fieldSize="w-100 h-md"
                                />
                            </Col>
                            <Col xl={6}>
                                <LabelField
                                    type="text"
                                    label="captain Phone No."
                                    onChange={handleChange}
                                    required
                                    name="captainPhone"
                                    value={form.captainPhone}
                                    fieldSize="w-100 h-md"
                                />
                            </Col>
                            <Col xl={6}><LabelField label="status" onChange={handleChange} required name='status' value={form.status} option={['Active', 'Inactive']} fieldSize="w-100 h-md" /></Col>
                            <Col xl={12}><LabelField type="number" label="total capacity" onChange={handleChange} required name='totalCapacity' value={form.totalCapacity} fieldSize="w-100 h-md" /></Col>
                            <Col xl={12}>
                                <Label className="mc-label-field-title mb-2">english specifications</Label>
                                <ChipInput
                                    style={{ margin: '5px 0' }}
                                    value={enSpecs}
                                    onAdd={handleEnglishAdd}
                                    onDelete={handleEnglishDelete}
                                    variant="outlined"
                                />
                            </Col>
                            <Col xl={12}>
                                <Label className="mc-label-field-title mb-2">arabic specifications</Label>
                                <ChipInput
                                    style={{ margin: '5px 0' }}
                                    value={areSpecs}
                                    onAdd={handleArabicAdd}
                                    onDelete={handleArabicDelete}
                                    variant="outlined"
                                />
                            </Col>
                        </Row>
                        <div>
                            <Col xl={12}>
                                <div className="red-highlight mt-4">
                                    <DatePicker
                                        highlightDates={selectedDates}
                                        placeholderText="Select dates &#128197;"
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                        wrapperClassName="w-full"
                                        onChange={handleDateChange}
                                        selected={currentDate}
                                        customInput={
                                            <LabelField
                                                type="text"
                                                label="Select Unavailable Slots for Individual trips"
                                                name="date"
                                                fieldSize="w-100 h-md"
                                                className={'whitespace-nowrap'}
                                            />
                                        }
                                    />
                                </div>
                            </Col>
                            <Col xl={12}>
                                {currentDate && (
                                    <>
                                        <Label className="mc-label-field-title mt-3">
                                            Select Time
                                        </Label>
                                        <div className="time-intervals flex flex-wrap gap-x-4 w-full">
                                            {timeSlots.map((time) => (
                                                <>
                                                    <div
                                                        key={time}
                                                        className="flex items-center mb-2 whitespace-nowrap"
                                                    >
                                                        <label className="flex items-center">
                                                            <input
                                                                style={{ marginRight: "5px" }}
                                                                className="form-checkbox h-5 w-5 text-blue-500"
                                                                type="checkbox"
                                                                value={time}
                                                                checked={selectedTimes.some(
                                                                    (t) => t === time
                                                                )}
                                                                onChange={(e) =>
                                                                    handleTimeChange(e.target.value)
                                                                }
                                                            />
                                                            <span className="text-lg">{time}</span>
                                                        </label>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                        <div>
                                            <h1 onClick={selectAll} className={`${selectedTimes.length === timeSlots.length ? 'bg-red-500' : 'bg-blue-500'} text-base px-4 py-2 text-white font-normal rounded-lg w-max cursor-pointer ml-auto`}>{selectedTimes.length === timeSlots.length ? 'Remove all' : 'Select all'}</h1>
                                        </div>
                                    </>
                                )}
                            </Col>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "10px",
                                    gap: "10px",
                                }}
                            >
                                {currentDate &&
                                    slots.has(convertDateToString(currentDate)) && (
                                        <button
                                            type="btn"
                                            className="btn btn-danger"
                                            onClick={() => {
                                                removeDate(currentDate);
                                            }}
                                        >
                                            Remove Date
                                        </button>
                                    )}
                                {currentDate &&
                                    slots.has(convertDateToString(currentDate)) && (
                                        <button type="btn" onClick={addDate} className="btn btn-primary">
                                            Update Date
                                        </button>
                                    )}
                                {currentDate &&
                                    !slots.has(convertDateToString(currentDate)) && (
                                        <button
                                            type="btn"
                                            onClick={addDate}
                                            disabled={
                                                form.type !== "Group"
                                                    ? !currentDate || selectedTimes.length === 0
                                                    : !currentDate
                                            }
                                            className="btn btn-success"
                                        >
                                            Add Date
                                        </button>
                                    )}
                            </div>
                        </div>
                        <div className="mb-4">
                            <Label className="mc-label-field-title mt-3">
                                Select Available days for group trip
                            </Label>
                            <Select
                                isMulti
                                name="availability"
                                options={availabilityOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={boatAvailability}
                                onChange={handleSelectChange}
                            />
                        </div>
                        <div className="mb-4">
                            <Label className="mc-label-field-title mt-3">
                                Select timings
                            </Label>
                            <div>
                                {
                                    boatAvailability?.length > 0 && boatAvailability.map((avail, index) => (
                                        <div className="flex items-center gap-4">
                                            <div className="flex-1">
                                                {avail.label}
                                            </div>
                                            <div className="flex items-center gap-3 flex-[2]">
                                                <input type="radio" id="am" name={`group_slots${index}`} checked={avail?.slot === '06:00 AM'} onChange={(e) => handleGroupSlotChange(e, avail.value)} value="06:00 AM" />
                                                <label for="am">06:00 AM</label>
                                                <input type="radio" id="pm" name={`group_slots${index}`} checked={avail?.slot === '03:00 PM'} onChange={(e) => handleGroupSlotChange(e, avail.value)} value="03:00 PM" />
                                                <label for="pm">03:00 PM</label>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div >
                        <Col xl={12}>
                            <div className="w-full red-highlight">
                                <DatePicker
                                    highlightDates={selectedDatesForGroup}
                                    placeholderText="Select dates &#128197;"
                                    dateFormat="dd/MM/yyyy"
                                    onChange={handleGroupDateChange}
                                    minDate={new Date()}
                                    wrapperClassName="w-full"
                                    filterDate={isWeekday}
                                    customInput={
                                        <LabelField
                                            type="text"
                                            label="Select Unavailable Dates for Group trips"
                                            name="date"
                                            fieldSize="w-full h-md"
                                            className={'whitespace-nowrap'}
                                        />
                                    }
                                />
                            </div>
                        </Col>
                    </CardLayout >
                </Col>
                <Col xl={6}>
                    <CardLayout>
                        <Box className="mc-trip-upload-media">
                            <Box className="mc-trip-upload-file">
                                <Input type="file" id='image' name='image' multiple onChange={handleImageChange} />
                                <Label htmlFor="image"><Icon type="collections" /><Text>{uploadFile}</Text></Label>
                            </Box>
                            {
                                image.map((img, index) => (
                                    <Box key={img.name} className="mc-trip-upload-image">
                                        {typeof img === 'string' ? (
                                            <Image src={img} alt="boat image" style={{ maxHeight: '250px' }} />
                                        ) : (
                                            <Image src={URL?.createObjectURL?.(img)} alt="boat image" style={{ maxHeight: '250px' }} />
                                        )}
                                        <Button style={{ color: 'red' }} onClick={() => { removeImage(index) }} className="material-icons delete position-absolute top-0 end-0 m-2 image-delete-button">cancel</Button>
                                    </Box>
                                ))
                            }
                        </Box>
                        <Anchor
                            className="flex items-center justify-center gap-1 btn w-100 btn-primary mt-2"
                            text={loading ? "Updating Data..." : 'update Boat'}
                            icon="cloud_upload"
                            href="#"
                            onClick={!loading && handleSubmit}
                        />
                    </CardLayout>
                </Col>
            </Row>
        </PageLayout>
    )
}

import {API} from '.'

export const createStaticData = async(data) => await API.put("/static",data)

export const getStaticData = async() => await API.get('/static');

export const updateFooter = async(data) => await API.put('/static',data);

export const updateAbout = async(data) => await API.put('/about',data);

export const getAboutData = async() => await API.get('/about');
import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Anchor, Heading, Box, Text, Icon, Button } from "../elements";
import * as API from '../../api/trips'
import EmptyList from "../EmptyList";

export default function TripsTable({ thead, tbody }) {
    const [alertModal, setAlertModal] = useState({ active: false, id: '', index: '' });
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(tbody)
    }, [tbody]);

    let newData = data;

    const deleteTrip = async (id, index) => {
        const res = await API.deleteTrip(id);
        if (res.data.status === true) {
            const updatedData = [...newData]
            updatedData.splice(index, 1)
            setData(updatedData)
        }
    }

    return (
        <Box className="mc-table-responsive">
            {data.length === 0 && <EmptyList title={'Trips'} />}
            {
                data.length !== 0 &&
                <Table className="mc-table trip">
                    <Thead className="mc-table-head primary">
                        <Tr>
                            <Th>
                                <Box className="mc-table-check">
                                    <Text>uid</Text>
                                </Box>
                            </Th>
                            {thead.map((item, index) => (
                                <Th key={index}>{item}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody className="mc-table-body even">
                        {Array.isArray(data) && data?.map((item, index) => (
                            <Tr key={index}>
                                <Td title={index + 1}>
                                    <Box className="mc-table-check">
                                        <Text>#{index + 1}</Text>
                                    </Box>
                                </Td>
                                <Td>
                                    <Box className="mc-table-trip md">
                                        <Box className="mc-table-group">
                                            <Heading as="h6">{item?.en?.title}</Heading>
                                            <Text>{item?.en?.description}</Text>
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>
                                    <Box className="mc-table-trip md">
                                        <Box className="mc-table-group">
                                            <Heading as="h6">{item?.are?.title}</Heading>
                                            <Text>{item?.are?.description}</Text>
                                        </Box>
                                    </Box>
                                </Td>
                                <Td>{item?.type}</Td>
                                {/* <Td>{item?.price}</Td> */}
                                {/* <Td>
                                    {item?.maxPerson}
                                </Td> */}
                                <Td>{item?.status}</Td>
                                <Td>
                                    <Box className="mc-table-action">
                                        {/* <Anchor href={`/trip-view/${item._id}`} title="View" className="material-icons view">visibility</Anchor> */}
                                        <Anchor href={`/edit-trip/${item._id}`} title="Edit" className="material-icons edit">edit</Anchor>
                                        <Button key={item?._id} title="Delete" className="material-icons delete" onClick={(e) => { setAlertModal({ active: true, id: item?._id, index: index }) }}>delete</Button>
                                    </Box>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            }

            <Modal show={alertModal.active} onHide={() => setAlertModal({ active: false, id: '', index: '' })}>
                <Box className="mc-alert-modal">
                    <Icon type="new_releases" />
                    <Heading as="h3">are your sure!</Heading>
                    <Text as="p">Want to delete this trip?</Text>
                    <Modal.Footer>
                        <Button type="btn" className="btn btn-secondary" onClick={() => setAlertModal({ active: false, id: '', index: '' })}> close</Button>
                        <Button type="btn" className="btn btn-danger" onClick={() => { deleteTrip(alertModal.id, alertModal.index); setAlertModal({ active: false, id: '', index: '' }) }}>yes, delete</Button>
                    </Modal.Footer>
                </Box>
            </Modal>
        </Box>
    );
}
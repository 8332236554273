import axios from 'axios'
// export const API = axios.create({ baseURL: `http://localhost:4000` });
export const API = axios.create({baseURL: `https://api.ocealics.com`});

API.interceptors.request.use((req) => {
    const user = localStorage.getItem('admin')
    if (user) {
        const { token } = JSON.parse(user)
        req.headers['x-api-key'] = token
    }
    return req;
});
import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout, EcommerceCard, FloatCard } from "../../components/cards";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/reviewlist.json";
import ReviewTables from "../../components/tables/ReviewTables";
import * as userAPI from '../../api/users'
import { toast } from "react-toastify";
import { useEffect } from "react";

export default function ReviewList() {

  const [reviews, setReviews] = useState([]);

  const [filteredReviews, setFilteredReviews] = useState([]);


  const fetchReviews = async () => {
    try {
      const res = await userAPI.userReviews();
      if (res.status) {
        setReviews(res?.data?.data)
      }
    } catch (error) {
      if (error?.response?.data?.message) toast.error(error.response.data.message)
      else toast.error('Failed to fetch Users details')
      console.log(error.message);
    }
  }

  const disabledReviews = reviews.filter((r) => r.status === "Inactive");
  const activeReviews = reviews.filter((r) => r.status !== "Inactive");


  useEffect(() => {
    fetchReviews();
  }, []);
  useEffect(() => {
    setFilteredReviews(reviews)
  }, [reviews]);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"All Reviews"}>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xs={12} xl={12}>
          <Row xs={1} sm={2}>
            <Col xl={4}>
              <div className="cursor-pointer" onClick={() => setFilteredReviews(reviews)}>
                <FloatCard
                  variant={'lg blue'}
                  digit={reviews.length}
                  title={'Reviews'}
                  icon={'hotel_class'}
                />
              </div>
            </Col>
            <Col xl={4}>
              <div className="cursor-pointer" onClick={() => setFilteredReviews(activeReviews)}>
                <FloatCard
                  variant={'lg green'}
                  digit={activeReviews.length}
                  title={'Active Reviews'}
                  icon={'check_circle'}
                />
              </div>
            </Col>
            <Col xl={4}>
              <div className="cursor-pointer" onClick={() => setFilteredReviews(disabledReviews)}>
                <FloatCard
                  variant={'lg red'}
                  digit={disabledReviews.length}
                  title={'Disabled Reviews'}
                  icon={'remove_circle'}
                />
              </div>
            </Col>
            {/* <Col>
              <EcommerceCard
                variant="yellow"
                title="total reviews"
                number={totalReviews}
                icon="hotel_class"
                trend={`${reviewPercentageThisMonth >= 0
                    ? "trending_up"
                    : "trending_down"
                  }`}
                percent={`+ ${reviewPercentageThisMonth.toFixed(1)}%`}
                compare="this month"
              />
            </Col>
            <Col>
              <EcommerceCard
                variant="blue"
                title="Disabled reviews"
                number={disabledReviews}
                icon="hotel_class"
                trend="&nbsp;"
                compare="&nbsp;"
              />
            </Col> */}
          </Row>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <ReviewTables
                  thead={data?.trip.thead}
                  tbody={filteredReviews}
                  fetchReviews={fetchReviews}
                />
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Button, Image, Label } from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import CustomOption from "../../components/fields/CustomOption";
import Multiselect from "multiselect-react-dropdown";
import * as API from "../../api/boats";
import * as AddOn from "../../api/addons";
import * as Trip from "../../api/trips";
import * as Promo from "../../api/promos";
import { useParams, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

const slots = new Map();

const timeSlots = [
  "05:00 AM",
  "06:00 AM",
  "07:00 AM",
  "08:00 AM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
];

const convertDateToString = (date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export default function TripView() {
  const id = useParams().id;
  const navigate = useNavigate();

  const [currentDate, setCurrentDate] = useState(null);

  const [images, setImages] = useState([]);
  const [boats, setBoats] = useState([]);
  const [selectedBoats, setSelectedBoats] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [selectedPromos, setSelectedPromos] = useState([]);
  const [addons, setAddOns] = useState([]);
  const [promos, setPromos] = useState([]);
  const [initialAddons, setInitialAddons] = useState([]);
  const [initialBoats, setInitialBoats] = useState([]);
  const [initialPromos, setInitialPromos] = useState([]);
  const [tripData, setTripData] = useState([]);
  const [form, setForm] = useState({
    enTitle: "",
    areTitle: "",
    enDesc: "",
    areDesc: "",
    category: "",
    type: "Group",
    price: "",
    maxPerson: "",
    startTime: "5 AM",
    enLocation: "",
    areLocation: "",
    duration: 0,
    status: "Active",
    boatYacht: [],
    addons: [],
    note: "",
  });

  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);

  const handleDateChange = (date) => {
    setCurrentDate(date);
    let foundTimes = null;
    const convertedDate = convertDateToString(date);
    if (slots.has(convertedDate)) foundTimes = slots.get(convertedDate);
    if (foundTimes !== null) {
      setSelectedTimes(foundTimes);
    } else {
      setSelectedTimes([]);
    }
  };

  const submitIt = (e) => {
    e.preventDefault();

    const convertedDate = convertDateToString(currentDate);
    slots.set(convertedDate, selectedTimes);

    setSelectedDates([...selectedDates, currentDate]);
    setCurrentDate(null);
    setSelectedTimes([]);
  };

  async function fetchData(id) {
    try {
      const res = await AddOn.getAddons();
      const addonData = res.data.data;
      let arr = [];
      addonData.forEach((addon, index) => {
        arr.push({ title: addon?.en?.title, _id: addon?._id });
      });
      setAddOns(arr);

      const response = await API.getBoats();
      const boatData = response.data.data;
      let Boat = [];
      boatData.forEach((boat, index) => {
        if (boat.en && boat.en.title)
          Boat.push({ title: boat?.en?.title, _id: boat?._id });
      });
      setBoats(Boat);

      const promoResponse = await Promo.getAllPromos();
      const promoData = promoResponse.data.data;
      let Promos = [];
      promoData.forEach((promo, index) => {
        Promos.push({
          code: promo?.promoCode,
          description: promo?.en.description,
          _id: promo?._id,
        });
      });
      setPromos(Promos);

      const tripResponse = await Trip.getTripById(id);
      setTripData(tripResponse.data.data);

      //set slots in map
      const allSlots = tripResponse.data.data.slots;
      let tempDates = [];
      allSlots.forEach((slot) => {
        const date = slot.date;
        const timeArray = slot.time;
        slots.set(date, timeArray);
        tempDates.push(new Date(date));
      });
      setSelectedDates([...tempDates]);

      const selectedBoats = tripResponse.data.data.boatYacht;
      let iniBoats = [];
      selectedBoats.forEach((boat) => {
        iniBoats.push({ title: boat?.en?.title, _id: boat?._id });
      });
      setInitialBoats(iniBoats);

      const selectedAddons = tripResponse.data.data.addons;
      let iniAddons = [];
      selectedAddons.forEach((addon) => {
        iniAddons.push({ title: addon?.en?.title, _id: addon?._id });
      });
      setInitialAddons(iniAddons);

      const selectedPromos = tripResponse.data.data.promos;
      let iniPromos = [];
      selectedPromos.forEach((promo) => {
        iniPromos.push({
          code: promo?.promoCode + ' (' + promo?.en?.description + ')',
          description: promo?.en?.description,
          _id: promo?._id,
        });
      });
      setInitialPromos(iniPromos);
    } catch (error) {
      if (error?.response?.data?.message)
        console.log(error.response.data.message);
      else toast.error('Failed to fetch Trip Details')
    }
  }

  const fetchTripData = async () => {
    try {
      const data = tripData;
      if (!data || data === []) return;

      setForm({
        enTitle: data.en.title,
        areTitle: data.are.title,
        enDesc: data.en.description,
        areDesc: data.are.description,
        category: data.category,
        type: data.type,
        price: data.price,
        maxPerson: data.maxPerson,
        startTime: data?.startTime ? data.startTime : '',
        enLocation: data.en.location,
        areLocation: data.are.location,
        duration: data?.duration,
        status: data.status,
        note: data.note,
        endTime: data?.endTime ? data.endTime : ''
      });
      setSelectedAddons(data.addons);
      setSelectedBoats(data.boatYacht);
      setImages(data?.image);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  useEffect(() => {
    if (id) fetchTripData(id);
  }, [tripData]);

  const handleChange = (e) => {
    if (e.target.value === "True") setForm({ ...form, [e.target.name]: true });
    else if (e.target.value === "False")
      setForm({ ...form, [e.target.name]: false });
    else setForm({ ...form, [e.target.name]: e.target.value });
  };



  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={form?.enTitle}>
              <Button
                className={`material-icons icons p-2`}
                style={{
                  marginLeft: "15px",
                  borderRadius: "100%",
                  color: "white",
                  backgroundColor: "#5e5d72",
                }}
                onClick={() => navigate(`/edit-trip/${id}`)}
              >
                edit
              </Button>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={7}>
          <CardLayout>
            <Row>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="english title"
                  name="enTitle"
                  onChange={handleChange}
                  value={form.enTitle}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="arabic title"
                  name="areTitle"
                  onChange={handleChange}
                  value={form.areTitle}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>
              <Col xl={6}>
                <LabelTextarea
                  label="english description"
                  name="enDesc"
                  onChange={handleChange}
                  value={form.enDesc}
                  fieldSize="w-100 h-text-md"
                  disabled
                />
              </Col>
              <Col xl={6}>
                <LabelTextarea
                  label="arabic description"
                  name="areDesc"
                  onChange={handleChange}
                  value={form.areDesc}
                  fieldSize="w-100 h-text-md"
                  disabled
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  label="category"
                  type="text"
                  name="category"
                  onChange={handleChange}
                  value={form.category}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  label="type"
                  name="type"
                  onChange={handleChange}
                  value={form.type}
                  option={["Group", "Individual"]}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>
              {form.type === 'Group' &&
                <Col xl={6}>
                  <LabelField
                    type="text"
                    name="startTime"
                    onChange={handleChange}
                    value={form.startTime}
                    label={`start time`}
                    fieldSize="w-100 h-md"
                  />
                </Col>
              }
              {form.type === 'Group' &&
                <Col xl={6}>
                  <LabelField
                    type="text"
                    name="endTime"
                    onChange={handleChange}
                    value={form.endTime}
                    label={`end time`}
                    fieldSize="w-100 h-md"
                  />
                </Col>
              }
              <Col xl={6}>
                <LabelField
                  type="number"
                  name="price"
                  onChange={handleChange}
                  value={form.price}
                  label={`${form.type === "Group" ? "Price Per Person" : "Trip Price"
                    }`}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>
              {form.type === "Group" && <Col xl={6}>
                <LabelField
                  type="number"
                  label="Capacity"
                  name="maxPerson"
                  onChange={handleChange}
                  value={form.maxPerson}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>}
              {form.type !== 'Group' &&
                <Col xl={6}>
                  <LabelField
                    type="number"
                    label="duration"
                    name="duration"
                    onChange={handleChange}
                    value={form.duration}
                    fieldSize="w-100 h-md"
                    disabled
                  />
                </Col>}
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="en location"
                  name="enLocation"
                  onChange={handleChange}
                  value={form.enLocation}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  type="text"
                  label="arabic location"
                  name="areLocation"
                  onChange={handleChange}
                  value={form.areLocation}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>
              <Col xl={6}>
                <LabelField
                  label="status"
                  option={["Active", "Inactive"]}
                  name="status"
                  onChange={handleChange}
                  value={form.status}
                  fieldSize="w-100 h-md"
                  disabled
                />
              </Col>
              <Col xl={6}>
                <Label className="mc-label-field-title">boats</Label>
                <Multiselect
                  options={boats}
                  showCheckbox={true}
                  selectedValues={initialBoats}
                  onSelect={(e) => {
                    setSelectedBoats(e);
                  }}
                  onRemove={(e) => {
                    setSelectedBoats(e);
                  }}
                  getOptionValue={(option) => option._id}
                  displayValue="title"

                  disablePreSelectedValues
                />
              </Col>
              <Col xl={6}>
                <Label className="mc-label-field-title">Addons</Label>
                <Multiselect
                  options={addons}
                  showCheckbox={true}
                  selectedValues={initialAddons}
                  // onSelect={(e) => {
                  //   setSelectedAddons(e);
                  // }}
                  // onRemove={(e) => {
                  //   setSelectedAddons(e);
                  // }}
                  getOptionValue={(option) => option._id}
                  displayValue={`title`}
                  disablePreSelectedValues

                />
              </Col>
              <Col xl={6}>
                <Label className="mc-label-field-title">Promos</Label>
                <Multiselect
                  options={promos}
                  showCheckbox={true}
                  selectedValues={initialPromos}
                  onSelect={(e) => {
                    setSelectedPromos(e);
                  }}
                  onRemove={(e) => {
                    setSelectedPromos(e);
                  }}
                  getOptionValue={(option) => option._id}
                  displayValue={`code`}
                  disablePreSelectedValues
                />
              </Col>
              {form.type !== 'Group' && <Col xl={12}>
                <div className="">
                  <div className="p-6 rounded-lg">
                    <form
                      style={{
                        alignItems: "center",
                        gap: "15px",
                      }}
                      onSubmit={submitIt}
                    >
                      <Row>
                        <Col xl={4}>
                          <div className="">
                            <DatePicker
                              highlightDates={selectedDates}
                              placeholderText="Click to see selected dates      &#128197;"
                              dateFormat="dd/MM/yyyy"
                              onChange={handleDateChange}
                              selected={currentDate}
                              // includeDates={selectedDates}

                              customInput={
                                <LabelField
                                  type="text"
                                  label="Selected Dates"
                                  name="date"
                                  fieldSize="w-100 h-md"
                                />
                              }
                            />
                          </div>
                        </Col>
                        <Col xl={7}>
                          {currentDate && (
                            <>
                              <Label className="mc-label-field-title">
                                Selected Time Slots
                              </Label>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                                  gap: "5px",
                                  justifyContent: "space-evenly",
                                  width: "100%",
                                }}
                                className="time-intervals"
                              >
                                {timeSlots.map((time) => (
                                  <div
                                    key={time}
                                    className="flex items-center mb-2"
                                  >
                                    <label className="flex items-center space-x-2">
                                      <input
                                        style={{ marginRight: "5px" }}
                                        type="checkbox"
                                        value={time}
                                        checked={selectedTimes.some(
                                          (t) => t === time
                                        )}
                                        // onChange={(e) =>
                                        //   handleTimeChange(e.target.value)
                                        // }
                                        className="form-checkbox h-5 w-5 text-blue-500"
                                      />
                                      <span className="text-lg">{time}</span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </Col>
                      </Row>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "10px",
                        }}
                      >

                      </div>
                    </form>
                  </div>
                </div>
              </Col>}
              <Col xl={12}>
                <LabelTextarea
                  label="Note"
                  name="note"
                  onChange={handleChange}
                  value={form.note}
                  fieldSize="w-100 h-text-md"
                  disabled
                />
              </Col>
            </Row>
          </CardLayout>
        </Col>
        <Col xl={5}>
          <CardLayout>
            <Box className="mc-trip-upload-media">

              {images.map((img, index) => (
                <Box key={img?.name} className="mc-trip-upload-image">
                  {typeof img === "string" ? (
                    <Image
                      src={img}
                      alt="trip image"
                      style={{ maxHeight: "250px" }}
                    />
                  ) : (
                    <Image
                      src={URL?.createObjectURL?.(img)}
                      alt="trip image"
                      style={{ maxHeight: "250px" }}
                    />
                  )}

                </Box>
              ))}
            </Box>

          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { Box, Button, Image, Input, Label, Icon, Text, Textarea } from "../../components/elements";
import { LabelField } from "../../components/fields";
import { CardLayout } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import * as API from "../../api/blogs";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";


export default function EditBlog() {

  const id = useParams().id;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [enPostContent, setEnPostContent] = useState("");
  const [arePostContent, setArePostContent] = useState("");
  const [selectedEnImages, setEnSelectedImages] = useState([]);
  const [enPostCover, setEnPostCover] = useState("");
  const [arePostCover, setArePostCover] = useState("");
  const [selectedAreImages, setAreSelectedImages] = useState([]);
  const [enTitle, setEnTitle] = useState('')
  const [areTitle, setAreTitle] = useState('')
  const [coverImage, setCoverImage] = useState([])
  const [enPostSubText, setEnPostSubText] = useState("");
  const [arePostSubText, setArePostSubText] = useState("");

  useEffect(() => {
    const fetchData = async (id) => {
      try {

        const res = await API.getBlogById(id)
        const data = res?.data?.data;
        setEnTitle(data?.en?.title)
        setAreTitle(data?.are?.title)
        setEnPostSubText(data?.en?.subText)
        setArePostSubText(data?.are?.subText)
        setEnPostCover(data?.en?.cover)
        setArePostCover(data?.are?.cover)
        setEnPostContent(data?.en?.content)
        setArePostContent(data?.are?.content)
        setCoverImage([data.image])
      } catch (error) {
        if (error.response.error.message) toast.error(error.response.error.message)
        else toast.error('Failed to get Blog')
        console.log(error);
      }
    }
    if (id)
      fetchData(id);
  }, [id]);

  const resetForm = () => {
    setEnPostContent('')
    setArePostCover('')
    setEnPostCover('')
    setArePostContent('')
    setEnSelectedImages([])
    setAreSelectedImages([])
    setEnTitle('')
    setAreTitle('')
    setCoverImage([])
  }

  const handleCoverImage = (event) => {
    const files = Array.from(event.target.files)
    setCoverImage(files)
  }


  const handleEnFileChange = (event) => {
    const files = Array.from(event.target.files);
    setEnSelectedImages(files);
  };

  const handleAreFileChange = (event) => {
    const files = Array.from(event.target.files);
    setAreSelectedImages(files);
  };

  const handleEnImageInsert = async (image) => {
    try {
      const imageData = new FormData();
      imageData.append('image', image)
      const { data } = await API.getImageLink(imageData)
      const imageUrl = data.image

      const imageTag = `<img src="${imageUrl}" width="300px"  alt="Inserted Image" />`;
      const updatedContent = `${enPostContent}${imageTag}`;
      setEnPostContent(updatedContent);
    } catch (error) {
      if (error.response.data.message) toast.error(error.rresponse.data.message)
      else toast.error('Failed to upload the image')
      console.log(error.message);
    }


  };
  const handleAreImageInsert = async (image) => {

    try {
      const imageData = new FormData();
      imageData.append('image', image)
      const { data } = await API.getImageLink(imageData)
      const imageUrl = data.image

      const imageTag = `<img src="${imageUrl}" width="300px"  alt="Inserted Image" />`;
      const updatedContent = `${arePostContent}${imageTag}`;
      setArePostContent(updatedContent);
    } catch (error) {
      if (error.response.data.message) toast.error(error.response.data.message)
      else toast.error('Failed to upload the image')
      console.log(error.message);
    }


  };

  const handleSubmit = async () => {
    try {
      if (coverImage.length === 0) {
        toast.warning("Please Upload Cover Image for this blog!")
        return;
      }
      const blogData = new FormData();
      blogData.append('enTitle', enTitle)
      blogData.append('areTitle', areTitle)
      blogData.append('enPostContent', enPostContent)
      blogData.append('arePostContent', arePostContent)
      blogData.append('enPostCover', enPostCover)
      blogData.append('arePostCover', arePostCover)
      blogData.append('enPostSubText', enPostSubText)
      blogData.append('arePostSubText', arePostSubText)
      coverImage.forEach((image) => {
        blogData.append('image', image)
      })
      const res = await API.updateBlog(id, blogData)
      if (res.data.status) {
        setLoading(false)
        toast.success('Blog updated successfully')
        resetForm();
        navigate('/blogs-list')
      }
    }
    catch (error) {
      setLoading(false);
      if (error.response.data.message) toast.error(error.response.data.message)
      else toast.error('Something went wrong')
      console.log(error.message);
    }
  };
  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={enTitle}>
              <Button
                className={`material-icons icons p-2`}
                style={{
                  marginLeft: "15px",
                  borderRadius: "100%",
                  color: "white",
                  backgroundColor: "#5e5d72",
                }}
                onClick={() => navigate(`/blogs-list`)}
              >
                cancel
              </Button>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={12}>
          {
            coverImage.length === 0 &&
            <Box className="mc-trip-upload-file mt-4">
              <Input type="file" id='coverimage' name='coverimage' onChange={handleCoverImage} />
              <Label htmlFor="coverimage"><Icon type="collections" /><Text> cover image </Text></Label>
            </Box>
          }
          {
            coverImage.map((img, index) => (
              <Box key={img.name} className="mc-trip-upload-image d-flex justify-content-center">
                {
                  (typeof img === 'string') ? <Image src={img} alt="boat image" style={{ maxWidth: '300px' }} /> : <Image src={URL?.createObjectURL?.(img)} alt="boat image" style={{ maxWidth: '300px' }} />
                }

                <Button onClick={() => { setCoverImage([]) }} className="btn btn-danger position-absolute top-0 end-0 m-2 " >Remove Image</Button>
              </Box>
            ))

          }

        </Col>
        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <LabelField
                  type="text"
                  label="english title"
                  fieldSize="w-100 h-md"
                  value={enTitle}
                  onKeyDown={(e) => {
                    if (e.keyCode === 189) {
                      alert('This key(-) is restricted for title');
                      e.preventDefault();
                      return;
                    }
                    if (e.keyCode === 186) {
                      alert('This key(:) is restricted for title');
                      e.preventDefault();
                      return;
                    }
                  }}
                  onChange={(e) => { setEnTitle(e.target.value) }}
                  name="title"
                />
              </Col>
              <Col xl={12}>
                <div className="max-w-full mx-auto p-6">
                  <h2 className="text-2xl font-bold mb-4">Create Blog SubText(English)</h2>
                  <JoditEditor value={enPostSubText} onBlur={setEnPostSubText} />
                  <h2 className="text-2xl font-bold my-4">Create Blog Cover(English)</h2>
                  <JoditEditor value={enPostCover} onBlur={setEnPostCover} />
                  <h2 className="text-2xl font-bold my-4">Create Blog Post</h2>
                  <JoditEditor value={enPostContent} onBlur={setEnPostContent} />

                  <Box className="mc-trip-upload-file mt-4">
                    <Input type="file" id='image' name='image' multiple onChange={handleEnFileChange} />
                    <Label htmlFor="image"><Icon type="collections" /><Text> upload images </Text></Label>
                  </Box>
                  {
                    selectedEnImages.length !== 0 &&
                    <h3 className="text-xl text-center" style={{ marginTop: '5px' }}>Selected Images</h3>
                  }
                  <div className="mt-6 d-flex" style={{ justifyContent: 'center', gap: '10px', flexWrap: 'wrap' }}>
                    {selectedEnImages.map((image) => (
                      <div key={image.name} className="mb-2 d-flex" style={{ width: '200px', flexDirection: 'column', justifyContent: 'end' }}>
                        <img
                          src={URL.createObjectURL(image)}
                          alt={image?.name || 'image'}
                          className="w-16 h-16 object-cover rounded"
                          width={200}
                        />
                        <button
                          onClick={() =>
                            handleEnImageInsert(image)
                          }
                          className="btn btn-primary  "
                          style={{ width: '200px', marginTop: '5px' }}
                        >
                          Insert Image
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <LabelField
                  type="text"
                  label="arabic title"
                  fieldSize="w-100 h-md"
                  value={areTitle}
                  onChange={(e) => { setAreTitle(e.target.value) }}
                  name="title"
                />
              </Col>
              <Col xl={12}>
                <div className="max-w-full mx-auto p-6">
                  <h2 className="text-2xl font-bold mb-4">Create Blog SubText(Arabic)</h2>
                  <JoditEditor value={arePostSubText} onBlur={setArePostSubText} />
                  <h2 className="text-2xl font-bold my-4">Create Blog Cover(Are)</h2>
                  <JoditEditor value={arePostCover} onBlur={setArePostCover} />
                  <h2 className="text-2xl font-bold my-4">Create Blog Post</h2>
                  <JoditEditor value={arePostContent} onBlur={setArePostContent} />

                  <Box className="mc-trip-upload-file mt-4">
                    <Input type="file" id='areimage' name='image' multiple onChange={handleAreFileChange} />
                    <Label htmlFor="areimage"><Icon type="collections" /><Text> upload images </Text></Label>
                  </Box>
                  {
                    selectedAreImages.length !== 0 &&
                    <h3 className="text-xl text-center" style={{ marginTop: '5px' }}>Selected Images</h3>
                  }
                  <div className="mt-6 d-flex" style={{ justifyContent: 'center', gap: '10px', flexWrap: 'wrap' }}>
                    {selectedAreImages.map((image) => (
                      <div key={image.name} className="mb-2 d-flex" style={{ width: '200px', flexDirection: 'column', justifyContent: 'end' }}>
                        <img
                          src={URL.createObjectURL(image)}
                          alt={image.name}
                          className="w-16 h-16 object-cover rounded"
                          width={200}
                        />
                        <button
                          onClick={() =>
                            handleAreImageInsert(image)
                          }
                          className="btn btn-primary  "
                          style={{ width: '200px', marginTop: '5px' }}
                        >
                          Insert Image
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </CardLayout>
        </Col>

        <CardLayout className={"d-flex justify-content-center"}>
          <Button
            type="btn"
            className={"btn btn-primary text-xl "}
            onClick={!loading && handleSubmit}
          >
            {loading ? 'Updating Blog...' : 'Update Blog'}
          </Button>
        </CardLayout>
      </Row>
    </PageLayout>
  );
}
import React, { useContext, useState, useRef, useEffect } from 'react';
import { ProfileDropdown } from '../components/header';
import { Button, Section, Box, Input } from "../components/elements";
import { DrawerContext } from '../context/Drawer';
import data from "../data/master/header.json";
import { useLocation, useNavigate } from 'react-router-dom';

export default function Header({ user, setUser }) {

    const { drawer, toggleDrawer } = useContext(DrawerContext);
    const [scroll, setScroll] = useState("fixed");

    window.addEventListener('scroll', () => {
        if (window.scrollY > 0) setScroll("sticky");
        else setScroll("fixed");
    });



    return (
        <Section as="header" className={`mc-header ${scroll}`}>
            <Box className="mc-header-group">
                <Box className="mc-header-left">
                    <Button
                        icon={drawer ? "menu_open" : "menu"}
                        className="mc-header-icon toggle"
                        onClick={toggleDrawer}
                    />
                </Box>
                <Box className="mc-header-right">
                    <ProfileDropdown
                        name={user?.userName}
                        // image={ user?.picture }
                        username={user?.email}
                        dropdown={data.profile.dropdown}
                        user={user}
                        setUser={setUser}

                    />
                </Box>
            </Box>
        </Section>
    );
}


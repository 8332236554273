import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout, CardHeader } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import * as API from "../../api/addons";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function EditAddon() {

  const id = useParams().id
  const navigate = useNavigate();

  const [form, setForm] = useState({ enTitle: "", enDesc: "desc", areTitle: "", areDesc: "desc", price: "",quantity:"", status: "Active" });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formData = {
        en: {
          title: form.enTitle,
          description: form.enDesc,
        },
        are: {
          title: form.areTitle,
          description: form.areDesc,
        },
        price: form.price,
        quantity: form.quantity,
        status: form.status,
      }
      const res = await API.updateAddon(id, formData)
      if (res.data.status){
        setLoading(false);
        toast.success('Addon Updated Successfully')
        navigate('/addon-list')
      }
    } catch (error) {
      setLoading(false);
      if (error.response.data.message) toast.error(error.response.data.message)
      else toast.error('Something went wrong')
      console.log(error.message);
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const res = await API.getAddonById(id)
        const data = res.data.data;
        setForm({
          enTitle: data.en.title,
          enDesc: data.en.description,
          areTitle: data.are.title,
          areDesc: data.are.description,
          price: data.price,
          quantity: data.quantity,
          status: data.status,
        })
      } catch (error) {
        if (error.response.data.message) toast.error(error.response.data.message)
        else toast.error('Failed to get Addon details')
      }
    }
    if (id)
      fetchData(id);
  }, [id]);


  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title="edit addon">
              <Button
                className={`material-icons icons p-2`}
                style={{
                  marginLeft: "15px",
                  borderRadius: "100%",
                  color: "white",
                  backgroundColor: "#5e5d72",
                }}
                onClick={() => navigate(`/addon-list`)}
              >
                cancel
              </Button>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={6}>
          <CardLayout>
            <CardHeader title="english details" dotsMenu={data?.dotsMenu} />
            <Row>
              <Col xl={12}>
                <LabelField
                  type="text"
                  label="Addon title"
                  fieldSize="w-100 h-md"
                  value={form.enTitle}
                  onChange={handleChange}
                  name="enTitle"
                />
              </Col>
              {/* <Col xl={12}>
                <LabelTextarea
                  label="description"
                  name="enDesc"
                  value={form.enDesc}
                  onChange={handleChange}
                  fieldSize="w-100 h-text-md"
                />
              </Col> */}
            </Row>
          </CardLayout>
        </Col>

        <Col xl={6}>
          <CardLayout>
            <CardHeader title="Arabic details" dotsMenu={data?.dotsMenu} />
            <Row>
              <Col xl={12}>
                <LabelField
                  type="text"
                  label="addon title"
                  fieldSize="w-100 h-md"
                  name="areTitle"
                  value={form.areTitle}
                  onChange={handleChange}
                />
              </Col>
              {/* <Col xl={12}>
                <LabelTextarea
                  label="description"
                  name="areDesc"
                  value={form.areDesc}
                  onChange={handleChange}
                  fieldSize="w-100 h-text-md"
                />
              </Col> */}
            </Row>
          </CardLayout>
        </Col>
        <Col xl={12}>
          <CardLayout>
            <Col xl={12}>
              <LabelField
                type="number"
                label="price"
                value={form.price}
                onChange={handleChange}
                name="price"
                className="mb-2"
                fieldSize="w-100 h-md"
              />{" "}
            </Col>
            <Col xl={12}>
              <LabelField
                type="number"
                label="max quantity"
                value={form.quantity}
                onChange={handleChange}
                name="quantity"
                className="mb-2 "
                fieldSize="w-100 h-md"
              />{" "}
            </Col>
          
            <Col xl={12}>
              <LabelField
                label="status"
                name="status"
                value={form.status}
                onChange={handleChange}
                option={["Active", "Inactive"]}
                className="mb-1"
                fieldSize="w-100 h-md"
              />{" "}
            </Col>
          </CardLayout>
        </Col>
        <Col xl={12}>
          <CardLayout className={"d-flex justify-content-center"}>
            <Button
            type="btn"
              className={"btn btn-primary text-xl"}
              onClick={!loading && handleSubmit}
            >
              {loading ? 'Adding...' : 'update Addon'}
            </Button>
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}
